
import React, { useState , useEffect, useContext,useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  Table,
  TableHead,
  CheckboxField
  } from '@aws-amplify/ui-react';
  import styles from './SMMADTextTable.module.css';  
  
import { generateClient } from 'aws-amplify/api';
import  { listSMMADTexts }  from '../../../graphql/queries'
  

const SMMADTextTable = (props) => {       
  

  SMMADTextTable.propTypes = {
      AccountSelected: PropTypes.string,
      Report: PropTypes.string
      };


      const [ErrorMessage, setErrorMessage] = useState("") 

      const [vTextData, setvTextData] = useState()
      const [vTextSortedData, setvTextSortedData] = useState()
      
      const [RecordsReturned, setRecordsReturned] = useState(0)    
      const [nextToken, setnextToken] = useState(undefined) 
      const [IsLoading, setIsLoading] = useState(false)
      const [vAccountSelected, setvAccountSelected] = useState(props.AccountSelected)
      const client = generateClient();
      

      useEffect(() => {


        console.log("SMMADTextTable.useEffect[].props.Report: ",props.Report); 
        (async function() {
              try {      

              
              //console.log("SMMADTextTable.useEffect[].props.vTextData[10]: ",props.vTextData[10]); 
              GetContent()
          
              } catch (err) {
                console.error('PageViewMap.useEffect[].ERROR: ', err, err.stack); 
            }
          }
        )();           


      },[]) 

      useEffect(() => {

        const fetch1 = async () => {


          try {

                if(nextToken !== null) {
                  setIsLoading(true)

                    var datetime = new Date();                                                
                    let limit = 1000000
                    //console.log("SMMADTextTable.useEffect[NextToken].nextToken: ", nextToken);   
                    
                    let  variables = {
                      nextToken,
                      filter: {
                        Account: {
                                eq: vAccountSelected
                            },
                      }
                    };   
                    
                    const ContentData2  = await client.graphql({
                      query: listSMMADTexts,
                      variables: variables
                    });
      
          
          
                  //console.log("Content.GetContent.ContentData2.data.listSMMADTexts.nextToken:  ",ContentData2.data.listSMMADTexts.nextToken)

                  let ContentData = vTextData                   
                        
        
                  ContentData2.data.listSMMADTexts.items.forEach(item => 
                    {   
                  
                      if ([...ContentData].filter(Content => item.id === Content.id)["length"] === 0) {  
                        ContentData.push(item) 
                      }

                    })


                      //console.log("SMMADTextTable.useEffect[NextToken].elseif.SMMADTextTableRecordsData2.data.listEmailAccountStats.nextToken: ", SMMADTextTableRecordsData2.data.listEmailAccountStats.nextToken);   
                      //console.log("SMMADTextTable.useEffect[NextToken].elseif.SMMADTextTableRecordsData2.length: ", SMMADTextTableRecordsData2.data.listEmailAccountStats.items.length);  
                      //console.log("SMMADTextTable.useEffect[NextToken].elseif.SMMADTextTableRecordsData2.items: ", SMMADTextTableRecordsData2.data.listEmailAccountStats.items);  

                      setvTextData(ContentData)  
                      setnextToken(ContentData2.data.listSMMADTexts.nextToken)  

                                          

                    
                } else if (nextToken === null){          
                  
                 
                  
                  setIsLoading(false)
  
                }

          } catch (err) {
            //console.log(err)
          } finally {
            setIsLoading(false)
          }
        }
      
        fetch1()

      }, [nextToken])         
      
      useEffect(() => {

        

        if(vTextData !== undefined && nextToken === null && IsLoading === false) {
          console.log("SMMADTextTable.useEffect[IsLoading].len(vTextData): ", vTextData.length, " - nextToken: ", nextToken, " - IsLoading: ", IsLoading);   
          let vSorted = SortTextsByWeight(vTextData)
          setvTextSortedData(vSorted)
          setRecordsReturned(vSorted.length)  

        }

      },[IsLoading])       

      useEffect(() => {

        

        if(vTextSortedData !== undefined) {
          console.log("SMMADTextTable.useEffect[vTextSortedData].len(vTextSortedData): ", vTextSortedData.length);  
          console.log("SMMADTextTable.useEffect[vTextSortedData].vTextSortedData: ", vTextSortedData); 

        }

      },[vTextSortedData])   

      async function GetContent() {
        try {
                  setErrorMessage("")
                  setvTextData([])
                  
               //console.log("Content.GetContent.vAccountSelected:  ",vAccountSelected)
               //console.log("Content.GetContent.vCampaignSelected:  ",vCampaignSelected)
                 

                 let  variables = {
                    filter: {
                      Account: {
                              eq: vAccountSelected
                          },
                    }
                  };

                  const ContentData2  = await client.graphql({
                    query: listSMMADTexts,
                    variables: variables
                  });
    
        
        
                  //console.log("Content.GetContent.ContentData2:  ",ContentData2)

                  if (ContentData2.data.listSMMADTexts.items.length > 0) {
                      const Content1 = ContentData2.data.listSMMADTexts.items 

                      setvTextData(Content1)  
                      
                      setnextToken(ContentData2.data.listSMMADTexts.nextToken)  
         
                }

        } catch (err) { 
                        console.error('Content.GetContent.ERROR: ', err, err.stack); 
                    }
      }   

      function SortTextsByWeight(pContentData) {
        pContentData.sort(WeightSortFunction);
        return pContentData;
        //    //console.log(JSON.stringify(pEmails));
      }
    
      function WeightSortFunction(a, b) {
        
        //console.log("SMMADTextTable.WeightSortFunction.a: ",a.Weight, " < b: ",b.Weight);  
        let vWeightA = a.Weight;
        let vWeightB = b.Weight;
        let vReturnOrder = vWeightA < vWeightB ? 1 : -1;
        console.log("SMMADTextTable.WeightSortFunction.vWeightA: ",vWeightA, " < vWeightB: ",vWeightB, " = vReturnOrder: ",vReturnOrder);  
        return vReturnOrder;
      }
    

  return (
      <div>
                        <div className={styles.MessageBox}>{ErrorMessage} Records retrieved: {RecordsReturned}</div>  
                        <div className={styles.Instructions}>Positive weights mean, you gained followers after post. Negative weights (-) means you lost followers after post</div>  
                        <div className={styles.ToolBar}> 
                        
                        {vTextData !== undefined && (                            
                            <Table className={styles.Table}> 
                            <TableHead>          
                              <TableRow className={styles.TableHeaderRow}>
                                <TableCell className={styles.TableHeaderRowCell} >Text</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell} ># Posts</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell} >Weight</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell}>Active</TableCell> 
                              </TableRow>                                      
                            </TableHead>                               
                              <TableBody>
                              {
                                    vTextSortedData && vTextSortedData.map((vText) => (         
                                    <TableRow key={vText.id}  className={styles.TableRow}>  
                                      <TableCell className={styles.TableCellHeader}>       
                                      <Text className={styles.TextCell}>
                                        {vText.Text}  
                                        </Text>          
                                      </TableCell>         
                                      <TableCell className={styles.TableCellHeader}>      
                                      <Text className={styles.Text}>
                                      {vText.PostedCount}  
                                        </Text>          
                                      </TableCell>      
                                      <TableCell className={styles.TableCellHeader}>      
                                      <Text className={styles.Text}>
                                      {Math.round(vText.ZScore,2)}  
                                        </Text>          
                                      </TableCell>      
                                      <TableCell className={styles.TableCellHeader}>    
                                        <CheckboxField
                                          className={styles.CheckboxField} 
                                          name="subscribe-controlled"
                                          checked={vText.Active}
                                        />         
                                      </TableCell>     
                                    </TableRow>    
                                  ))
                              }                                                                               
                            </TableBody>
                          </Table>   
                          )}   
                         
                        </div>  
      </div>
      
  )
}

export default SMMADTextTable;

