import React, {  useEffect, useRef, useState } from 'react';
import './App.css';
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports';
import axios from 'axios';
import { Routes, Route, useLocation, useNavigate} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';


import {

  useAuthenticator

  } from '@aws-amplify/ui-react';

import LandingPageV4 from "./pages/LandingPage/LandingPageV4";
import LandingPageV5 from "./pages/LandingPage/LandingPageV5";
import LandingPage from "./pages/LandingPage/LandingPage_clean";
import BackgroundSpringCanvas from "./pages/LandingPage/BackgroundSpringCanvas";
import MainMenuV2 from  "./pages/Menu/MainMenuV2";
import MainMenuCustomer from  "./pages/Menu/MainMenuCustomer";

import { ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import studioTheme from './ui-components/studioTheme';
import GlobalFonts from './fonts/fonts';
import { AnimatePresence } from 'framer-motion';
import ButtonContainer from './components/buttons3d/ButtonContainer';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import AuthenticatorRhyteIt from './pages/Authenticator/AuthenticatorRhyteIt';
import Application from './pages/Application/Application';
import MoreInfo from './pages/Application/ApplicationClean';
import Demo from './pages/Videos/Demo/Demo';
import Processes from './pages/Videos/Processes/Processes';
import Overview from './pages/Videos/Overview/Overview';
import Videos from './pages/Videos/Videos';
import Footer from './pages/RhyteItComponents/Footer/Footer';
import FooterV2 from './pages/RhyteItComponents/Footer/FooterV2';

import SBSMNews from './pages/SBSMNews/SBSMNews';
import SBSMNewsAdmin from './pages/SBSMNewsAdmin/SBSMNewsAdmin';
import SBSMRSS from './pages/SBSMRSS/SBSMRSS';
import SBSMQueries from './pages/SBSMQueries/SBSMQueries';

import RhyteItAccounts from './pages/RhyteItAccounts/RhyteItAccounts';
import RhyteItAccountDetail from './pages/RhyteItAccounts/RhyteItAccountDetail';
import RhyteItAccountTabs from './pages/RhyteItAccounts/RhyteItAccountTabs';
import RhyteItAccountSocialMedia from './pages/RhyteItAccounts/RhyteItAccountSocialMedia';
import RhyteItAccountTwitter from './pages/RhyteItAccounts/RhyteItAccountTwitter';
import RhyteItAccountSD from './pages/RhyteItAccounts/RhyteItAccountSD';

import TreeGrowth from './pages/Experiments/TreeGrowth';

import DigitalMarketPlace from './pages/DigitalMarketPlace/DigitalMarketPlace';
import DigitalMarketPlaceDetail from './pages/DigitalMarketPlace/DigitalMarketPlaceDetail';
import TurboFanBoost from './pages/DigitalMarketPlace/TurboFanBoost/TurboFanBoost';


import Product from './pages/DigitalMarketPlace/Product';
import DigitalMarketPlaceDetailSuccess from './pages/DigitalMarketPlace/DigitalMarketPlaceDetailSuccess';
import ProductManagement from './pages/ProductManagement/ProductManagement';
import ProductManagementDetail from './pages/ProductManagement/ProductDetail';
import CustomerPurchases from './pages/CustomerPurchases/CustomerPurchases';
import LeaveComment from './pages/CommentManagement/LeaveComment';

import AdultContentCreatorSearch from './pages/Search/AdultContentCreatorSearch'; 

import ApplicantManagement from './pages/Application/ApplicantManagement/ApplicantManagement';
import ApplicantDetail from './pages/Application/ApplicantManagement/ApplicantDetail';
import Conversation from './pages/Application/Conversation'; 

import Dashboard from './pages/Dashboard/Dashboard';
import TwitterStats from './pages/Twitter/TwitterStats';

import EmailManagement from './pages/EmailManagement/EmailManagement';
import EmailDetail from './pages/EmailManagement/EmailDetail';
import EmailReply from './pages/EmailManagement/EmailReply';
import EmailCompose from './pages/EmailManagement/EmailCompose';

import SMMADPlatforms from './pages/SMMAD/SMMADPlatforms/Platforms';
import SMMADPlatformDetail from './pages/SMMAD/SMMADPlatforms/PlatformDetail';
import SMMADCampaign from './pages/SMMAD/SMMADCampaign/SMMADCampaign';
import SMMADBatchJobs from './pages/SMMAD/SMMADBatchJobs/SMMADBatchJobs';
import SMMADSetup from './pages/SMMAD/SMMADPro/SMMADSetup';
import TurboFanBoostUser from './pages/SMMAD/SMMADPro/SMMADUserManagement';
import SMMADOrganize from './pages/SMMAD/SMMADOrganize/SMMADOrganize';
import SMMADContentMetrics from './pages/SMMAD/SMMADContentMetrics/SMMADContentMetrics';

import { UserContext, CognitoGroupContext, AuthenticatedContext} from './BusinessLogic/Hooks/UserContext';
import {RhyteItLogging} from './BusinessLogic/UserManagement';

import SBSMLogoTicker from './pages/Experiments/SBSMLogoTicker';
import SBSMLogoTickerHorizontal from './pages/Experiments/SBSMLogoTickerHorizontal';
import SubredditTicker from './pages/Experiments/SubredditTicker';
import OFProfilePicTicker from './pages/Experiments/OFProfilePicTicker';


import Background from './pages/Experiments/Background';
import BackgroundLogo from './pages/Backgrounds/Background';
import BackgroundNoLogo from './pages/Backgrounds/BackgroundNoLogo';
import BackgroundFailure from './pages/Backgrounds/BackgroundFailure';
import BackgroundStandOut from './pages/Backgrounds/BackgroundStandOut';
import ForegroundCanvasErase from './pages/Backgrounds/ForegroundCanvasErase';
import BackgroundAnimationTester from './pages/Backgrounds/BackgroundAnimationTester';
import BackgroundSVGAPI from './pages/Backgrounds/BackgroundSVGAPI';
import BackgroundAlexJanvier from './pages/Backgrounds/BackgroundAlexJanvier';



import Villain from "./pages/LandingPage/StoryBrandPages/Villain";
import Solution from "./pages/LandingPage/StoryBrandPages/Solution";
import Benefits from "./pages/LandingPage/StoryBrandPages/Benefits";
import LetsTalk from "./pages/LandingPage/StoryBrandPages/LetsTalk";
import LetsTalkBackground from "./pages/LandingPage/StoryBrandPages/LetsTalkBackground";
import LetsTalkBackgroundV2 from "./pages/LandingPage/StoryBrandPages/LetsTalkBackgroundV2";


import {
    fetchAuthSession,
    getCurrentUser
  } from 'aws-amplify/auth';





import 'mapbox-gl/dist/mapbox-gl.css';



Amplify.configure(awsconfig);
const helmetContext = {};


const App = () => { 

    const location = useLocation();
    const [Users, setUsers] = useState();  
    const [CognitoGroup, setCognitoGroup] = useState();  
    const [UserAuthenticated, setUserAuthenticated] = useState();
    const [UserSubscribed, setUserSubscribed] = useState(); 
    const [vLandingPage, setvLandingPage] = useState(false); 
    const [FirstTouch, setFirstTouch] = useState(true);
    const { authStatus } = useAuthenticator(context => [context.authStatus]);


    useEffect(() => {

      (async function() {
        try {               


        //    console.log("App.useEffect[].authStatus:  ",authStatus);
            if (process.env.REACT_APP_ENV === "PRODUCTION") {
              await getData()
            }

            if (authStatus ==="authenticated" || authStatus ==="configuring" ) {
                
              const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
           //   console.log("App.useEffect[].idToken:  ",idToken);
            //  console.log("App.useEffect[].accessToken:  ",accessToken);

              if (idToken !== undefined) {
                setUsers(idToken.payload.email)
                setCognitoGroup(accessToken["payload"]["cognito:groups"])
              }

            }

            } catch (err) {

              if (err.name !== "NotAuthorizedException") {
                RhyteItLogging(Users, "App", "useEffect[]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)                
                console.error("App.useEffect[].ERROR:  ",err);
              }
          }
        }
      )();   

      setvLandingPage(getRandomInt(2))    
  

    },[])     

    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }
    

    const getData = async () => {

      
      try {               
            //console.log("LandingPageV4.handleMouseOver.FirstTouch - Before: ", FirstTouch);
            if (FirstTouch === true) {
                //console.log("LandingPageV4.handleMouseOver.FirstTouch: ", FirstTouch);
                setFirstTouch(false);    
                //console.log("LandingPageV4.handleMouseOver.FirstTouch2: ", FirstTouch);

                  const res = await axios.get('https://geolocation-db.com/json/')
                  //console.log("LandingPageV4.getData.res.data: ",res.data );
                  //console.log("LandingPageV4.getData.res: ",res );
                if (res.data.IPv4 !== "98.199.173.171" && (res.data.state === "Texas" || res.data.state === "Rajasthan" )) {

                  window.location.replace("http://www.google.com/");             

                }
              }
            } catch (err) {           
              console.error("App.getData.ERROR:  ",err);
              RhyteItLogging(Users, "App", "getData",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)    
          }

      }       




    return (
      <HelmetProvider context={helmetContext}>
      <GlobalFonts />
      <div className="App"> 
      <ThemeProvider theme={studioTheme}> 
          <main className="Main" >
            <div className="App-intro">
              <AnimatePresence initial={false} mode="wait">        
                    <UserContext.Provider value={{Users, setUsers}} Users={Users}>
                      <CognitoGroupContext.Provider value={{CognitoGroup, setCognitoGroup}} Users={Users}> 
                        <Routes  location={location} key={location.pathname}>

                            <Route path="/" element={<LandingPageV5/>}/>
                            <Route path="/LandingPage" element={<LandingPage/>}/>
                            <Route path="/LandingPageV4" element={<LandingPageV4/>}/>  
                            <Route path="/LandingPageV5" element={<LandingPageV5/>}/>  
                            <Route path="/Application" element={<Application/>}/>   
                            <Route path="/BackgroundSpringCanvas" element={<BackgroundSpringCanvas/>}/>    
                            <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>   
                            <Route path="/AuthenticatorRhyteIt" element={<AuthenticatorRhyteIt/>}/>   
                            <Route path="/ButtonContainer" element={<ButtonContainer/>}/>  
                            <Route path="/Demo" element={<Demo/>}/>      
                            <Route path="/Videos" element={<Videos/>}/>    
                            <Route path="/Processes" element={<Processes/>}/>   
                            <Route path="/Overview" element={<Overview/>}/>    
                            
                            <Route path="/Footer" element={<Footer/>}/>     
                            <Route path="/FooterV2" element={<FooterV2/>}/>    
                            
                            <Route path="/MainMenuV2" element={<MainMenuV2/>}/>  

                            <Route path="/SBSMQueries" element={<SBSMQueries/>}/>   
                            <Route path="/SBSMNewsAdmin" element={<SBSMNewsAdmin/>}/>   
                            <Route path="/SBSMNews" element={<SBSMNews/>}/>   
                            <Route path="/SBSMRSS" element={<SBSMRSS/>}/>  

                            <Route path="/RhyteItAccounts" element={<RhyteItAccounts/>}/>  
                            <Route path="/RhyteItAccountDetail" element={<RhyteItAccountDetail/>}/>    
                            <Route path="/RhyteItAccountTabs" element={<RhyteItAccountTabs/>}/>                                
                            <Route path="/RhyteItAccountSocialMedia" element={<RhyteItAccountSocialMedia/>}/>  
                            <Route path="/RhyteItAccountTwitter" element={<RhyteItAccountTwitter/>}/>    
                            <Route path="/RhyteItAccountSD" element={<RhyteItAccountSD/>}/>            

                            <Route path="/AdultContentCreatorSearch" element={<AdultContentCreatorSearch/>}/>    

                            <Route path="/DigitalMarketPlace" element={<DigitalMarketPlace/>}/>       
                            <Route path="/DigitalMarketPlaceDetail" element={<DigitalMarketPlaceDetail/>}/> 

                            <Route path="/Product/:productParam" element={<Product/>}/>  
                            <Route path="/ProductManagement" element={<ProductManagement/>}/>      
                            <Route path="/ProductManagementDetail" element={<ProductManagementDetail/>}/>   
                            <Route path="/DigitalMarketPlaceDetailSuccess" element={<DigitalMarketPlaceDetailSuccess/>}/>  
                            <Route path="/CustomerPurchases" element={<CustomerPurchases/>}/> 
                            <Route path="/LeaveComment" element={<LeaveComment/>}/> 
                            <Route path="/TurboFanBoost" element={<TurboFanBoost/>}/>     
                            
                            <Route path="/Dashboard" element={<Dashboard/>}/> 
                            <Route path="/TwitterStats" element={<TwitterStats/>}/>    
                            
                            <Route path="/MoreInfo" element={<MoreInfo/>}/> 
                            <Route path="/ApplicantManagement" element={<ApplicantManagement/>}/> 
                            <Route path="/ApplicantDetail" element={<ApplicantDetail/>}/> 
                            <Route path="/TreeGrowth" element={<TreeGrowth/>}/>         
                            <Route path="/Conversation" element={<Conversation/>}/>   
                            
                            <Route path="/EmailManagement" element={<EmailManagement/>}/>
                            <Route path="/EmailDetail" element={<EmailDetail/>}/>  
                            <Route path="/EmailReply" element={<EmailReply/>}/>  
                            <Route path="/EmailCompose" element={<EmailCompose/>}/>   

                            <Route path="/SMMADPlatforms" element={<SMMADPlatforms/>}/>     
                            <Route path="/SMMADPlatformDetail" element={<SMMADPlatformDetail/>}/> 
                            <Route path="/SMMADCampaign" element={<SMMADCampaign/>}/>  
                            <Route path="/SMMADBatchJobs" element={<SMMADBatchJobs/>}/>  
                            <Route path="/SMMADSetup" element={<SMMADSetup/>}/>  
                            <Route path="/SMMADOrganize" element={<SMMADOrganize/>}/>  
                            <Route path="/SMMADContentMetrics" element={<SMMADContentMetrics/>}/> 
                            
                            
                            <Route path="/TurboFanBoostUser" element={<TurboFanBoostUser/>}/> 

                            <Route path="/MainMenuCustomer" element={<MainMenuCustomer/>}/>  

                            
                            <Route path="/SBSMLogoTicker" element={<SBSMLogoTicker/>}/>  
                            <Route path="/SBSMLogoTickerHorizontal" element={<SBSMLogoTickerHorizontal/>}/>                             
                            <Route path="/SubredditTicker" element={<SubredditTicker/>}/>    
                            <Route path="/OFProfilePicTicker" element={<OFProfilePicTicker/>}/>                               


                            <Route path="/Background" element={<Background/>}/>   
                            <Route path="/BackgroundLogo" element={<BackgroundLogo/>}/>  
                            <Route path="/BackgroundNoLogo" element={<BackgroundNoLogo/>}/>     
                            <Route path="/BackgroundFailure" element={<BackgroundFailure/>}/>  
                            <Route path="/ForegroundCanvasErase" element={<ForegroundCanvasErase/>}/>  
                            <Route path="/BackgroundAnimationTester" element={<BackgroundAnimationTester/>}/>  
                            <Route path="/BackgroundStandOut" element={<BackgroundStandOut/>}/> 
                            <Route path="/BackgroundSVGAPI" element={<BackgroundSVGAPI/>}/> 
                            <Route path="/BackgroundAlexJanvier" element={<BackgroundAlexJanvier/>}/> 

                            
                            <Route path="/LetsTalk" element={<LetsTalk/>}/> 
                            <Route path="/LetsTalkBackground" element={<LetsTalkBackground/>}/> 
                            <Route path="/LetsTalkBackgroundV2" element={<LetsTalkBackgroundV2/>}/> 
                            
                            
                            <Route path="/Solution" element={<Solution/>}/> 
                            <Route path="/Villain" element={<Villain/>}/> 
                            
                             
                            <Route path="/Benefits" element={<Benefits/>}/>          
                            
                            
                        </Routes>
                      </CognitoGroupContext.Provider>   
                    </UserContext.Provider>            
              </AnimatePresence>
            </div>   
          </main> 
        </ThemeProvider>  
      </div>
    </HelmetProvider>
    );
    
  }

export default App;
