import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./Problem.module.css";

import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
//import {useInView} from "react-intersection-observer";

import { Button, Flex, Text, Image } from "@aws-amplify/ui-react";
import Background from "../../Backgrounds/BackgroundFailure";

const Problem = (props) => {
  Problem.propTypes = {
    scrollYProgress: PropTypes.object,
  };

  const scale = useTransform(props.scrollYProgress, [0.08, 0.09,0.2, 0.3], [0.8, 1, 1, 0.1]);
  const rotate = useTransform(props.scrollYProgress, [0.08, 0.09,0.2, 0.3], [-5, 0, 0, 5 ]);

  const ref = useRef(null);
  const isInView = useInView(ref);
  
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {}, []);

  useEffect(() => {
   console.log("Problem.useEffect[isInView].windowHeight: " ,windowHeight);
   // console.log("Problem.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

  }, [isInView]);



  return (
    <motion.section ref={ref}   className={styles.SectionContainer}>
      <motion.div
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        className={styles.Background}
      >
      <Background backgroundcolor="#eab21e" />
      </motion.div>
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
        wrap="wrap"
        className={styles.TextFlexColumn}
      >
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="center"
          alignContent="flex-start"
          wrap="wrap"
          className={styles.HeaderTextRow}
        >
          <motion.div className={styles.MayanNumber}>
            <motion.h1
              style={{
                transform: isInView ? "none" : "translateX(-200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              }}
              className={styles.MayanDot}
            >
              &#x2022;&#x2022;
            </motion.h1>
          </motion.div>
          <motion.div>
            <motion.h1
              style={{
                transform: isInView ? "none" : "translateY(-200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              }}
              className={styles.HeaderText}
            >
              The Adult Content Creator Hamster Wheel
            </motion.h1>
          </motion.div>
        </Flex>
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          className={styles.HeaderSubTextRow}
        >
          <motion.h1
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
            className={styles.HeaderSubText}
          >
            Are you struggling to keep up with all your social media accounts
            while focusing on creating top-notch content and engaging with your
            fans?
          </motion.h1>
        </Flex>
      </Flex>
    </motion.section>
  );
};

export default Problem;
