import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import {
  listSMMADCampaigns,
  listSMMADAccounts,
} from "../../../graphql/queries";
import {
  createSMMADAccounts,
  createSMMADCampaigns,
} from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import Header from "../../RhyteItComponents/Header/Header";
import {
  UserContext,
  CognitoGroupContext,
} from "../../../BusinessLogic/Hooks/UserContext";
import {
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";
import { fetchAuthSession } from "aws-amplify/auth";
import { motion as m } from "framer-motion";
import SMMADCampaignTable from "./SMMADCampaignTable";
import MenuBackground from "./../../Menu/MenuBackground";
import { get_uuidv4 } from "../../../BusinessLogic/uuidv4";

import SMMADHashTags from "../SMMADHashTags/SMMADHashTags";
import SMMADPostingConfig from "./SMMADPostingConfig"

import {
  Button,
  Flex,
  TableBody,
  TextField,
  SelectField,
  Text,
  Fieldset,
  CheckboxField,
  TableCell,
  TableRow,
  Table,
  TableHead,
} from "@aws-amplify/ui-react";
import styles from "./SMMADCampaign.module.css";

const SMMADCampaign = (props) => {
  SMMADCampaign.propTypes = {
    RedditUser: PropTypes.object,
  };

  const client = generateClient();
  const [vSMMADCampaign, setvSMMADCampaign] = useState([]);
  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuV2", Title: "Menu", isCurrent: false },
    { Path: "/SMMADCampaign", Title: "TurboFanBoost", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);

  const { Users, setUsers } = useContext(UserContext);
  const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);

  const [vCampaignsActive, setvCampaignsActive] = useState(false);
  const [vAccountType, setvAccountType] = useState(false);

  const [vAccountSelected, setvAccountSelected] = useState({"Account":"None"});
  let [vAccountList, setvAccountList] = useState();
  let [vNewAccount, setvNewAccount] = useState();
  const [vAddNewAccountVisible, setvAddNewAccountVisible] = useState(false);
  const [vCampaignsVisible, setvCampaignsVisible] = useState(false);

  const [vUpdateKey, setvUpdateKey] = useState(false);

  const [updatedKey, setupdatedKey] = useState();
  const [vViewPostingConfig, setvViewPostingConfig] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (CognitoGroup !== undefined) {
          let GetGroupRoleAccessResponse = await GetGroupRoleAccess(
            CognitoGroup,
            "RhyteItSMMADUserGroup"
          );

          if (GetGroupRoleAccessResponse) {
            GetSMMADAccounts();
          }
        } else {
          const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

          if (idToken !== undefined) {
            setUsers(idToken.payload.email);
            setCognitoGroup(accessToken["payload"]["cognito:groups"]);
          } else {
            ReturnUserToLandingPage(Users, CognitoGroup);
          }
        }
      } catch (err) {
        RhyteItLogging(
          Users,
          "SMMADBatchJobs",
          "useEffect[CognitoGroup]",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    })();
  }, [CognitoGroup]);

  useEffect(() => {
    //console.log("SMMADCampaign.useEffect[vAccountSelected].vAccountSelected:  ",vAccountSelected)

    if (vAccountSelected !== undefined) {
      setvCampaignsVisible(true);
      setupdatedKey(Date.now());
    }
  }, [vAccountSelected]);

  useEffect(() => {
    //  console.log("SMMADCampaign.useEffect[vAccountList].vAccountList:  ",vAccountList)

    if (vAccountList !== undefined && Users !== "edwardhawke@rhyteit.com") {
      AccountSelectedAction(vAccountList[0]["Account"]);
    }
  }, [vAccountList]);

  useEffect(() => {
    //  console.log("SMMADCampaign.useEffect[vCampaignsVisible].vCampaignsVisible:  ",vCampaignsVisible)
    if (vCampaignsVisible !== undefined) {
    }
  }, [vCampaignsVisible]);

  async function GetSMMADAccounts() {
    try {
      setErrorMessage("");
      setvCampaignsActive([]);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            Account: {
              eq: Users,
            },
          },
        };
      } else {
        variables = {};
      }

      const listSMMADAccountsData2 = await client.graphql({
        query: listSMMADAccounts,
        variables: variables,
      });

      if (listSMMADAccountsData2.data.listSMMADAccounts.items.length > 0) {
        const SMMADAccount1 =
          listSMMADAccountsData2.data.listSMMADAccounts.items;

        let vAccountListArray = [];

        SMMADAccount1.forEach(async (vAccount1) => {
          if (
            vAccountListArray.filter(
              (vAccount) => vAccount["Account"] === vAccount1["Account"]
            ).length === 0
          ) {
            vAccountListArray.push(vAccount1);
          }
        });

        let vAccountListArraySorted = SortAccountsByName(vAccountListArray);
        console.log("SMMADvAccount1.GetSMMADAccounts.vAccountListArraySorted: ", vAccountListArraySorted);
        setvAccountList(vAccountListArraySorted);
      }
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADvAccount1",
        "GetSMMADAccounts",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function SortAccountsByName(pRhyteItAccounts) {
    pRhyteItAccounts.sort(GFG_sortFunction);
    return pRhyteItAccounts;
    //    //console.log(JSON.stringify(pEmails));
  }

  function GFG_sortFunction(a, b) {
    let RhyteItAccountA = a.Account;
    let RhyteItAccountB = b.Account;
    return RhyteItAccountA > RhyteItAccountB ? 1 : -1;
  }

  function NewAccount_handleChange(event, data) {
    try {
      setvNewAccount(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADCampaign",
        "NewAccount_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function AddAccountRow() {
    try {
      setvAccountSelected(null);
      let vNewValue = vAddNewAccountVisible === false ? true : false;
      setvAddNewAccountVisible(vNewValue);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADCampaign",
        "AddAccountRow",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function CreateNewAccount() {
    try {
      //console.log("SMMADCampaignTable.SaveRow.vCampaignToShow:  ",vCampaignToShow[0]);

      var vUpdateDateTimeNew = new Date().toISOString();

      let AccountItem = {
        id: vNewAccount,
        Account: vNewAccount,
        AccountType: vAccountType === false ? "Customer" : "Internal",
        CampaignsActive: vCampaignsActive,
        SMMADAccountStatus: "new",
        Active: true,
        Weight: 0,
        PostedCount: 0,
        PurchaseId: "",
        ProductId: "",
        TwitterAccount: "",
        TwitterPassword: "",
        TwitterConsumerKey: "",
        TwitterConsumerSecret: "",
        TwitterBearerToken: "",
        TwitterAccessToken: "",
        TwitterAccessTokenSecret: "",
        TwitterClientId: "",
        TwitterClientSecret: "",
        TwitterAppName: "",
        TwitterAppId: "",
        TwitterStatus: "active",
        CreationName: Users,
        CreationDateTime: vUpdateDateTimeNew,
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      //console.log("SMMADCampaignTable.SaveRow.CampaignItem:  ",CampaignItem);

      const createSMMADAccountsResponse = await client.graphql({
        query: createSMMADAccounts,
        variables: {
          input: AccountItem,
        },
      });
      setvAddNewAccountVisible(false);
      setvCampaignsActive(false);
      setvAccountType(false);
      setvNewAccount("");
      if (vCampaignsActive === false) {
        CreateDefaultCampaign();
      } else {
        GetSMMADAccounts();
      }
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADCampaign",
        "CreateNewAccount",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function CreateDefaultCampaign() {
    try {
      setErrorMessage("");

      var vUpdateDateTimeNew = new Date().toISOString();

      let CampaignItem = {
        id: get_uuidv4,
        Account: vNewAccount,
        AccountType: vAccountType === false ? "Customer" : "Internal",
        Campaign: "Default",
        Description: "Default",
        SMMADCampaignStatus: "new",
        ContentTextActive: true,
        Active: true,
        Weight: 0,
        PostedCount: 0,
        CreationName: vNewAccount,
        CreationDateTime: vUpdateDateTimeNew,
        UpdateName: vNewAccount,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      const updateSMMADCampaignResponse = await client.graphql({
        query: createSMMADCampaigns,
        variables: {
          input: CampaignItem,
        },
      });
      setvCampaignsVisible(false);

      GetSMMADAccounts();
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADCampaign",
        "CreateDefaultCampaign",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function AccountSelectedAction(pAccount) {
    try {
      //    console.log("SMMADCampaign.AccountSelectedAction.pAccount: ", pAccount);
      let vSelectedAccount = vAccountList.filter(
        (vAccountItem) => vAccountItem["Account"] === pAccount
      );

      console.log("SMMADCampaign.AccountSelectedAction.vSelectedAccount: ",vSelectedAccount);
      console.log("SMMADCampaign.AccountSelectedAction.vSelectedAccount[0]: ",vSelectedAccount[0]);
      setvCampaignsVisible(vSelectedAccount[0]["CampaignsActive"]);
      //setvAccountSelected(pAccount);
      setvAccountSelected(vSelectedAccount[0]);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADCampaign",
        "AccountSelectedAction",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function ViewConfig(event) {
    try {
      console.log("SMMADCampaign.ViewConfig.event: ", event);
      console.log("SMMADCampaign.ViewConfig.vViewPostingConfig: ", vViewPostingConfig);
      setvViewPostingConfig(!vViewPostingConfig)


    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADCampaign",
        "ViewConfig",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      <MenuBackground
        className={styles.MenuBackground}
        GradientStopColor1="#0000"
        GradientStopColor2="#000"
        GradientStopColor3="#00CCCC"
        GradientStopColor4="#8880"
      />

      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
        <Header
          key={Date.now()}
          Title="TurbFanBoost"
          SubTitle="Social Media Marketing Automation"
          BreadCrumbItems={vBreadCrumbItems}
          pageSource="TurbFanBoost"
        />
        <Fieldset className={styles.Fieldset} size="small">
          <legend className={styles.legend}>Accounts</legend>
          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="3rem"
          >
            <SelectField
              className={styles.SelectField}
              value={vAccountSelected.Account}
              placeholder="Select account"
              onChange={(e) => AccountSelectedAction(e.target.value)}
            >
              {vAccountList !== undefined &&
                vAccountList.map((FromAccount) => (
                  <option
                    key={FromAccount.Account}
                    className={styles.SelectFieldOption}
                    value={FromAccount.Account}
                    label={FromAccount.Account}
                  >
                    {FromAccount.Account}
                  </option>
                ))}
            </SelectField>
            <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => ViewConfig(e)}
              >
                Account Config
              </Button>            
          </Flex>
          {vAccountList !== undefined && vAccountSelected.Account === "edwardhawke@rhyteit.com" && (
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="3rem"
            >
              <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => AddAccountRow(e)}
              >
                Add Account
              </Button>
            </Flex>
          )}

          {vAddNewAccountVisible === true && (
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="3rem"
              className={styles.FlexRow}
            >
              <TextField
                className={styles.CampaignField}
                name="Account"
                placeholder="Add new account"
                defaultValue={vNewAccount}
                onChange={(e) => NewAccount_handleChange(e, vNewAccount)}
              />
              <CheckboxField
                className={styles.CheckboxField}
                label="Campaigns"
                name="subscribe-controlled"
                checked={vCampaignsActive}
                onChange={(e) => setvCampaignsActive(e.target.checked)}
              />
              <CheckboxField
                className={styles.CheckboxField}
                label="Internal"
                name="subscribe-controlled"
                checked={vAccountType}
                onChange={(e) => setvAccountType(e.target.checked)}
              />
              <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => CreateNewAccount(e)}
              >
                Create Account
              </Button>
            </Flex>
          )}
        </Fieldset>
        {vCampaignsVisible === true && vViewPostingConfig === true &&  (
          <Fieldset className={styles.Fieldset} size="small">
            <legend>Posting Frequency</legend>
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="3rem"
              className={styles.FlexRow}
            >
              {vAccountSelected.Account !== "None" && vAccountList !== undefined && (
               <SMMADPostingConfig key={updatedKey} AccountSelected={vAccountSelected}/>
              )}
            </Flex>
          </Fieldset>
        )}

        {vCampaignsVisible === true && (
          <Fieldset className={styles.Fieldset} size="small">
            <legend>Campaigns</legend>
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="3rem"
              className={styles.FlexRow}
            >
              {vAccountSelected !== undefined && (
                <SMMADCampaignTable
                  key={updatedKey}
                  AccountSelected={vAccountSelected.Account}
                />
              )}
            </Flex>
          </Fieldset>
        )}
        {vCampaignsVisible === false && vAccountSelected !== undefined && (
          <SMMADHashTags
            key={"Default"}
            AccountSelected={vAccountSelected.Account}
            CampaignSelected={"Default"}
            ImageToTextRelationship={true}
          />
        )}
      </Flex>
    </m.div>
  );
};

export default SMMADCampaign;
