import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
 import styles from './LandingPageNavBar.module.css';  
import {Link, useNavigate } from 'react-router-dom';
import {
  Flex,
  Text,
  useAuthenticator

  } from '@aws-amplify/ui-react';
  import axios from 'axios';
  import {uuidv4} from "../../BusinessLogic/UtilitiesManagement"; 
  
import  {createAudienceGeoInfo}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging} from '../../BusinessLogic/UserManagement';
import { signOut } from 'aws-amplify/auth';

const LandingPageNavBar = (props) => {

  LandingPageNavBar.propTypes = {
      pageSource: PropTypes.string,
      User: PropTypes.string
    };

    var vFirstTouch = true
    const client = generateClient();
    const {Users, setUsers} = useContext(UserContext);    
    const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);  
    const [vpageSource, setvpageSource] = useState(props.pageSource)   
  
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    let navigate = useNavigate(); 

useEffect(() => {

     // console.log("LandingPageNavBar.useEffect[].authStatus: " ,authStatus, " - .CognitoGroup: " ,CognitoGroup, " - .Users: " ,Users);


      (async function() {
        try {  

            getData()
            
        } catch (err) {
          console.error('LandingPageNavBar.useEffect[].ERROR: ', err, err.stack); 
      }
    }
  )();   
          
},[])   

useEffect(() => {


   },[CognitoGroup])             
    

const getData = async () => {
  if (vFirstTouch === true) {
      //console.log("LandingPageV4.handleMouseOver.FirstTouch: ", FirstTouch);
         vFirstTouch = false;    


      const res = await axios.get('https://geolocation-db.com/json/')

      let DynamoUUID =  await uuidv4()

    if (res.data.IPv4 !== "98.199.173.171") {

      let AudienceGeoItem = {
        id: DynamoUUID,
        UserId: "LandingPageNavBar",
        Page: vpageSource,
        IPv4: res.data.IPv4,
        city: res.data.city,
        country_code: res.data.country_code,
        country_name: res.data.country_name,
        latitude: res.data.latitude.toString() ,
        longitude: res.data.longitude.toString(),
        postal: res.data.postal,
        state: res.data.state,
        CreationName:  vpageSource,
        UpdateName: vpageSource
      }                      

      //console.log("LandingPageNavBar.getData.AudienceGeoItem: " ,AudienceGeoItem)

      try{

        let createAudienceGeoInfoResponse = await client.graphql({                    
          query: createAudienceGeoInfo,
          variables:{
              input: AudienceGeoItem
            }
      });         
          

      } catch (err)  {
        RhyteItLogging(Users, "LandingPageNavBar", "getData",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
     }

    }
  }

}    


async function GoToMenuSignedIn() {
  try {

   // console.log("LandingPageNavBar.GoToMenuSignedIn.authStatus: " ,authStatus, " - .CognitoGroup: " ,CognitoGroup, " - .Users: " ,Users);


        navigate('/AuthenticatorRhyteIt', 
        {
          state: {
            UserId:Users,                                
            UserName:Users,                                
            CognitoGroup:CognitoGroup,
            authStatus: authStatus
          }
        }
      );  
        
        

    } catch (err) { 
      RhyteItLogging(Users, "GoToMenuSignedIn", "getData",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
  }        
}  

async function handleSignOut() {
  try {
    await signOut();
  } catch (error) {
    //console.log('error signing out: ', error);
  }
}

  return (
            <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="nowrap"
                    className={styles.GridContainer}
                  >    
                   <Flex
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              alignContent="center"
                              wrap="nowrap"
                              gap="0.25rem"
                              className={styles.LogoRow}
                            >                      
                      <img className={styles.logoimg} src="https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItFavicon2.svg" alt="RhyteIt"/>                 
                      <Link className={styles.logoName} isExternal={false} to="/LandingPageV4" >   
                          <Flex
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  alignContent="center"
                                  wrap="nowrap"
                                  gap="0.25rem"
                                >                         
                            <Text className={styles.TextUpperCase}>R</Text>
                            <Text className={styles.TextLowerCase}>hyte</Text>
                            <Text className={styles.TextUpperCase}>I</Text>
                            <Text className={styles.TextLowerCase}>t</Text>                  
                          </Flex>
                      </Link>   
                    </Flex> 
                    <Flex
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="stretch"
                              alignContent="flex-start"
                              wrap="wrap"
                              className={styles.LinkRow}
                            >          
                        <Link className={styles.Link} to="/SBSMNews">
                          Headlines
                        </Link>  
                        <Link className={styles.Link} to="/AdultContentCreatorSearch">
                          Rankings and Search
                        </Link>  
                        <Link className={styles.Link} to="/Videos">
                          About Us
                        </Link>    
                        <Link className={styles.Link} to="/Conversation">
                          Start a conversation
                        </Link>      
                        <button  className={styles.LinkButton} onClick={(e) => GoToMenuSignedIn()}>                        
                          {authStatus === "authenticated" ? "Enter" : "Sign In" }
                        </button>  
                        <button  className={styles.LinkButton} onClick={(e) => handleSignOut()}>Sign Out</button>
                      </Flex>                    
                  </Flex>   
  )
}

export default LandingPageNavBar;