
import { Amplify } from 'aws-amplify';
import React, { useState, useEffect } from 'react'
//import isUrlHttp from 'is-url-http';
//import './Conversation.css'; 
import {
    TextField, 
    Text,
    CheckboxField,
    TextAreaField,
    Button,
    Flex,
    SelectField
  } from '@aws-amplify/ui-react';
import PropTypes from 'prop-types';
import styles from './Conversation.module.css';  
import {listApplicants} from '../../graphql/queries'
import {createAudienceGeoInfo, createApplicants, createMeetings}  from '../../graphql/mutations'
import StatusMessageBox from "../../ui-components/StatusMessageBox";
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";
import MenuBackground from '../Menu/MenuBackground';
import {motion as m} from "framer-motion";
import axios from "axios";
import {uuidv4} from "../../BusinessLogic/UtilitiesManagement";
import { get_uuidv4 } from "../../BusinessLogic/uuidv4";

import { generateClient } from 'aws-amplify/api';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import Footer from '../RhyteItComponents/Footer/Footer';
import { SEO } from '../SEO/SEO';
import {RhyteItLogging} from '../../BusinessLogic/UserManagement';


const Conversation = (props) => {

    Conversation.propTypes = {
        UserEmail: PropTypes.string,
        UserId: PropTypes.string,
        UserName: PropTypes.string
      };

    
    const client = generateClient();

    const [ValidURLFlag, setValidURLFlag] = useState(false); 
    const [FirstTouch, setFirstTouch] = useState(true);      
    const [ip, setIP] = useState('');  
    let vIPAddress = ""      
    const [value, onChange] = useState(new Date());
    const [vACCSite, setvACCSite] = useState("https://onlyfans.com/");
    

    const [NewUser, setNewUser] = useState({
                                id: "",
                                UserId: "",
                                UserEmail: "",
                                UserLastName: "",
                                UserFirstName: "",
                                UserDialCode: "",
                                UserPhone: "",
                                SocialMediaProfile:  "",
                                ipAddress:  "",
                                PrivacyAcknowledgement: "",
                                VerificationCode: "",
                                VerificationStatus: "",
                                Application:  "",
                                Active:  "",
                                CreationName:  "",
                                UpdateName:  "",
                                CreationDateTime:  ""})
                                                                           

    let [Role, setRole] = useState("ContentCreator")   
    let [vMeetingContext, setvMeetingContext] = useState("")   
    let [vMeetingDateTime, setvMeetingDateTime] = useState(new Date().toISOString().substring(0, 16)) 
    let [vMinMeetingDateTime, setvMinMeetingDateTime] = useState(new Date().toISOString().substring(0, 16)) 
    let [vMeetingSet, setvMeetingSet] = useState(false) 
    let [vCity, setvCity] = useState() 
    
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  

    useEffect(() => {
        var vUpdateDateTimeNew = new Date().toISOString().substring(0, 16)
        //console.log('Conversation.useEffect[].vDateTime.toUTCString():', vUpdateDateTimeNew);
        setvMeetingDateTime(vUpdateDateTimeNew)
        setvMinMeetingDateTime(vUpdateDateTimeNew)
        getData()

        setErrorMessage("")
                
    },[])   

    useEffect(() => {

        //console.log('Conversation.useEffect[ip].ip:', ip);
        //setErrorMessage(ip)
                
    },[ip])       

    const getData = async () => {


        try {     
            const res = await axios.get('https://geolocation-db.com/json/')
            //console.log("LandingPageV4.getData.res.data: ",res.data );
            //console.log("LandingPageV4.getData.res: ",res );
            setIP(res.data.IPv4)   
            setvCity(res.data.city)    
        } catch (err) { //console.log('error fetching Users')
            RhyteItLogging("None", "Conversation", "getData",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                    }
    }  

    function UserEmail_handleChange(event, data) {
        try {              
            
            // //console.log('UserEmail_handleChange.data.email:', data.email);

            // //console.log('UserEmail_handleChange.event.target.value:', event.target.value);

            NewUser.UserId = event.target.value;
            
            setNewUser(NewUser)

          
    } catch (err) { //console.log('error fetching Users')
        RhyteItLogging(ip, "Conversation", "UserEmail_handleChange",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                }
    }  
    
    function SocialMediaProfile_handleChange(event, data) {
        try {              
            
            //console.log('UserEmail_handleChange.data.email:', data.email);

            //console.log('SocialMediaProfile_handleChange.event.target.value:', event.target.value);

            NewUser.SocialMediaProfile = event.target.value;
            
            setNewUser(NewUser)

            } catch (err) { //console.log('error TeamsUserId_handleChange')
            console.error(err, err.stack); 
        }        
    }  
     
    function MeetingContext_handleChange(event, data) {
        try {              
            
            setvMeetingContext(event.target.value)

            } catch (err) { //console.log('error TeamsUserId_handleChange')
            console.error(err, err.stack); 
        }        
    }  

    function MeetingDateTime_handleChange(event, data) {
        try {              
            
            //console.log("Conversation.MeetingDateTime_handleChange.event.target.value: ",event.target.value );
            let vMeetingDateTimeChange = new Date(event.target.value)


            //console.log("Conversation.MeetingDateTime_handleChange.vMeetingDateTimeChange: ",vMeetingDateTimeChange.toISOString() );
            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            
            //console.log("Conversation.MeetingDateTime_handleChange.tomorrow: ",tomorrow );
            
            if (vMeetingDateTimeChange > tomorrow) {

                setvMeetingDateTime(event.target.value)
                setErrorMessage("")
            
            } else {
                let ProcessApplicantErrorMessage = "Date and time need to be tomorrow or beyond"
                setErrorMessage(ProcessApplicantErrorMessage)
            }

            } catch (err) { //console.log('error TeamsUserId_handleChange')
            console.error(err, err.stack); 
        }        
    }  
         
    async function ProcessApplicant(e, data) {
        try {

            let ProcessApplicantErrorMessage = ""

            let IPAddressAlreadyExists = await CheckIPAddress()

            let tomorrow = new Date();
    
            tomorrow.setDate(tomorrow.getDate() + 1);


            if ( IPAddressAlreadyExists === false) {

                let EmailAddressAlreadyExists = await CheckEmailAddress(NewUser.UserId)

                if (EmailAddressAlreadyExists === true) {
                        
                    ProcessApplicantErrorMessage = "We already have you."
                    setErrorMessage(ProcessApplicantErrorMessage)
    
                } else if (EmailAddressAlreadyExists === false) {
            
                    if (NewUser.UserId !== "") {

                        User_Add(NewUser);
                        CreateMeeting(NewUser);
                        
                        ProcessApplicantErrorMessage = "Congratulations on your first step to OnlyFans success. \n We will set up the meeting within the next 24 hours. \n"
                        setErrorMessage(ProcessApplicantErrorMessage)
                        setvMeetingSet(true)

                    } else {
                        
                        ProcessApplicantErrorMessage = "Form is incomplete"
                        setErrorMessage(ProcessApplicantErrorMessage)

                    }

                }

            }

    } catch (err) { //console.log('error fetching Users')
        RhyteItLogging(ip, "Conversation", "ProcessApplicant",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                }
    }      

    async function CheckIPAddress() {
        try {

            let vCheckIPAddressReturnMessage = false
            //console.log("Conversation.CheckIPAddress.NewUser: ", NewUser )
            //console.log("Conversation.CheckIPAddress.NewUser[0].email: ", NewUser.UserId , " - ip address: ", ip)

            const variables = {
                filter: {
                    ipAddress: {
                        eq: ip             
                    }
                }
              };

            const ApplicantIPAddressCheck  = await client.graphql({
                query: listApplicants,
                variables: variables
              });

            const vIPAddressCheck = ApplicantIPAddressCheck.data.listApplicants.items          
            
            //console.log("Conversation.CheckIPAddress.vIPAddressCheck: ", vIPAddressCheck)

            if (vIPAddressCheck.length > 0) {

                vCheckIPAddressReturnMessage = true
                let fetchUserIdErrorMessage = "You have already applied."
                setErrorMessage(fetchUserIdErrorMessage)

            }
            else if (vIPAddressCheck.length === 0) {                
                vCheckIPAddressReturnMessage = false
            }         
        
    
            //console.log("Conversation.CheckIPAddress.vCheckIPAddressReturnMessage: ", vCheckIPAddressReturnMessage)
            return vCheckIPAddressReturnMessage

        } catch (err) { //console.log('error fetching Users')
            RhyteItLogging(ip, "Conversation", "CheckIPAddress",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                    }
    }   

    async function CheckEmailAddress(pApplicantEmail) {
        try {

            let vCheckEmailAddressReturnMessage = false
            //console.log("Conversation.CheckEmailAddress.pApplicantEmail: ", pApplicantEmail)

            const variables = {
                filter: {
                    UserId: {
                        eq: pApplicantEmail
                    }
                }
              };

            const ApplicantEmailAddressCheck  = await client.graphql({
                query: listApplicants,
                variables: variables
              });            


     
            const vEmailAddressCheck = ApplicantEmailAddressCheck.data.listApplicants.items          
            
            if (vEmailAddressCheck.length > 0) {

                vCheckEmailAddressReturnMessage = true
                let fetchUserIdErrorMessage = "You have connected with us. Email us support@rhyteit.com"
                setErrorMessage(fetchUserIdErrorMessage)

            }
            else if (vEmailAddressCheck.length === 0) {                
                vCheckEmailAddressReturnMessage = false
            }         
        
    
            return vCheckEmailAddressReturnMessage

        } catch (err) { //console.log('error fetching Users')
            RhyteItLogging(ip, "Conversation", "CheckEmailAddress",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                    }
    }   

    async function User_Add(pNewUser) {
        try {

            let ReturnMessage = ""


            //console.log("Conversation.User_Add.ip: ",ip)            

            let vIPTemp = "1111111"

            if (ip === undefined || ip === null || ip === "") {

                vIPTemp = "111111111"

            } else {

                vIPTemp = ip

            }         

                    setValidURLFlag(true)       

                    let vApplicant = {
                        id: pNewUser.UserId,
                        UserId: pNewUser.UserId,
                        UserLastName: "",
                        UserFirstName: "",
                        ipAddress: vIPTemp,
                        UserPhone: "+18005555555",
                        UserEmail: pNewUser.UserId,
                        SocialMediaProfile: vACCSite + "" + pNewUser.SocialMediaProfile,
                        PrivacyAcknowledgement: true,
                        EmailTemplate: "Welcome Gift",
                        Application: "New",
                        Role: Role,
                        InOnlyFansProspectingProfile: false,
                        CreationName: pNewUser.UserId,
                        UpdateName: pNewUser.UserId,
                        Active: true
                    }      

                    //let arr = CollabortionToolStatusSchedulers.concat(NewCollabortionToolStatusSchedule);
                    setNewUser(vApplicant);
                    //console.log("Conversation.User_Add.vApplicant: ",vApplicant)

                        try {

                            const ReturnMessage = await client.graphql({
                                query: createApplicants,
                                variables: {
                                  input: vApplicant
                                }
                              });             
                            //console.log("Conversation.User_Add.ReturnMessage: ",ReturnMessage)
                            if (ReturnMessage.data.createApplicants.CreationDateTime.length > 0 ) {

                                var vUpdateDateTime = new Date().toISOString()

                                ErrorMessageStr = "Thank you for connecting with us " + ReturnMessage.data.createApplicants.UserId +  ". We look forward to speaking with you." 
                                setErrorMessage(ErrorMessageStr)
                                
                            }                    

                        } catch (err) { 

                            RhyteItLogging(ip, "Conversation", "User_Add",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                    }     


        if (pNewUser.SocialMediaProfile.length === 0) {
            ErrorMessageStr = "Please enter your adult content profile url"
            console.error(ErrorMessageStr); 
            setErrorMessage(ErrorMessageStr)
            
            setValidURLFlag(false)       

        }




        } catch (err) {   
                ErrorMessageStr = 'Conversation.User_Add.ERROR:  ' + err 
                console.error(ErrorMessageStr); 
                setErrorMessage(ErrorMessageStr)
        }        
    }     

    async function CreateMeeting(pNewUser) {
        try {

            let ReturnMessage = ""
            var DynamoUUID =  get_uuidv4()
            
            let vMeetingDateTimeChange = new Date(vMeetingDateTime).toISOString()


            let vIPTemp = "1111111"

            if (ip === undefined || ip === null || ip === "") {

                vIPTemp = "111111111"
            } else {

                vIPTemp = ip

            }            

            //console.log("Conversation.CreateMeeting.vMeetingDateTimeChange: ",vMeetingDateTimeChange );
                
            let MeetingItem = {
                id: pNewUser.UserId,
                UserEmail: pNewUser.UserId,
                UserType: Role,
                MeetingType: "IntroConversation",
                MeetingStatus: "new",
                MeetingContext: vMeetingContext,
                ipAddress: vIPTemp,
                MeetingDateTime: vMeetingDateTimeChange,
                Active: true,
                CreationName: pNewUser.UserId,
                UpdateName: pNewUser.UserId
            }      

            let ReturnMessage1 = await client.graphql({
                query: createMeetings,
                variables: {
                    input: MeetingItem
                }
            });             
                    


        } catch (err) {   
            RhyteItLogging(ip, "Conversation", "CreateMeeting",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
        }        
    }     
        
    function RoleSelected(pRole) {
        try {
                  //console.log("ReportSelected.pReport: ",pReport);
                  setRole(pRole)
  
        } catch (err) { 
            RhyteItLogging(ip, "Conversation", "RoleSelected",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                    }
    } 
        
    function ACCSiteSelected(pACCSite) {
        try {
                  //console.log("ReportSelected.pReport: ",pReport);
                  setvACCSite(pACCSite)
  
        } catch (err) { 
            RhyteItLogging(ip, "Conversation", "ACCSiteSelected",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                    }
    } 

    return (
        <m.div
                key="LandingPageV2"
                initial={{y: "100%"}}
                animate={{y: "0%"}}
                transition={{duration:0.75, ease: "easeOut"}}
                exit={{ opacity: 1 }}
               >
               <SEO
                   title="Let's talk about you!"
                   description='RhyteIt will help you grow your fan base'
                   name='RhyteIt'
                   type='article' />   
            <div className = {styles.BackroundImage}>
                <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#777"   GradientStopColor4="#8880"/> 
            
                <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="wrap"
                        gap="1rem"
                        className = {styles.SignUpFormDiv}>    
                    <HeaderMenu pageSource="Application"/>      
                    <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="wrap"
                        gap="3rem"
                        className = {styles.SignUpContentSection}>         
                            <Flex
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                alignContent="flex-start"
                                wrap="wrap"
                                gap="0.25rem"
                                className={styles.SignUpContentSectionCenterLeftColumn}>
                                { vMeetingSet === false && (
                                <Flex
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    alignContent="flex-start"
                                    wrap="wrap"
                                    gap="0.25rem"
                                    className = {styles.SignUpFormContainer}>  
                                    { vCity !== undefined && (
                                        <label className = {styles.CurrentlySeeking}>
                                            Currently seeking adult content creators in <label className = {styles.CityLabel}>{vCity}</label>
                                        </label> 
                                    )}         
                                    <label className = {styles.Title}>
                                    Ready to boost your OnlyFans success?
                                    </label>       
                                    <div className={styles.SignUpFormContainer}> 
                                        <div className = {styles.HeaderLine}/>                            
                                    </div>                          
                                    <Text className = {styles.SubTitle}>
                                    Hey there, adult content creator!👋 
                                    </Text>                       
                                    <Text className = {styles.SubTitlePurple}>
                                    Ready to level up your OnlyFans game? 
                                    </Text>                       
                                    <Text className = {styles.SubTitleWhite}>
                                    We're here to help you shine brighter and earn more. 
                                    </Text>                          
                                    <Text className = {styles.SubTitlePurple}>
                                    Just drop us a few details, and we'll get the ball rolling on our chat!
                                    </Text>               
                                </Flex>   
                                )}          
                                { vMeetingSet === false && (                          
                                <form className = {styles.SignUpForm}>
                                    <Flex
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="stretch"
                                            alignContent="flex-start"
                                            wrap="wrap"
                                            gap="1rem"
                                            className = {styles.SignUpFormContent}>  

                                                <Text 
                                                className={styles.DateTimeLabel}>
                                                    Email                                                   
                                                </Text>                                                                        
                                                <TextField  
                                                    className={ styles.EmailFieldHighlighted }
                                                    type="email" 
                                                    color="white"
                                                    isRequired={true}  
                                                    placeholder="Please enter your email"  
                                                    defaultValue={NewUser.UserId}
                                                    name="NewUser.Email" 
                                                    onChange={ (e) => UserEmail_handleChange(e) } 
                                                    />  
                                                <Text 
                                                className={styles.DateTimeLabel}>
                                                Choose your site
                                                </Text> 
                                                <SelectField
                                                    value={vACCSite}
                                                    onChange={(e) => ACCSiteSelected(e.target.value)}
                                                    className={styles.SelectField}                                                   
                                                    >
                                                    <option value="https://onlyfans.com/">OnlyFans</option>
                                                    <option value="https://fansly.com/">Fansly</option>
                                                    <option value="https://www.fanvue.com/">Fanvue</option>
                                                    <option value="https://fancentro.com/">Fancentro</option>
                                                    <option value="https://justforfans.app/">Just For Fans</option>
                                                    <option value="https://other.com/">Other</option>
                                                </SelectField> 
                                                <Text 
                                                    className={styles.DateTimeLabel}>
                                                    Your OF name (or other site)                                       
                                                </Text>                                                                                  
                                                <TextField  
                                                    className={ValidURLFlag === true ? styles.SignUpFormEmailField : styles.SignUpFormEmailField }
                                                    type="text" 
                                                    color="white"
                                                    isRequired={true}  
                                                    placeholder="ex: thetulsaredbear, roxystylez"  
                                                    defaultValue={NewUser.SocialMediaProfile}
                                                    name="NewUser.SocialMediaProfile"                              
                                                    onChange={ (e) => SocialMediaProfile_handleChange(e) } 
                                                    />    
                                                <Text 
                                                    className={ValidURLFlag === true ? styles.DateTimeLabel: styles.DateTimeLabel }>
                                                    Preferred Date and Time for our Zoom Call:
                                                </Text>
                                                <input 
                                                    className={styles.DateTimePicketInput} 
                                                    aria-label="Date and time" type="datetime-local" 
                                                    value={vMeetingDateTime}
                                                    min={vMinMeetingDateTime}
                                                    onChange={ (e) => MeetingDateTime_handleChange(e) } 
                                                    />      

                                                <Text 
                                                className={styles.DateTimeLabel}>
                                                Choose your role
                                                </Text>                                                                       
                                                <SelectField
                                                    value={Role}
                                                    onChange={(e) => RoleSelected(e.target.value)}
                                                    className={styles.SelectField}                                                                                    
                                                    >
                                                    <option value="ContentCreator">Content Creator</option>
                                                    <option value="Manager">Manager</option>
                                                    <option value="Chatter">Chatter</option>
                                                    <option value="Marketer">Marketer</option>
                                                </SelectField>   
                                                <TextAreaField 
                                                    label={
                                                        <Text 
                                                        className={ValidURLFlag === true ? styles.EmailFieldHighlighted: styles.EmailFieldHighlighted }>
                                                        Any topics you want to cover?
                                                        </Text>
                                                    }                                              
                                                    className={styles.TextAreaField} 
                                                    name="MeetingContext"     
                                                    placeholder="Ex: I need help with..., I would like to know how to..., Can you do..."                   
                                                    defaultValue={vMeetingContext}
                                                    onChange={ (e) => MeetingContext_handleChange(e, vMeetingContext) }
                                                    rows={5}
                                                    columns={3}/>                                                                                      
                                            <span className={styles.SignUpFormSpan}></span> 
                                            <div className={styles.SignUpButtonContainer} >
                                                <Button
                                                    className={styles.metallicbutton}
                                                    loadingText=""
                                                    onClick={ (e) => ProcessApplicant(e, NewUser) } 
                                                    ariaLabel=""
                                                    >
                                                Let's Talk
                                                </Button>    
                                            </div>                                                                                                                                                              
                                    </Flex>
                                </form> 
                                )}       
                                { vMeetingSet === true && (   
                                 <Flex
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    alignContent="flex-start"
                                    wrap="wrap"
                                    gap="0.25rem"
                                    className = {styles.SignUpFormContainer}>    
                                        <Text className = {styles.MeetingSetCongratulationsText}>CONGRATULATIONS!
                                        </Text>                       
                                        <Text className = {styles.MeetingSetCompleteText}>
                                        We will schedule a meeting with you in the next 24 hours.
                                        </Text>                       
                                        <Text className = {styles.MeetingSetCompleteText}>
                                        We look forward to working with you :)
                                        </Text>                        
                                        <Text className = {styles.MeetingSetCompleteText}>
                                        RhyteIt
                                        </Text>                        
                                    </Flex>      
                                    )}            
                                <Flex
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    alignContent="flex-start"
                                    wrap="wrap"
                                    gap="0.25rem"
                                    className = {styles.SignUpFormContainer}>    
                                    <Text className = {styles.SubTitle}>
                                    Your success starts here. Let's make it happen together!
                                    </Text>                       
                                    <Text className = {styles.SubTitle}>
                                    Warm regards,
                                    </Text>                       
                                    <Text className = {styles.SubTitlePurple}>
                                    Jaime Rincon
                                    </Text>                        
                                    <Text className = {styles.SubTitle}>
                                    RhyteIt
                                    </Text>                        
                                </Flex>                                       
                            </Flex> 
                    </Flex>
                    <Footer/>
                </Flex>        
            </div>
  
            </m.div>
                
    );
}

export default Conversation;    