import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { SEO } from '../SEO/SEO';
import {ErrorBoundary} from 'react-error-boundary'
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";
import {motion as m} from "framer-motion";

import  { }  from '../../graphql/queries'
import  { createAudienceGeoInfo }  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {GetSBSMNewsArticlesAd, GetLogos} from '../../BusinessLogic/SBSMNewsManagement';
import SBSMNewsTable from "./SBSMNewsTable";

import { UserContext} from '../../BusinessLogic/Hooks/UserContext';

import axios from 'axios';
import {uuidv4} from "../../BusinessLogic/UtilitiesManagement";

import { Flex
} from '@aws-amplify/ui-react';
import styles from './SBSMNews.module.css';
import {GetSBSMNewsQueryTermsAd} from '../../BusinessLogic/SBSMQueryTermsManagement';

import MenuBackground from '../Menu/MenuBackground';
import {RhyteItLogging} from '../../BusinessLogic/UserManagement';

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const SBSMNews = (props) => {       
  
  SBSMNews.propTypes = {
          RedditUser: PropTypes.object
        };

        const client = generateClient();
        const [FirstTouch, setFirstTouch] = useState(true);      
        const [ip, setIP] = useState('');  

        const [SBSMNewsArticles, setSBSMNewsArticles] = useState([{id: '',
              UserId: '',
              Article:  '',
              InfoType:  '',
              Title: '',
              URL: '',
              Source: '',
              PubDate: '',
              SBSMStatus: '',
              Penalty: '',
              UpVotes: '',
              DownVotes: '',
              AgeInHours: '',
              CreationName:  '',
              UpdateName:  '',
              CreationDateTime:  '',
              UpdateDateTime:  '' }])

        const [SBSMLogos, setSBSMLogos] = useState();   

        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
        const [updatedKey, setupdatedKey] = useState(Date.now())   
        let debugConsoleOutPut = true

        const {Users, setUsers} = useContext(UserContext);   
        const [Article, setArticle] = useState([]);
        const [ShowTable, setShowTable] = useState(false);

        useEffect(() => {

          (async function() {
            try {  

                  getData()
                  
                  GetSBSMNewsArticles()

                } catch (err) {              
                  RhyteItLogging(Users, "SBSMNews", "useEffect[]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
              }
            }
          )();   
      

        },[]) 

        useEffect(() => {

          if (Object.keys(SBSMNewsArticles).length > 1) {
            setShowTable(true)
          }

          setErrorMessage("SBSM items retrieved at: " + new Date().toLocaleString())

        },[SBSMNewsArticles])     
       

          
        useEffect(() => {
      
          //console.log("SBSMNews.useEffect[updatedKey].updatedKey:  ",updatedKey)
          //console.log("SBSMNews.useEffect[updatedKey].ShowTable:  ",ShowTable)
          //console.log("SBSMNews.useEffect[updatedKey].Object.keys(SBSMNewsArticles).length:  ",Object.keys(SBSMNewsArticles).length)

          if (Object.keys(SBSMNewsArticles).length > 1) {
            setShowTable(true)
          }


        }, [updatedKey]);      
  

        const getData = async () => {
          //console.log("LandingPageV4.handleMouseOver.FirstTouch - Before: ", FirstTouch);
          if (FirstTouch === true) {
              //console.log("LandingPageV4.handleMouseOver.FirstTouch: ", FirstTouch);
              setFirstTouch(false);    
              //console.log("LandingPageV4.handleMouseOver.FirstTouch2: ", FirstTouch);
    
              const res = await axios.get('https://geolocation-db.com/json/')
              //console.log("LandingPageV4.getData.res.data: ",res.data );
              //console.log("LandingPageV4.getData.res: ",res );
              setIP(res.data.IPv4)
    
              let DynamoUUID =  await uuidv4()
    
              //vIPAddress = res.data.IPv4
    
            if (res.data.IPv4 !== "98.199.173.171") {
    
              let SaveAreaData = {
              id: DynamoUUID,
              UserId: "edwardhawke@rhyteit.com",
              Page: "SBSMNews",
              IPv4: res.data.IPv4,
              city: res.data.city,
              country_code: res.data.country_code,
              country_name: res.data.country_name,
              latitude: res.data.latitude,
              longitude: res.data.longitude,
              postal: res.data.postal,
              state: res.data.state,
              CreationName:  "SBSMNews",
              UpdateName: "SBSMNews"
              }                      
    
              //console.log("LandingPageV4.getData.SaveSubReddit: " ,SaveSubReddit)
    
              try{
    

                let createAudienceGeoInfoResponse = await client.graphql({                    
                  query: createAudienceGeoInfo,
                  variables:{input: {SaveAreaData
                  }
                }
              });
                  
                  //console.log("LandingPageV4.getData.createAudienceGeoInfoResponse: " ,createAudienceGeoInfoResponse)
    
              } catch (err)  {
              ErrorMessageStr = 'SBSMNews.getData.ERROR: ' + err + err.stack
              setErrorMessage(ErrorMessageStr)
              console.error(ErrorMessageStr); 
              }
    
            }
          }
    
        }  

        async function GetSBSMNewsArticles() {
          try {
                    setErrorMessage("")
          
                    if (debugConsoleOutPut === true) {
                 //     //console.log("SBSMNews.GetSBSMNewsArticles.Users: ", Users)
                    }
                    let vArticlesTmp = SBSMNewsArticles 

                    let {Articles, nextToken} = await GetSBSMNewsArticlesAd()

                    Articles.forEach(item => 
                      {    
                    
                        if ([...vArticlesTmp].filter(Article => item.id === Article.id)["length"] === 0) {  
                          vArticlesTmp.push(item) 
                        }

                        if (vArticlesTmp.length === 0) {

                          vArticlesTmp.push(...Articles) 

                        }
  
                      })                    

                    let vNextToken = nextToken

                      

                    while (vNextToken !== null) {
                      
                      let {Articles, nextToken} = await GetSBSMNewsArticlesAd(vNextToken)  
                    //  console.log("SBSMNews.GetSBSMNewsArticles.Articles -2:  ",Articles);
                   //   console.log("SBSMNews.GetSBSMNewsArticles.Articles.length - 2:  ",Articles.length);                    
                   //   console.log("SBSMNews.GetSBSMNewsArticles.NextToken - 2:  ",nextToken.substring(nextToken.length - 10)); 
                      Articles.forEach(item => 
                        {    
                      
                          if ([...vArticlesTmp].filter(Article => item.id === Article.id)["length"] === 0) {  
                            vArticlesTmp.push(item) 
                          }

                          if (vArticlesTmp.length === 0) {

                            vArticlesTmp.push(...Articles) 

                          }

                        })    
                      vNextToken = nextToken

                    }
                    
                    let FilterOutBlanks = [...vArticlesTmp].filter(Article => Article.id !== "")

                    let vSortedArticles = FilterOutBlanks.sort( compare );
                    
                    const vLogos = await GetSBSMNewsQueryTermsAd(Users)
                                            
                   // console.log("SBSMNews.GetSBSMNewsArticles.vSortedArticles:  ",vSortedArticles);

                    setSBSMLogos(vLogos)
                    setSBSMNewsArticles(vSortedArticles)

                    setupdatedKey(Date.now())

  
          } catch (err) { 
            RhyteItLogging(Users, "SBSMNews", "GetSBSMNewsArticles",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }    
        

        function compare( a, b ) {
          if ( a.Score > b.Score ){
            return -1;
          }
          if ( a.Score < b.Score ){
            return 1;
          }
          return 0;
        }
      
    return ( 
      <m.div
      key="SBSMNews"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
            <SEO
              title="Find all the latest news, podcasts and videos about the adult content creator industry!"
              description='Live feed'
              name='RhyteIt'
              type='article' />  
            <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#484890"   GradientStopColor4="#8880"/>                                   

              <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="2rem"
                    className = {styles.MasterContainer}>                 
                      <HeaderMenu  pageSource="SBSMNews" User={Users}/>
                  <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="1rem"
                    className = {styles.MasterContainer}>                       
                      <h1 className={styles.h1}>Adult Content Creator News</h1>  
                      <div className={styles.headerline} />
                      <h2 className={styles.h2}>See, vote and comment on the latest industry news, podcasts, articles and videos</h2>  
                      <div className={styles.ArticleMessageBox}>{ErrorMessage}</div>  
                      {ShowTable && (<SBSMNewsTable  key={updatedKey} SBSMLogos={SBSMLogos} SBSMNewsArticles={SBSMNewsArticles}/>)  }
                    </Flex> 
                  </Flex> 
          </m.div>          

    )

  }
 

export default SBSMNews;