
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  Label,
  CheckboxField,
  TextField,
  Flex,
  SelectField,
  Button, Fieldset 
  } from '@aws-amplify/ui-react'; 
import styles from './SMMADCampaignNewField.module.css';
import  { listSMMADCampaigns, listSMMADHashTags }  from '../../../graphql/queries'
import  { deleteSMMADContent, updateSMMADCampaigns, createSMMADCampaigns}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

const SMMADCampaignNewField = (props) => {       

  SMMADCampaignNewField.propTypes = {
      AccountSelected: PropTypes.string
      };

    const client = generateClient();
    const [vCampaign, setvCampaign] = useState("")  
    const [vAccountSelected, setvAccountSelected] = useState(props.AccountSelected)     
    const [vContentTextActive, setvContentTextActive] = useState(false)         
    const [vContentTextInActive, setvContentTextInActive] = useState(false)        


    

    useEffect(() => {

    },[])  

    function Campaign_handleChange(event) {
      try {
                  console.log("SMMADCampaignRow.Campaign_handleChange.event.target.value:  ",event.target.value)   
                  setvCampaign(event.target.value)
             
      } catch (err) { 
                      console.error("SMMADCampaignRow.Campaign_handleChange.ERROR: ", err, err.stack); 
                  }
    }  

    async function CreateCampaign() {
      try {              
          
      let vAccountType = ""
       
      //console.log("SMMADCampaignNewField.useEffect[].CreateCampaign.vAccountSelected:  ",vAccountSelected);

       if (vAccountSelected.includes("RhyteIt") || vAccountSelected.includes("@rhyteit.com") || vAccountSelected.includes("@richbi.ch"))  {

        vAccountType = "Internal"
       
      } else {
      
        vAccountType = "Customer"

       }

       var vUpdateDateTimeNew = new Date().toISOString()
      

       let CampaignItem = {
           "id": vAccountSelected + "_" + vCampaign,
           "Account": vAccountSelected,
           "AccountType": vAccountType,
           "Campaign": vCampaign,
           "Description":vCampaign,
           "SMMADCampaignStatus": "new",
           "ContentTextActive": vContentTextActive,
           "Active": true,
           "Weight": 0,
           "PostedCount": 0,
           "CreationName": vAccountSelected,
           "CreationDateTime": vUpdateDateTimeNew,
           "UpdateName": vAccountSelected,
           "UpdateDateTime": vUpdateDateTimeNew,
       }

       
      //console.log("SMMADCampaignNewField.useEffect[].CreateCampaign.CampaignItem:  ",CampaignItem);
      
 
           
           const createSMMADCampaignResponse = await client.graphql({
             query: createSMMADCampaigns,
             variables: {
               input: CampaignItem
             }
           });  


        } catch (err) { 
          console.error("SMMADCampaignNewField.AddCampaignRow.Error:  ".err, err.stack); 
      }        
    }  

    async function Text2ImageAssociationRule(pRuleChoice) {
      try {
              //    console.log("SMMADCampaignRow.Text2ImageAssociationRule.pRuleChoice:  ",pRuleChoice)   

            let vRuleChoiceTemp = pRuleChoice === "vContentTextInActive" ? true : false
            let vRuleChoiceTemp1 = pRuleChoice === "vContentTextActive" ? true : false


          //  console.log("SMMADCampaignRow.Text2ImageAssociationRule.vRuleChoiceTemp1:  ",vRuleChoiceTemp1)  
           // console.log("SMMADCampaignRow.Text2ImageAssociationRule.vRuleChoiceTemp:  ",vRuleChoiceTemp)  

            
            setvContentTextInActive(vRuleChoiceTemp)
            setvContentTextActive(vRuleChoiceTemp1)
             
      } catch (err) { 
                      console.error("SMMADCampaignRow.Text2ImageAssociationRule.ERROR: ", err, err.stack); 
                  }
    }  

    return (  
      <Fieldset 
          size="small">    
          <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="3rem"
              className={styles.FlexRow} 
          >  
                <TextField 
                    className={styles.CampaignField} 
                    name="vCampaign"     
                    placeholder="Add new campaign"         
                    defaultValue={vCampaign}
                    onChange={ (e) => Campaign_handleChange(e) }/>                                                                  
                <Button className={styles.Button}
                    variation="primary"         
                    onClick={ (e) => CreateCampaign(e)}
                    >
                          Create Campaign
                    </Button>                                                                                                                                                                                                                                                    
            </Flex>   

            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="3rem"
                className={styles.FlexRow} 
              >     
                                 
              <CheckboxField            
                className={styles.CheckboxField}  
                checked={vContentTextActive}
                onChange={(e) => Text2ImageAssociationRule("vContentTextActive")}
              />    
              <Label            
                className={styles.Label}           
                >Associate multiple captions to a specific images/videos</Label> 

            </Flex>   

            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="3rem"
                className={styles.FlexRow} 
              >     
                                 
              <CheckboxField            
                className={styles.CheckboxField}  
                checked={vContentTextInActive}
                onChange={(e) => Text2ImageAssociationRule("vContentTextInActive")}
              />    
              <Label            
                className={styles.Label}           
                >Any caption can be associated with any image/video in campaign</Label> 
                                                                                                                                                                                                                                                                                
            </Flex>               
            </Fieldset>

    )
  }
export default SMMADCampaignNewField;


