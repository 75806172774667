import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useRef } from 'react';

import  { listSMMADAccounts }  from '../../../graphql/queries'
import  {updateSMMADAccounts }  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import Header from "../../RhyteItComponents/Header/Header";
import { UserContext, CognitoGroupContext} from '../../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';
import {motion as m} from "framer-motion";
import MenuBackground from '../../Menu/MenuBackground';


import {
  Button , Flex, Divider, Label, TextField, SelectField,Text,TableCell,Table, TableBody, TableRow, Fieldset, CheckboxField
} from '@aws-amplify/ui-react';
import styles from './SMMADUserManagement.module.css';

const SMMADUserManagement = (props) => {       
  
  SMMADUserManagement.propTypes = {
          RedditUser: PropTypes.object
        };

        const client = generateClient();
        const [vSMMADUserManagement, setvSMMADUserManagement] = useState([]) 
        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/SMMADUserManagement", Title:"TurboFanBoost", isCurrent:true}]
     
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr) 

        const [vCampaignsActive, setvCampaignsActive] = useState(false)   
        const [vAccountType, setvAccountType] = useState(false)     
        
        const [vAccountSelected, setvAccountSelected] = useState(); 
        const [vTurbFanAccounts, setvTurbFanAccounts] = useState();  
        const [vTurbFanAccount, setvTurbFanAccount] = useState();       
        let [vAccountList, setvAccountList] = useState();     
        let [vNewAccount, setvNewAccount] = useState();  

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);    

        const [vCampaignsVisible, setvCampaignsVisible] = useState(false)   
        
   

        useEffect(() => {
          
          window.scrollTo(0, 0);

        },[]) 



           useEffect(() => {
             
            (async function() {
              try {  
  
                      if (CognitoGroup !== undefined) {
  
                        let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "RhyteItSMMADUserGroup")
                        
                        if (GetGroupRoleAccessResponse) {  
                          GetSMMADAccounts()
                        }
                            
  
                      } else {
  
                        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
  
                        if (idToken !== undefined) {
                          setUsers(idToken.payload.email)
                          setCognitoGroup(accessToken["payload"]["cognito:groups"])
                        } else {
                          
                          ReturnUserToLandingPage(Users, CognitoGroup)
                        }
  
                      }
                    } catch (err) {                  
                      RhyteItLogging(Users, "SMMADUserManagement", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                  }
                }
              )();   
      
             },[CognitoGroup])               

         useEffect(() => {

          if (vTurbFanAccounts !== undefined) {
  
          let vAccount = vTurbFanAccounts.filter((TurbFanAccount) => TurbFanAccount["Account"] === vAccountSelected)

          setvTurbFanAccount(vAccount[0])

          }

        },[vAccountSelected])  


        useEffect(() => {

          if (vAccountList !== undefined && Users !== "edwardhawke@rhyteit.com") {
  
            AccountSelectedAction(vAccountList[0]["Account"])

          }

        },[vAccountList])           
                   
        useEffect(() => {

          if (vTurbFanAccount !== undefined) {

            let vAccountTypeTmp = (vTurbFanAccount.AccountType ===  "Internal" ? true  :   false )   
  
            setvAccountType(vAccountTypeTmp)  
            setvCampaignsActive(vTurbFanAccount.CampaignsActive)

          }
        

        },[vTurbFanAccount])    
                


        async function GetSMMADAccounts() {
          try {
                    setErrorMessage("")
                    setvSMMADUserManagement([])
                    let variables 
                if (Users !== "edwardhawke@rhyteit.com") {

                    variables = {
                      filter: {
                        Account: {
                                eq: Users
                            }
                      }
                    };
                }
                  else {
                    variables = {};

                  }

                    const listSMMADAccountsData2  = await client.graphql({
                      query: listSMMADAccounts,
                      variables: variables
                    });
      
          

                    if (listSMMADAccountsData2.data.listSMMADAccounts.items.length > 0) {

                        const SMMADUserManagement1 = listSMMADAccountsData2.data.listSMMADAccounts.items 


                        let vAccountListArray = []
                      
                        SMMADUserManagement1.forEach(async (Campaign) => {

                          if (vAccountListArray.filter((vcampaign) => vcampaign["Account"] === Campaign["Account"]).length === 0) {

                            if (Campaign["Account"] !== "RhyteIt") {
                              vAccountListArray.push({"Account": Campaign["Account"], "CampaignsActive": Campaign["CampaignsActive"], "TwitterConsumerKey": Campaign["TwitterConsumerKey"]})  
                          
                            }
                            

                          }
                        });

                        console.log("SMMADUserManagement.GetSMMADAccounts.vAccountListArray: ", vAccountListArray);

                        let vAccountListArraySorted  = SortAccountsByName(vAccountListArray)
                        //console.log("SMMADUserManagement.GetSMMADAccounts.vAccountListArraySorted: ", vAccountListArraySorted);
                        setvAccountList(vAccountListArraySorted)
                        
                        setvTurbFanAccounts(SMMADUserManagement1)
           
                    }

          } catch (err) { 
                  RhyteItLogging(Users, "SMMADUserManagement", "GetSMMADAccounts",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }   

        function SortAccountsByName(pRhyteItAccounts) {
          pRhyteItAccounts.sort(GFG_sortFunction);
              return pRhyteItAccounts
          //    //console.log(JSON.stringify(pEmails));
          }
    
        function GFG_sortFunction(a, b) {
            let RhyteItAccountA = a.Account;
            let RhyteItAccountB = b.Account;
            return RhyteItAccountA > RhyteItAccountB ? 1 : -1;
        };     


 
              
        async function SaveAccount(pSMMADAccountStatus) {
          try {           
              
              var vUpdateDateTimeNew = new Date().toISOString()
          
    
              let AccountItem = {
                  "id": vTurbFanAccount["id"],
                  "Account": vTurbFanAccount["Account"],
                  "AccountType": (vAccountType === false ? "Customer"  :   "Internal"  ),
                  "CampaignsActive": vCampaignsActive,
                  "SMMADAccountStatus": pSMMADAccountStatus,
                  "Active": vTurbFanAccount["Active"],
                  "Weight": vTurbFanAccount["Weight"],
                  "PostedCount": vTurbFanAccount["PostedCount"],
                  "PurchaseId": vTurbFanAccount["PurchaseId"],
                  "ProductId": vTurbFanAccount["ProductId"],
                  "TwitterAccount": vTurbFanAccount["TwitterAccount"],
                  "TwitterPassword": vTurbFanAccount["TwitterPassword"],
                  "TwitterConsumerKey": vTurbFanAccount["TwitterConsumerKey"],
                  "TwitterConsumerSecret": vTurbFanAccount["TwitterConsumerSecret"],
                  "TwitterBearerToken": vTurbFanAccount["TwitterBearerToken"],
                  "TwitterAccessToken": vTurbFanAccount["TwitterAccessToken"],
                  "TwitterAccessTokenSecret": vTurbFanAccount["TwitterAccessTokenSecret"],
                  "TwitterClientId": vTurbFanAccount["TwitterClientId"],
                  "TwitterClientSecret": vTurbFanAccount["TwitterClientSecret"],
                  "TwitterAppName": vTurbFanAccount["TwitterAppName"],
                  "TwitterAppId": vTurbFanAccount["TwitterAppId"],
                  "TwitterStatus": vTurbFanAccount["TwitterStatus"],
                  "CreationName": vTurbFanAccount["CreationName"],
                  "CreationDateTime": vTurbFanAccount["CreationDateTime"],
                  "UpdateName": Users,
                  "UpdateDateTime": vUpdateDateTimeNew
              }
                  
                  //console.log("SMMADUserManagementTable.SaveRow.CampaignItem:  ",CampaignItem);
    
                  const createSMMADAccountsResponse = await client.graphql({
                    query: updateSMMADAccounts,
                    variables: {
                      input: AccountItem
                    }
                  });     
                  GetSMMADAccounts()  
                  
                  
            } catch (err) { 
              RhyteItLogging(Users, "SMMADUserManagement", "SaveAccount",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }            
            

        async function AccountSelectedAction(pAccount) {
          try {           
                //console.log("SMMADUserManagement.AccountSelectedAction.pAccount: ", pAccount);   
                //console.log("SMMADUserManagement.AccountSelectedAction.vAccountList: ", vAccountList);                
                let vSelectedAccount = vAccountList.filter((vAccountItem) => vAccountItem["Account"] === pAccount)
                //console.log("SMMADUserManagement.AccountSelectedAction.vSelectedAccount[CampaignsActive]: ", vSelectedAccount["CampaignsActive"]);   
                setvCampaignsVisible(vSelectedAccount["CampaignsActive"])
                setvAccountSelected(pAccount)
    
            } catch (err) { 
              RhyteItLogging(Users, "SMMADUserManagement", "AccountSelectedAction",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }      
                
        function TwitterAccount_handleChange(event, data) {
          try {              
           
    
            vTurbFanAccount.TwitterAccount = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setvTurbFanAccount(vTurbFanAccount)   
    
     
    
            } catch (err) { 
              RhyteItLogging(Users, "SMMADUserManagement", "TwitterAccount_handleChange",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }     
         
        function TwitterPassword_handleChange(event, data) {
          try {              
           
    
            vTurbFanAccount.TwitterPassword = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setvTurbFanAccount(vTurbFanAccount)   
    
     
    
            } catch (err) { 
              RhyteItLogging(Users, "SMMADUserManagement", "TwitterPassword_handleChange",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }     
     
        function TwitterConsumerKey_handleChange(event, data) {
          try {              
           
    
            vTurbFanAccount.TwitterConsumerKey = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setvTurbFanAccount(vTurbFanAccount)   
    
     
    
            } catch (err) { 
              RhyteItLogging(Users, "SMMADUserManagement", "TwitterConsumerKey_handleChange",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }  
        
        function TwitterConsumerSecret_handleChange(event, data) {
          try {              
           
    
            vTurbFanAccount.TwitterConsumerSecret = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setvTurbFanAccount(vTurbFanAccount)   
    
     
    
            } catch (err) { 
              RhyteItLogging(Users, "SMMADUserManagement", "TwitterConsumerSecret_handleChange",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }             
    
        
        function TwitterAccessToken_handleChange(event, data) {
          try {              
           
    
            vTurbFanAccount.TwitterAccessToken = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setvTurbFanAccount(vTurbFanAccount)   
    
     
    
            } catch (err) { 
              RhyteItLogging(Users, "SMMADUserManagement", "TwitterAccessToken_handleChange",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }             
            

        function TwitterAccessTokenSecret_handleChange(event, data) {
          try {              
           
    
            vTurbFanAccount.TwitterAccessTokenSecret = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setvTurbFanAccount(vTurbFanAccount)   
    
     
    
            } catch (err) { 
              RhyteItLogging(Users, "SMMADUserManagement", "TwitterAccessTokenSecret_handleChange",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }                
    return ( 
    <m.div
      key="Search"
      initial={{x: "100%"}}
      animate={{x: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
     <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#00CCCC"   GradientStopColor4="#8880"/> 
                                 
                  <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem"
                      className={styles.FlexColumn}
                    >     
                      <Header key={Date.now()} Title="TurbFanBoost" SubTitle="Social Media Marketing Automation" BreadCrumbItems ={vBreadCrumbItems} pageSource="TurbFanBoost"/>  
                      <Fieldset 
                              className={styles.Fieldset}
                              size="small">  
                      <legend
                          className={styles.legend}>Accounts</legend>   
                      <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent="center"
                          wrap="nowrap"
                          gap="3rem"
                        >      
                      <SelectField
                          className={styles.SelectField}
                          value={vAccountSelected}
                          placeholder="Select account"
                          onChange={(e) => AccountSelectedAction(e.target.value)}>
                        {
                          vAccountList !== undefined && vAccountList.map((FromAccount) => (                      
                            ( <option 
                              key={FromAccount.Account} className={styles.SelectFieldOption} value={FromAccount.Account} label={FromAccount.Account}>
                                {FromAccount.Account}
                              </option>)        
                            ))
                        }   
                      </SelectField>                                                                                                                                                                                                                                                                                                                                                                                               
                      </Flex>                                                                                                                                                                                                                                                                                                                                                                                                     
                      </Fieldset>                         
                      {
                          vTurbFanAccount !== undefined && vTurbFanAccount.length > 0 && (        
                          <Table className={styles.Table}>
                            <TableBody>                      
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <Label   
                                    className={styles.Label}
                                    >
                                      Account Status
                                  </Label>
                                </TableCell>
                                <TableCell>
                                  <Text className={styles.TextField} 
                                    name="vTurbFanAccount.SMMADAccountStatus">
                                      {vTurbFanAccount.SMMADAccountStatus}
                                    </Text>
                                </TableCell>
                              </TableRow>                      
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <Label   
                                    className={styles.Label}
                                    >
                                      Group posts into campaigns
                                  </Label>
                                </TableCell>
                                <TableCell>
                                <CheckboxField            
                                      className={styles.CheckboxField}      
                                      name="subscribe-controlled"
                                      checked={vCampaignsActive}
                                      onChange={(e) => setvCampaignsActive(e.target.checked)}
                                    />        
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <Label   
                                    className={styles.Label}
                                    >
                                      Twitter Username
                                  </Label>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vTurbFanAccount.id"                           
                                    defaultValue={vTurbFanAccount.TwitterAccount}
                                    onChange={ (e) => TwitterAccount_handleChange(e, vTurbFanAccount) } />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <Label   
                                    className={styles.Label}
                                    >
                                      Twitter Password
                                  </Label>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vTurbFanAccount.EmailAccount"                           
                                    defaultValue={vTurbFanAccount.TwitterPassword}
                                    onChange={ (e) => TwitterPassword_handleChange(e, vTurbFanAccount) } />
                                </TableCell>
                              </TableRow> 
                            </TableBody>
                          </Table>     
                        )
                    }            

                     {
                          Users === "edwardhawke@rhyteit.com" && vTurbFanAccount !== undefined && (        
                          <Table className={styles.Table}>
                            <TableBody>                                 
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <Label   
                                    className={styles.Label}
                                    >
                                      Internal Account
                                  </Label>
                                </TableCell>
                                <TableCell>
                                  <CheckboxField            
                                      className={styles.CheckboxField}    
                                      name="subscribe-controlled"
                                      checked={vAccountType}
                                      onChange={(e) => setvAccountType(e.target.checked)}
                                    />  
                                </TableCell>
                              </TableRow>     
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <Label   
                                    className={styles.Label}
                                    >
                                      Twitter Consumer Key
                                  </Label>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vTurbFanAccount.TwitterConsumerKey"                           
                                    defaultValue={vTurbFanAccount.TwitterConsumerKey}
                                    onChange={ (e) => TwitterConsumerKey_handleChange(e, vTurbFanAccount) } />
                                </TableCell>
                              </TableRow>                                        
                              <TableRow>
                                <TableCell  className={styles.RowLabelColumn}>         
                                  <Label   
                                    className={styles.Label}
                                    >
                                      Twitter Consumer Secret
                                  </Label>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vTurbFanAccount.TwitterConsumerSecret"                           
                                    defaultValue={vTurbFanAccount.TwitterConsumerSecret}
                                    onChange={ (e) => TwitterConsumerSecret_handleChange(e, vTurbFanAccount) } />
                                </TableCell>
                              </TableRow >
                              <TableRow className={styles.RowLabelColumn}>
                                <TableCell   className={styles.RowLabelColumn}>         
                                  <Label   
                                    className={styles.Label}
                                    >
                                      Twitter Access Token
                                  </Label>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vTurbFanAccount.TwitterAccessToken"                           
                                    defaultValue={vTurbFanAccount.TwitterAccessToken}
                                    onChange={ (e) => TwitterAccessToken_handleChange(e, vTurbFanAccount) } />
                                </TableCell>
                              </TableRow>
                              <TableRow className={styles.RowLabelColumn}>
                                <TableCell   className={styles.RowLabelColumn}>         
                                  <Label   
                                    className={styles.Label}
                                    >
                                      Twitter Access Token Secret
                                  </Label>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vTurbFanAccount.TwitterAccessTokenSecret"                           
                                    defaultValue={vTurbFanAccount.TwitterAccessTokenSecret}
                                    onChange={ (e) => TwitterAccessTokenSecret_handleChange(e, vTurbFanAccount) } />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>     
                        )
                    }                                      
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="3rem"
                      >      
                        <Button className={styles.Button}
                        variation="primary"         
                        onClick={ (e) => SaveAccount("Saved")}
                        >
                              Save
                      </Button> 
                        <Button className={styles.Button}
                        variation="primary"         
                        onClick={ (e) => SaveAccount("Cancel")}
                        >
                              Cancel Account
                      </Button>
                    </Flex>     
                    </Flex> 
    </m.div>                      

    )

  }
 

export default SMMADUserManagement;