
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  Fieldset
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import  { deleteSMMADContent, updateSMMADContent, createSMMADContent}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './ContentRow.module.css';  
  import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';  
  import { SelectedImageContext} from '../../../BusinessLogic/Hooks/SMMADImageContext';
  import SMMADText from "../SMMADText/SMMADText";
  
function ContentRow({
  SMMADContentRow,
  HashTagMap,
  ImageToTextRelationship,
  AccountSelected,
  CampaignSelected,
  ClickedImage,
  onClick
}) {       

  ContentRow.propTypes = {
      SMMADContentRow: PropTypes.object,
      HashTagMap: PropTypes.array,
      ImageToTextRelationship: PropTypes.bool,
      AccountSelected: PropTypes.string,
      CampaignSelected: PropTypes.string,
      onClick: PropTypes.func,
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vContentRow, setvContentRow] = useState(SMMADContentRow)   

    const [vCategory, setvCategory] = useState()   
    const [vActive, setvActive] = useState(SMMADContentRow.Active)   
    
    const [vRowDeleted, setvRowDeleted] = useState(false)     
    
    const [vHashTagMap, setvHashTagMap] = useState(HashTagMap) 
    
    const [vAccountSelected, setvAccountSelected] = useState(AccountSelected)   
    const [vCampaignSelected, setvCampaignSelected] = useState(CampaignSelected)   
    const [vAddText, setvAddText] = useState(false)   
        
    const vSelectedImage = useContext(SelectedImageContext);

    //console.log("ContentRow.useEffect[].vSelectedImage:  ",vSelectedImage) 

    useEffect(() => {

       //console.log("ContentRow.useEffect[].vSelectedImage:  ",vSelectedImage) 
       //console.log("ContentRow.useEffect[].ClickedImage:  ",ClickedImage) 
       //console.log("ContentRow.useEffect[].onClick:  ",onClick) 
       setHashMapToCategory()
      
      },[])  

      useEffect(() => {

       //console.log("ContentRow.useEffect[vActive].vActive:  ",vActive) 
       
       },[vActive])      

       useEffect(() => {

  //        //console.log("ContentRow.useEffect[vAddText].vAddText:  ",vAddText) 
        
        },[vAddText])             
       
              

      function setHashMapToCategory() {
        try {
  
                  
                  try { 
                  let vCategoryList = ""
                    HashTagMap.forEach(function(HashTag) {

                      if (vCategoryList.length > 0) {
                      vCategoryList += vCategoryList + ", #" + HashTag.HashTag
                      } else {
                        vCategoryList = "#" + HashTag.HashTag

                      }
                  });

                  
                    //console.log("ContentRow.setHashMapToCategory.vCategoryList:  ",vCategoryList) 
  
                    setvCategory(vCategoryList)
           
          
                  } catch (err) { 
                    console.error("ContentRow.Category_handleChange.Error:  ".err, err.stack); 
                }                  
   
                //console.log("ReportSelected.DashboardHighlevel.length: ",DashboardHighlevel.length); 
  
        } catch (err) { 
                        console.error('ContentRow.Category_handleChange.ERROR: ', err, err.stack); 
                    }
      }    

      async function ContentClick(event,data) {
        try {


          let vAddTextTmp = (vAddText === true ? false : true)
               
          setvAddText(vAddTextTmp)


        
            } catch (err) { 
  
            ErrorMessageStr = "ContentRow.ContentClick.Error: " + err + " " + err.stack
  
            console.error(ErrorMessageStr); 
            
            setErrorMessage(ErrorMessageStr)         
          }               
        
      }     

      async function ShowText(pShowText) {
        try {

               
          setvAddText(pShowText)
        
            } catch (err) { 
  
            ErrorMessageStr = "ContentRow.ContentClick.Error: " + err + " " + err.stack
  
            console.error(ErrorMessageStr); 
            
            setErrorMessage(ErrorMessageStr)         
          }               
        
      }  

      async function SaveRow(event, data) {
          try {           
            
              setErrorMessage("")

              var vUpdateDateTimeNew = new Date().toISOString()
          
              vContentRow.UpdateDateTime = vUpdateDateTimeNew       
              vContentRow.UpdateName = Users  
              
              vContentRow.Active = (vActive === false ?  true  :  false  )

              let ContentItem = {
                  "id": vContentRow.id,
                  "Account": vContentRow.Account,
                  "AccountType": vContentRow.AccountType,
                  "Campaign": vContentRow.Campaign,
                  "Category": vCategory,
                  "ContentType": vContentRow.ContentType,
                  "URL": vContentRow.URL,
                  "S3Bucket": vContentRow.S3Bucket,
                  "S3Key": vContentRow.S3Key,
                  "PostedCount":  vContentRow.PostedCount,
                  "Active":  vContentRow.Active,
                  "SMMADContentStatus":  "Updated",
                  "CreationName": vContentRow.CreationName,
                  "CreationDateTime": vContentRow.CreationDateTime,
                  "UpdateName": vContentRow.UpdateName,
                  "UpdateDateTime": vContentRow.UpdateDateTime,                  
                  "Text": vContentRow.Text,
                  "Weight": vContentRow.Weight,
                  "LastPostDate": vContentRow.LastPostDate
              }




              // //console.log("ContentRow.SaveRow.ContentItem:  ",ContentItem)                      

                const updateSMMADContentResponse = await client.graphql({
                  query: updateSMMADContent,
                  variables: {
                    input: ContentItem
                  }
                });  
    //            //console.log("SMMADCampaignRow.SaveRow.updateSMMADContentResponse:  ",updateSMMADContentResponse)  

                let vActiveTemp = (vActive  === false ?  true  :  false  )

                //console.log("SMMADCampaignRow.SaveRow.vCampaignStatus:  ",vCampaignStatus)  
                //setvCampaignToShow(updateSMMADCampaignResponse.data.updateSMMADCampaigns)
                setvActive(vActiveTemp)

            } catch (err) { 
              ErrorMessageStr = ("ContentRow.SaveRow.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }      
        
        async function DeleteRow(event,data) {
          try {

                  
                  setErrorMessage("")   

                  const DeleteContentRow = {
                    id: vContentRow.id,
                    _version: vContentRow._version
                  }
                  

                  const vDeleteResponse = await client.graphql({
                    query: deleteSMMADContent,
                    variables: {
                      input: DeleteContentRow
                    }
                  });  

                  ErrorMessageStr = vDeleteResponse
                  setErrorMessage(ErrorMessageStr)   
                  setvRowDeleted(true)   
    
                  
          
              } catch (err) { 

              ErrorMessageStr = "ContentRow.DeleteRow.Error: " + err + " " + err.stack

              console.error(ErrorMessageStr); 
              
              setErrorMessage(ErrorMessageStr)         
            }               
          
        }       



  return (
        
    <div  className={styles.TableRowBackground} 
          key={vContentRow.id}  
          onClick={onClick}>   
        {
            vRowDeleted === false && (        
              <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="nowrap"
                        gap="1rem"
                        className={styles.GridContainer}
                      >           
                      <Image
                          alt=""
                          src={vContentRow.URL}  
                          objectFit="initial"
                          objectPosition="50% 50%"
                          className={vActive === true ?  styles.ImageActive :styles.ImageInActive}
                          //onMouseEnter={(e) => Alert(e)}
                        />    
                      <Flex
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="flex-start"
                          alignContent="flex-start"
                          wrap="nowrap"
                          className={styles.overlay}
                        >     
                          <Button 
                              className={styles.IconButton}   
                              title="Delete picture">                            
                          <Image
                              src="https://rhyteiticons.s3.us-west-2.amazonaws.com/ImageIcons/Garbage.png"
                              className={styles.ImageDeleteIcon}
                              onClick={(e) => DeleteRow(e, vContentRow)}
                              //onMouseEnter={(e) => Alert(e)}
                            />                                          
                          </Button>
                      {
                          vActive === true && (   
                            <Button 
                                className={styles.IconButton}   
                                title="Stop posting content">                           
                            <Image
                                src="https://rhyteiticons.s3.us-west-2.amazonaws.com/ImageIcons/Inactive.png"
                                className={styles.ImageDeleteIcon}
                                onClick={(e) => SaveRow(e, vContentRow)}
                                //onMouseEnter={(e) => Alert(e)}
                              />                                          
                            </Button>
                       )}        
                       {
                           vActive === false && (    
                             <Button 
                                 className={styles.IconButton}   
                                 title="Start posting content">                            
                             <Image
                                 src="https://rhyteiticons.s3.us-west-2.amazonaws.com/ImageIcons/Active.png"
                                 className={styles.ImageDeleteIcon}
                                 onClick={(e) => SaveRow(e, vContentRow)}
                                 //onMouseEnter={(e) => Alert(e)}
                               />                                          
                             </Button>
                        )}                              
                      </Flex>  
                      {ImageToTextRelationship === true && vSelectedImage === vContentRow.id &&
                               (
                                                            
                           <SMMADText  AccountSelected={vAccountSelected}  ContentRow={vContentRow}   ContentId={vContentRow.id}  CampaignSelected={vCampaignSelected}  HashTagMap={vHashTagMap}/>
                      
                      )  }                                                     
              </Flex> 
              
      )
    }         
    </div>
  )
}

export default ContentRow;

