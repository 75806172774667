import {listSBSMNewsQueryTerms} from '../graphql/queries'
import { generateClient } from 'aws-amplify/api';

let debugConsoleOutPut = true

export async function  GetSBSMNewsQueryTermsAd(pUserId) {
    try {

              const client = generateClient();
             // console.log("SBSMQueryTermsManagement.GetSBSMNewsQueryTerms.pUserId: ", pUserId)


              const variables = {
                filter: {
                  UserId: {
                    eq: pUserId
                }
                }
              };
  
              const SBSMNewsQueryTermsData  = await client.graphql({
                  query: listSBSMNewsQueryTerms,
                  variables: variables
                });        
  
              const SBSMNewsQueryTerms = SBSMNewsQueryTermsData.data.listSBSMNewsQueryTerms.items 

              return  {...SBSMNewsQueryTerms}

    } catch (err) { 
                    console.error('GetSBSMNewsQueryTermsAd.GetSBSMNewsQueryTerms.ERROR: ', err, err.stack); 
                }
  } 