/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const GetSecretValue = /* GraphQL */ `
  mutation GetSecretValue($Environment: String, $SecretKey: String) {
    GetSecretValue(Environment: $Environment, SecretKey: $SecretKey)
  }
`;
export const GetS3PresignedURL = /* GraphQL */ `
  mutation GetS3PresignedURL(
    $PurchaseId: String
    $PurchaseEmail: String
    $Product: String
    $Environment: String
  ) {
    GetS3PresignedURL(
      PurchaseId: $PurchaseId
      PurchaseEmail: $PurchaseEmail
      Product: $Product
      Environment: $Environment
    )
  }
`;
export const SendCustomerEmailVerification = /* GraphQL */ `
  mutation SendCustomerEmailVerification($Email: String) {
    SendCustomerEmailVerification(Email: $Email)
  }
`;
export const createApplicants = /* GraphQL */ `
  mutation CreateApplicants(
    $input: CreateApplicantsInput!
    $condition: ModelApplicantsConditionInput
  ) {
    createApplicants(input: $input, condition: $condition) {
      id
      UserId
      UserEmail
      UserLastName
      UserFirstName
      UserDialCode
      UserPhone
      SocialMediaProfile
      ipAddress
      PrivacyAcknowledgement
      Application
      EmailTemplate
      Role
      Status
      Active
      NewsLetterStatus
      NewsLetterConsent
      InOnlyFansProspectingProfile
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateApplicants = /* GraphQL */ `
  mutation UpdateApplicants(
    $input: UpdateApplicantsInput!
    $condition: ModelApplicantsConditionInput
  ) {
    updateApplicants(input: $input, condition: $condition) {
      id
      UserId
      UserEmail
      UserLastName
      UserFirstName
      UserDialCode
      UserPhone
      SocialMediaProfile
      ipAddress
      PrivacyAcknowledgement
      Application
      EmailTemplate
      Role
      Status
      Active
      NewsLetterStatus
      NewsLetterConsent
      InOnlyFansProspectingProfile
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteApplicants = /* GraphQL */ `
  mutation DeleteApplicants(
    $input: DeleteApplicantsInput!
    $condition: ModelApplicantsConditionInput
  ) {
    deleteApplicants(input: $input, condition: $condition) {
      id
      UserId
      UserEmail
      UserLastName
      UserFirstName
      UserDialCode
      UserPhone
      SocialMediaProfile
      ipAddress
      PrivacyAcknowledgement
      Application
      EmailTemplate
      Role
      Status
      Active
      NewsLetterStatus
      NewsLetterConsent
      InOnlyFansProspectingProfile
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createMeetings = /* GraphQL */ `
  mutation CreateMeetings(
    $input: CreateMeetingsInput!
    $condition: ModelMeetingsConditionInput
  ) {
    createMeetings(input: $input, condition: $condition) {
      id
      UserEmail
      UserType
      MeetingType
      MeetingStatus
      MeetingContext
      ipAddress
      MeetingDateTime
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateMeetings = /* GraphQL */ `
  mutation UpdateMeetings(
    $input: UpdateMeetingsInput!
    $condition: ModelMeetingsConditionInput
  ) {
    updateMeetings(input: $input, condition: $condition) {
      id
      UserEmail
      UserType
      MeetingType
      MeetingStatus
      MeetingContext
      ipAddress
      MeetingDateTime
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteMeetings = /* GraphQL */ `
  mutation DeleteMeetings(
    $input: DeleteMeetingsInput!
    $condition: ModelMeetingsConditionInput
  ) {
    deleteMeetings(input: $input, condition: $condition) {
      id
      UserEmail
      UserType
      MeetingType
      MeetingStatus
      MeetingContext
      ipAddress
      MeetingDateTime
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      Description
      URL
      Type
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      Description
      URL
      Type
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      Description
      URL
      Type
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      UserId
      UserLastName
      UserFirstName
      UserEmail
      UserDialCode
      UserPhone
      PrivacyAcknowledgement
      Application
      SocialMediaProfile
      RedditUserID
      RedditPassword
      CreationName
      UpdateName
      Active
      StripeCustomerId
      StripeSubscriptionId
      StripeInvoiceURL
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      UserId
      UserLastName
      UserFirstName
      UserEmail
      UserDialCode
      UserPhone
      PrivacyAcknowledgement
      Application
      SocialMediaProfile
      RedditUserID
      RedditPassword
      CreationName
      UpdateName
      Active
      StripeCustomerId
      StripeSubscriptionId
      StripeInvoiceURL
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      UserId
      UserLastName
      UserFirstName
      UserEmail
      UserDialCode
      UserPhone
      PrivacyAcknowledgement
      Application
      SocialMediaProfile
      RedditUserID
      RedditPassword
      CreationName
      UpdateName
      Active
      StripeCustomerId
      StripeSubscriptionId
      StripeInvoiceURL
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createComments = /* GraphQL */ `
  mutation CreateComments(
    $input: CreateCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    createComments(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      SubRedditId
      Comment
      CommentStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateComments = /* GraphQL */ `
  mutation UpdateComments(
    $input: UpdateCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    updateComments(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      SubRedditId
      Comment
      CommentStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteComments = /* GraphQL */ `
  mutation DeleteComments(
    $input: DeleteCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    deleteComments(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      SubRedditId
      Comment
      CommentStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createEmailAccounts = /* GraphQL */ `
  mutation CreateEmailAccounts(
    $input: CreateEmailAccountsInput!
    $condition: ModelEmailAccountsConditionInput
  ) {
    createEmailAccounts(input: $input, condition: $condition) {
      id
      UserId
      Password
      FirstName
      LastName
      Name
      NameNonSpaced
      BirthMonth
      BirthDay
      BirthYear
      StartDate
      EndDate
      EmailAccountProvider
      EmailAccount
      ChromiumProfile
      GmailAccount
      GmailPassword
      TwitterAccount
      TwitterPassword
      TwitterConsumerKey
      TwitterConsumerSecret
      TwitterBearerToken
      TwitterAccessToken
      TwitterAccessTokenSecret
      TwitterClientId
      TwitterClientSecret
      TwitterAppName
      TwitterAppId
      TwitterStatus
      RedditAccount
      RedditPassword
      OnlyFansAccount
      MediumAccount
      InstagramAccount
      InstagramPassword
      VerificationProvider
      VerificationCode
      OpenAIOrgKey
      OpenAIapiKey
      AccountStatus
      Prompt
      NegativePrompt
      Model
      ProfileImageURL
      MageSeed
      Industry
      ProcessGroup
      RedoImage
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateEmailAccounts = /* GraphQL */ `
  mutation UpdateEmailAccounts(
    $input: UpdateEmailAccountsInput!
    $condition: ModelEmailAccountsConditionInput
  ) {
    updateEmailAccounts(input: $input, condition: $condition) {
      id
      UserId
      Password
      FirstName
      LastName
      Name
      NameNonSpaced
      BirthMonth
      BirthDay
      BirthYear
      StartDate
      EndDate
      EmailAccountProvider
      EmailAccount
      ChromiumProfile
      GmailAccount
      GmailPassword
      TwitterAccount
      TwitterPassword
      TwitterConsumerKey
      TwitterConsumerSecret
      TwitterBearerToken
      TwitterAccessToken
      TwitterAccessTokenSecret
      TwitterClientId
      TwitterClientSecret
      TwitterAppName
      TwitterAppId
      TwitterStatus
      RedditAccount
      RedditPassword
      OnlyFansAccount
      MediumAccount
      InstagramAccount
      InstagramPassword
      VerificationProvider
      VerificationCode
      OpenAIOrgKey
      OpenAIapiKey
      AccountStatus
      Prompt
      NegativePrompt
      Model
      ProfileImageURL
      MageSeed
      Industry
      ProcessGroup
      RedoImage
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteEmailAccounts = /* GraphQL */ `
  mutation DeleteEmailAccounts(
    $input: DeleteEmailAccountsInput!
    $condition: ModelEmailAccountsConditionInput
  ) {
    deleteEmailAccounts(input: $input, condition: $condition) {
      id
      UserId
      Password
      FirstName
      LastName
      Name
      NameNonSpaced
      BirthMonth
      BirthDay
      BirthYear
      StartDate
      EndDate
      EmailAccountProvider
      EmailAccount
      ChromiumProfile
      GmailAccount
      GmailPassword
      TwitterAccount
      TwitterPassword
      TwitterConsumerKey
      TwitterConsumerSecret
      TwitterBearerToken
      TwitterAccessToken
      TwitterAccessTokenSecret
      TwitterClientId
      TwitterClientSecret
      TwitterAppName
      TwitterAppId
      TwitterStatus
      RedditAccount
      RedditPassword
      OnlyFansAccount
      MediumAccount
      InstagramAccount
      InstagramPassword
      VerificationProvider
      VerificationCode
      OpenAIOrgKey
      OpenAIapiKey
      AccountStatus
      Prompt
      NegativePrompt
      Model
      ProfileImageURL
      MageSeed
      Industry
      ProcessGroup
      RedoImage
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createEmailAccountStats = /* GraphQL */ `
  mutation CreateEmailAccountStats(
    $input: CreateEmailAccountStatsInput!
    $condition: ModelEmailAccountStatsConditionInput
  ) {
    createEmailAccountStats(input: $input, condition: $condition) {
      id
      AccountId
      StatDate
      UserId
      TwitterFollowing
      TwitterFollowingScheduled
      TwitterFollowingIncr
      TwitterFollowingPer
      TwitterFollowingIncrScheduled
      TwitterFollowers
      TwitterFollowersScheduled
      TwitterFollowersIncr
      TwitterFollowersPer
      TwitterFollowersIncrScheduled
      TwitterTweets
      TwitterTweetsScheduled
      TwitterTweetsIncr
      TwitterTweetsPer
      TwitterTweetsIncrScheduled
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateEmailAccountStats = /* GraphQL */ `
  mutation UpdateEmailAccountStats(
    $input: UpdateEmailAccountStatsInput!
    $condition: ModelEmailAccountStatsConditionInput
  ) {
    updateEmailAccountStats(input: $input, condition: $condition) {
      id
      AccountId
      StatDate
      UserId
      TwitterFollowing
      TwitterFollowingScheduled
      TwitterFollowingIncr
      TwitterFollowingPer
      TwitterFollowingIncrScheduled
      TwitterFollowers
      TwitterFollowersScheduled
      TwitterFollowersIncr
      TwitterFollowersPer
      TwitterFollowersIncrScheduled
      TwitterTweets
      TwitterTweetsScheduled
      TwitterTweetsIncr
      TwitterTweetsPer
      TwitterTweetsIncrScheduled
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteEmailAccountStats = /* GraphQL */ `
  mutation DeleteEmailAccountStats(
    $input: DeleteEmailAccountStatsInput!
    $condition: ModelEmailAccountStatsConditionInput
  ) {
    deleteEmailAccountStats(input: $input, condition: $condition) {
      id
      AccountId
      StatDate
      UserId
      TwitterFollowing
      TwitterFollowingScheduled
      TwitterFollowingIncr
      TwitterFollowingPer
      TwitterFollowingIncrScheduled
      TwitterFollowers
      TwitterFollowersScheduled
      TwitterFollowersIncr
      TwitterFollowersPer
      TwitterFollowersIncrScheduled
      TwitterTweets
      TwitterTweetsScheduled
      TwitterTweetsIncr
      TwitterTweetsPer
      TwitterTweetsIncrScheduled
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createRhyteItEmails = /* GraphQL */ `
  mutation CreateRhyteItEmails(
    $input: CreateRhyteItEmailsInput!
    $condition: ModelRhyteItEmailsConditionInput
  ) {
    createRhyteItEmails(input: $input, condition: $condition) {
      id
      EmailAccount
      From
      To
      Subject
      S3Bucket
      S3Key
      EmailText
      ReplyText
      EmailHtml
      EmailRawText
      EmailStatus
      ContentType
      Size
      SizeUnits
      ContentTransferEncoding
      InReplyTo
      MessageID
      CharSet
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateRhyteItEmails = /* GraphQL */ `
  mutation UpdateRhyteItEmails(
    $input: UpdateRhyteItEmailsInput!
    $condition: ModelRhyteItEmailsConditionInput
  ) {
    updateRhyteItEmails(input: $input, condition: $condition) {
      id
      EmailAccount
      From
      To
      Subject
      S3Bucket
      S3Key
      EmailText
      ReplyText
      EmailHtml
      EmailRawText
      EmailStatus
      ContentType
      Size
      SizeUnits
      ContentTransferEncoding
      InReplyTo
      MessageID
      CharSet
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteRhyteItEmails = /* GraphQL */ `
  mutation DeleteRhyteItEmails(
    $input: DeleteRhyteItEmailsInput!
    $condition: ModelRhyteItEmailsConditionInput
  ) {
    deleteRhyteItEmails(input: $input, condition: $condition) {
      id
      EmailAccount
      From
      To
      Subject
      S3Bucket
      S3Key
      EmailText
      ReplyText
      EmailHtml
      EmailRawText
      EmailStatus
      ContentType
      Size
      SizeUnits
      ContentTransferEncoding
      InReplyTo
      MessageID
      CharSet
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createRhyteItEmailAttachments = /* GraphQL */ `
  mutation CreateRhyteItEmailAttachments(
    $input: CreateRhyteItEmailAttachmentsInput!
    $condition: ModelRhyteItEmailAttachmentsConditionInput
  ) {
    createRhyteItEmailAttachments(input: $input, condition: $condition) {
      id
      EmailId
      ContentType
      ContentDisposition
      ContentTransferEncoding
      ContentID
      XAttachmentId
      Size
      SizeUnits
      S3Bucket
      S3Key
      Text
      EmailAttachmentStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateRhyteItEmailAttachments = /* GraphQL */ `
  mutation UpdateRhyteItEmailAttachments(
    $input: UpdateRhyteItEmailAttachmentsInput!
    $condition: ModelRhyteItEmailAttachmentsConditionInput
  ) {
    updateRhyteItEmailAttachments(input: $input, condition: $condition) {
      id
      EmailId
      ContentType
      ContentDisposition
      ContentTransferEncoding
      ContentID
      XAttachmentId
      Size
      SizeUnits
      S3Bucket
      S3Key
      Text
      EmailAttachmentStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteRhyteItEmailAttachments = /* GraphQL */ `
  mutation DeleteRhyteItEmailAttachments(
    $input: DeleteRhyteItEmailAttachmentsInput!
    $condition: ModelRhyteItEmailAttachmentsConditionInput
  ) {
    deleteRhyteItEmailAttachments(input: $input, condition: $condition) {
      id
      EmailId
      ContentType
      ContentDisposition
      ContentTransferEncoding
      ContentID
      XAttachmentId
      Size
      SizeUnits
      S3Bucket
      S3Key
      Text
      EmailAttachmentStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createTwitterFollowerCollectionStats = /* GraphQL */ `
  mutation CreateTwitterFollowerCollectionStats(
    $input: CreateTwitterFollowerCollectionStatsInput!
    $condition: ModelTwitterFollowerCollectionStatsConditionInput
  ) {
    createTwitterFollowerCollectionStats(input: $input, condition: $condition) {
      id
      RunDateTime
      RhyteItAccount
      Twitter
      FollowersCollected
      Duration
      IpAddress
      Hostname
      CreationName
      CreationDateTime
      updatedAt
      __typename
    }
  }
`;
export const updateTwitterFollowerCollectionStats = /* GraphQL */ `
  mutation UpdateTwitterFollowerCollectionStats(
    $input: UpdateTwitterFollowerCollectionStatsInput!
    $condition: ModelTwitterFollowerCollectionStatsConditionInput
  ) {
    updateTwitterFollowerCollectionStats(input: $input, condition: $condition) {
      id
      RunDateTime
      RhyteItAccount
      Twitter
      FollowersCollected
      Duration
      IpAddress
      Hostname
      CreationName
      CreationDateTime
      updatedAt
      __typename
    }
  }
`;
export const deleteTwitterFollowerCollectionStats = /* GraphQL */ `
  mutation DeleteTwitterFollowerCollectionStats(
    $input: DeleteTwitterFollowerCollectionStatsInput!
    $condition: ModelTwitterFollowerCollectionStatsConditionInput
  ) {
    deleteTwitterFollowerCollectionStats(input: $input, condition: $condition) {
      id
      RunDateTime
      RhyteItAccount
      Twitter
      FollowersCollected
      Duration
      IpAddress
      Hostname
      CreationName
      CreationDateTime
      updatedAt
      __typename
    }
  }
`;
export const createSocialMediaProcesses = /* GraphQL */ `
  mutation CreateSocialMediaProcesses(
    $input: CreateSocialMediaProcessesInput!
    $condition: ModelSocialMediaProcessesConditionInput
  ) {
    createSocialMediaProcesses(input: $input, condition: $condition) {
      id
      Industry
      ProcessGroup
      Process
      Task
      Step
      Example
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSocialMediaProcesses = /* GraphQL */ `
  mutation UpdateSocialMediaProcesses(
    $input: UpdateSocialMediaProcessesInput!
    $condition: ModelSocialMediaProcessesConditionInput
  ) {
    updateSocialMediaProcesses(input: $input, condition: $condition) {
      id
      Industry
      ProcessGroup
      Process
      Task
      Step
      Example
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSocialMediaProcesses = /* GraphQL */ `
  mutation DeleteSocialMediaProcesses(
    $input: DeleteSocialMediaProcessesInput!
    $condition: ModelSocialMediaProcessesConditionInput
  ) {
    deleteSocialMediaProcesses(input: $input, condition: $condition) {
      id
      Industry
      ProcessGroup
      Process
      Task
      Step
      Example
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createRhyteItTweets = /* GraphQL */ `
  mutation CreateRhyteItTweets(
    $input: CreateRhyteItTweetsInput!
    $condition: ModelRhyteItTweetsConditionInput
  ) {
    createRhyteItTweets(input: $input, condition: $condition) {
      id
      Industry
      ProcessGroup
      Process
      Task
      Step
      Example
      Tweet
      RhyteItAccount
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateRhyteItTweets = /* GraphQL */ `
  mutation UpdateRhyteItTweets(
    $input: UpdateRhyteItTweetsInput!
    $condition: ModelRhyteItTweetsConditionInput
  ) {
    updateRhyteItTweets(input: $input, condition: $condition) {
      id
      Industry
      ProcessGroup
      Process
      Task
      Step
      Example
      Tweet
      RhyteItAccount
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteRhyteItTweets = /* GraphQL */ `
  mutation DeleteRhyteItTweets(
    $input: DeleteRhyteItTweetsInput!
    $condition: ModelRhyteItTweetsConditionInput
  ) {
    deleteRhyteItTweets(input: $input, condition: $condition) {
      id
      Industry
      ProcessGroup
      Process
      Task
      Step
      Example
      Tweet
      RhyteItAccount
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createCommentPosts = /* GraphQL */ `
  mutation CreateCommentPosts(
    $input: CreateCommentPostsInput!
    $condition: ModelCommentPostsConditionInput
  ) {
    createCommentPosts(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      SubRedditId
      PostTitle
      PostAuthor
      PostURL
      PostDateTime
      CommentPostStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateCommentPosts = /* GraphQL */ `
  mutation UpdateCommentPosts(
    $input: UpdateCommentPostsInput!
    $condition: ModelCommentPostsConditionInput
  ) {
    updateCommentPosts(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      SubRedditId
      PostTitle
      PostAuthor
      PostURL
      PostDateTime
      CommentPostStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteCommentPosts = /* GraphQL */ `
  mutation DeleteCommentPosts(
    $input: DeleteCommentPostsInput!
    $condition: ModelCommentPostsConditionInput
  ) {
    deleteCommentPosts(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      SubRedditId
      PostTitle
      PostAuthor
      PostURL
      PostDateTime
      CommentPostStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createQueryTerms = /* GraphQL */ `
  mutation CreateQueryTerms(
    $input: CreateQueryTermsInput!
    $condition: ModelQueryTermsConditionInput
  ) {
    createQueryTerms(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      QueryTermStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateQueryTerms = /* GraphQL */ `
  mutation UpdateQueryTerms(
    $input: UpdateQueryTermsInput!
    $condition: ModelQueryTermsConditionInput
  ) {
    updateQueryTerms(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      QueryTermStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteQueryTerms = /* GraphQL */ `
  mutation DeleteQueryTerms(
    $input: DeleteQueryTermsInput!
    $condition: ModelQueryTermsConditionInput
  ) {
    deleteQueryTerms(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      QueryTermStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSubRedditPosts = /* GraphQL */ `
  mutation CreateSubRedditPosts(
    $input: CreateSubRedditPostsInput!
    $condition: ModelSubRedditPostsConditionInput
  ) {
    createSubRedditPosts(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      PostId
      PostTitle
      PostURL
      NewsURL
      NewsSource
      NewsPubDate
      SubRedditPostStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSubRedditPosts = /* GraphQL */ `
  mutation UpdateSubRedditPosts(
    $input: UpdateSubRedditPostsInput!
    $condition: ModelSubRedditPostsConditionInput
  ) {
    updateSubRedditPosts(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      PostId
      PostTitle
      PostURL
      NewsURL
      NewsSource
      NewsPubDate
      SubRedditPostStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSubRedditPosts = /* GraphQL */ `
  mutation DeleteSubRedditPosts(
    $input: DeleteSubRedditPostsInput!
    $condition: ModelSubRedditPostsConditionInput
  ) {
    deleteSubRedditPosts(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      PostId
      PostTitle
      PostURL
      NewsURL
      NewsSource
      NewsPubDate
      SubRedditPostStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSBSMNewsQueryTerms = /* GraphQL */ `
  mutation CreateSBSMNewsQueryTerms(
    $input: CreateSBSMNewsQueryTermsInput!
    $condition: ModelSBSMNewsQueryTermsConditionInput
  ) {
    createSBSMNewsQueryTerms(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      QueryTermStatus
      LogImageFileLocation
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSBSMNewsQueryTerms = /* GraphQL */ `
  mutation UpdateSBSMNewsQueryTerms(
    $input: UpdateSBSMNewsQueryTermsInput!
    $condition: ModelSBSMNewsQueryTermsConditionInput
  ) {
    updateSBSMNewsQueryTerms(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      QueryTermStatus
      LogImageFileLocation
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSBSMNewsQueryTerms = /* GraphQL */ `
  mutation DeleteSBSMNewsQueryTerms(
    $input: DeleteSBSMNewsQueryTermsInput!
    $condition: ModelSBSMNewsQueryTermsConditionInput
  ) {
    deleteSBSMNewsQueryTerms(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      QueryTermStatus
      LogImageFileLocation
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSBSMNews = /* GraphQL */ `
  mutation CreateSBSMNews(
    $input: CreateSBSMNewsInput!
    $condition: ModelSBSMNewsConditionInput
  ) {
    createSBSMNews(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      InfoType
      Title
      ImageURL
      URL
      Source
      PubDate
      SBSMStatus
      Penalty
      UpVotes
      DownVotes
      AgeInHours
      Score
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSBSMNews = /* GraphQL */ `
  mutation UpdateSBSMNews(
    $input: UpdateSBSMNewsInput!
    $condition: ModelSBSMNewsConditionInput
  ) {
    updateSBSMNews(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      InfoType
      Title
      ImageURL
      URL
      Source
      PubDate
      SBSMStatus
      Penalty
      UpVotes
      DownVotes
      AgeInHours
      Score
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSBSMNews = /* GraphQL */ `
  mutation DeleteSBSMNews(
    $input: DeleteSBSMNewsInput!
    $condition: ModelSBSMNewsConditionInput
  ) {
    deleteSBSMNews(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      InfoType
      Title
      ImageURL
      URL
      Source
      PubDate
      SBSMStatus
      Penalty
      UpVotes
      DownVotes
      AgeInHours
      Score
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSBSMNewsRSS = /* GraphQL */ `
  mutation CreateSBSMNewsRSS(
    $input: CreateSBSMNewsRSSInput!
    $condition: ModelSBSMNewsRSSConditionInput
  ) {
    createSBSMNewsRSS(input: $input, condition: $condition) {
      id
      UserId
      RSSLink
      InfoType
      URL
      Status
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSBSMNewsRSS = /* GraphQL */ `
  mutation UpdateSBSMNewsRSS(
    $input: UpdateSBSMNewsRSSInput!
    $condition: ModelSBSMNewsRSSConditionInput
  ) {
    updateSBSMNewsRSS(input: $input, condition: $condition) {
      id
      UserId
      RSSLink
      InfoType
      URL
      Status
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSBSMNewsRSS = /* GraphQL */ `
  mutation DeleteSBSMNewsRSS(
    $input: DeleteSBSMNewsRSSInput!
    $condition: ModelSBSMNewsRSSConditionInput
  ) {
    deleteSBSMNewsRSS(input: $input, condition: $condition) {
      id
      UserId
      RSSLink
      InfoType
      URL
      Status
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSubReddits = /* GraphQL */ `
  mutation CreateSubReddits(
    $input: CreateSubRedditsInput!
    $condition: ModelSubRedditsConditionInput
  ) {
    createSubReddits(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      SubRedditId
      FlareRequired
      SubRedditStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSubReddits = /* GraphQL */ `
  mutation UpdateSubReddits(
    $input: UpdateSubRedditsInput!
    $condition: ModelSubRedditsConditionInput
  ) {
    updateSubReddits(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      SubRedditId
      FlareRequired
      SubRedditStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSubReddits = /* GraphQL */ `
  mutation DeleteSubReddits(
    $input: DeleteSubRedditsInput!
    $condition: ModelSubRedditsConditionInput
  ) {
    deleteSubReddits(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      SubRedditId
      FlareRequired
      SubRedditStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createAudienceGeoInfo = /* GraphQL */ `
  mutation CreateAudienceGeoInfo(
    $input: CreateAudienceGeoInfoInput!
    $condition: ModelAudienceGeoInfoConditionInput
  ) {
    createAudienceGeoInfo(input: $input, condition: $condition) {
      id
      UserId
      Page
      IPv4
      city
      country_code
      country_name
      latitude
      longitude
      postal
      state
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateAudienceGeoInfo = /* GraphQL */ `
  mutation UpdateAudienceGeoInfo(
    $input: UpdateAudienceGeoInfoInput!
    $condition: ModelAudienceGeoInfoConditionInput
  ) {
    updateAudienceGeoInfo(input: $input, condition: $condition) {
      id
      UserId
      Page
      IPv4
      city
      country_code
      country_name
      latitude
      longitude
      postal
      state
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteAudienceGeoInfo = /* GraphQL */ `
  mutation DeleteAudienceGeoInfo(
    $input: DeleteAudienceGeoInfoInput!
    $condition: ModelAudienceGeoInfoConditionInput
  ) {
    deleteAudienceGeoInfo(input: $input, condition: $condition) {
      id
      UserId
      Page
      IPv4
      city
      country_code
      country_name
      latitude
      longitude
      postal
      state
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createPostAlerts = /* GraphQL */ `
  mutation CreatePostAlerts(
    $input: CreatePostAlertsInput!
    $condition: ModelPostAlertsConditionInput
  ) {
    createPostAlerts(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      PostAlertStatus
      AutoRespond
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updatePostAlerts = /* GraphQL */ `
  mutation UpdatePostAlerts(
    $input: UpdatePostAlertsInput!
    $condition: ModelPostAlertsConditionInput
  ) {
    updatePostAlerts(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      PostAlertStatus
      AutoRespond
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deletePostAlerts = /* GraphQL */ `
  mutation DeletePostAlerts(
    $input: DeletePostAlertsInput!
    $condition: ModelPostAlertsConditionInput
  ) {
    deletePostAlerts(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      PostAlertStatus
      AutoRespond
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createPostAlertsNotifications = /* GraphQL */ `
  mutation CreatePostAlertsNotifications(
    $input: CreatePostAlertsNotificationsInput!
    $condition: ModelPostAlertsNotificationsConditionInput
  ) {
    createPostAlertsNotifications(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      PostTitle
      PostURL
      PostId
      PostAlertId
      Responded
      PostAlertNotificationStatus
      AutoResponse
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updatePostAlertsNotifications = /* GraphQL */ `
  mutation UpdatePostAlertsNotifications(
    $input: UpdatePostAlertsNotificationsInput!
    $condition: ModelPostAlertsNotificationsConditionInput
  ) {
    updatePostAlertsNotifications(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      PostTitle
      PostURL
      PostId
      PostAlertId
      Responded
      PostAlertNotificationStatus
      AutoResponse
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deletePostAlertsNotifications = /* GraphQL */ `
  mutation DeletePostAlertsNotifications(
    $input: DeletePostAlertsNotificationsInput!
    $condition: ModelPostAlertsNotificationsConditionInput
  ) {
    deletePostAlertsNotifications(input: $input, condition: $condition) {
      id
      UserId
      QueryTerm
      SubReddit
      PostTitle
      PostURL
      PostId
      PostAlertId
      Responded
      PostAlertNotificationStatus
      AutoResponse
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createPostSchedule = /* GraphQL */ `
  mutation CreatePostSchedule(
    $input: CreatePostScheduleInput!
    $condition: ModelPostScheduleConditionInput
  ) {
    createPostSchedule(input: $input, condition: $condition) {
      id
      UserId
      SubReddit
      PostTitle
      PostComment
      PostPhotoURL
      PostURL
      PostId
      PostDateTime
      PostScheduleStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updatePostSchedule = /* GraphQL */ `
  mutation UpdatePostSchedule(
    $input: UpdatePostScheduleInput!
    $condition: ModelPostScheduleConditionInput
  ) {
    updatePostSchedule(input: $input, condition: $condition) {
      id
      UserId
      SubReddit
      PostTitle
      PostComment
      PostPhotoURL
      PostURL
      PostId
      PostDateTime
      PostScheduleStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deletePostSchedule = /* GraphQL */ `
  mutation DeletePostSchedule(
    $input: DeletePostScheduleInput!
    $condition: ModelPostScheduleConditionInput
  ) {
    deletePostSchedule(input: $input, condition: $condition) {
      id
      UserId
      SubReddit
      PostTitle
      PostComment
      PostPhotoURL
      PostURL
      PostId
      PostDateTime
      PostScheduleStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createPhotoGeneratorModels = /* GraphQL */ `
  mutation CreatePhotoGeneratorModels(
    $input: CreatePhotoGeneratorModelsInput!
    $condition: ModelPhotoGeneratorModelsConditionInput
  ) {
    createPhotoGeneratorModels(input: $input, condition: $condition) {
      id
      UserId
      ModelName
      PhotoGeneratorModelStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updatePhotoGeneratorModels = /* GraphQL */ `
  mutation UpdatePhotoGeneratorModels(
    $input: UpdatePhotoGeneratorModelsInput!
    $condition: ModelPhotoGeneratorModelsConditionInput
  ) {
    updatePhotoGeneratorModels(input: $input, condition: $condition) {
      id
      UserId
      ModelName
      PhotoGeneratorModelStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deletePhotoGeneratorModels = /* GraphQL */ `
  mutation DeletePhotoGeneratorModels(
    $input: DeletePhotoGeneratorModelsInput!
    $condition: ModelPhotoGeneratorModelsConditionInput
  ) {
    deletePhotoGeneratorModels(input: $input, condition: $condition) {
      id
      UserId
      ModelName
      PhotoGeneratorModelStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createModelTrainingPhotos = /* GraphQL */ `
  mutation CreateModelTrainingPhotos(
    $input: CreateModelTrainingPhotosInput!
    $condition: ModelModelTrainingPhotosConditionInput
  ) {
    createModelTrainingPhotos(input: $input, condition: $condition) {
      id
      UserId
      ModelName
      TrainingPhotoURL
      ModelTrainingPhotoStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateModelTrainingPhotos = /* GraphQL */ `
  mutation UpdateModelTrainingPhotos(
    $input: UpdateModelTrainingPhotosInput!
    $condition: ModelModelTrainingPhotosConditionInput
  ) {
    updateModelTrainingPhotos(input: $input, condition: $condition) {
      id
      UserId
      ModelName
      TrainingPhotoURL
      ModelTrainingPhotoStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteModelTrainingPhotos = /* GraphQL */ `
  mutation DeleteModelTrainingPhotos(
    $input: DeleteModelTrainingPhotosInput!
    $condition: ModelModelTrainingPhotosConditionInput
  ) {
    deleteModelTrainingPhotos(input: $input, condition: $condition) {
      id
      UserId
      ModelName
      TrainingPhotoURL
      ModelTrainingPhotoStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createOutPutPhotos = /* GraphQL */ `
  mutation CreateOutPutPhotos(
    $input: CreateOutPutPhotosInput!
    $condition: ModelOutPutPhotosConditionInput
  ) {
    createOutPutPhotos(input: $input, condition: $condition) {
      id
      UserId
      ModelName
      PhotoURL
      OutPutPhotoStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateOutPutPhotos = /* GraphQL */ `
  mutation UpdateOutPutPhotos(
    $input: UpdateOutPutPhotosInput!
    $condition: ModelOutPutPhotosConditionInput
  ) {
    updateOutPutPhotos(input: $input, condition: $condition) {
      id
      UserId
      ModelName
      PhotoURL
      OutPutPhotoStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteOutPutPhotos = /* GraphQL */ `
  mutation DeleteOutPutPhotos(
    $input: DeleteOutPutPhotosInput!
    $condition: ModelOutPutPhotosConditionInput
  ) {
    deleteOutPutPhotos(input: $input, condition: $condition) {
      id
      UserId
      ModelName
      PhotoURL
      OutPutPhotoStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createOnlyFansProspectingProfiles = /* GraphQL */ `
  mutation CreateOnlyFansProspectingProfiles(
    $input: CreateOnlyFansProspectingProfilesInput!
    $condition: ModelOnlyFansProspectingProfilesConditionInput
  ) {
    createOnlyFansProspectingProfiles(input: $input, condition: $condition) {
      id
      UserId
      OnlyFansProfileURL
      OnlyFansProfilePictureURL
      OnlyFansPictureStatus
      OnlyFansChatURL
      OnlyFansId
      OnlyFansName
      OFSP_Photos
      OFSP_Videos
      OFSP_Likes
      OFSP_Streams
      OFSP_Fans
      PaidFree
      Description
      Location
      Subscribed
      AdSent
      Responded
      RespondedDate
      Demo
      DemoDate
      CollectionAccount
      RhyteItCustomer
      RhyteItCustomerDate
      RhyteItDAOMember
      RhyteItDAOMemberDate
      RhyteItUserId
      LinkTree
      Twitter
      Telegram
      Fansly
      Instagram
      Reddit
      Email
      Beacons
      TikTok
      AllMyLinks
      UpdateCounter
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateOnlyFansProspectingProfiles = /* GraphQL */ `
  mutation UpdateOnlyFansProspectingProfiles(
    $input: UpdateOnlyFansProspectingProfilesInput!
    $condition: ModelOnlyFansProspectingProfilesConditionInput
  ) {
    updateOnlyFansProspectingProfiles(input: $input, condition: $condition) {
      id
      UserId
      OnlyFansProfileURL
      OnlyFansProfilePictureURL
      OnlyFansPictureStatus
      OnlyFansChatURL
      OnlyFansId
      OnlyFansName
      OFSP_Photos
      OFSP_Videos
      OFSP_Likes
      OFSP_Streams
      OFSP_Fans
      PaidFree
      Description
      Location
      Subscribed
      AdSent
      Responded
      RespondedDate
      Demo
      DemoDate
      CollectionAccount
      RhyteItCustomer
      RhyteItCustomerDate
      RhyteItDAOMember
      RhyteItDAOMemberDate
      RhyteItUserId
      LinkTree
      Twitter
      Telegram
      Fansly
      Instagram
      Reddit
      Email
      Beacons
      TikTok
      AllMyLinks
      UpdateCounter
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteOnlyFansProspectingProfiles = /* GraphQL */ `
  mutation DeleteOnlyFansProspectingProfiles(
    $input: DeleteOnlyFansProspectingProfilesInput!
    $condition: ModelOnlyFansProspectingProfilesConditionInput
  ) {
    deleteOnlyFansProspectingProfiles(input: $input, condition: $condition) {
      id
      UserId
      OnlyFansProfileURL
      OnlyFansProfilePictureURL
      OnlyFansPictureStatus
      OnlyFansChatURL
      OnlyFansId
      OnlyFansName
      OFSP_Photos
      OFSP_Videos
      OFSP_Likes
      OFSP_Streams
      OFSP_Fans
      PaidFree
      Description
      Location
      Subscribed
      AdSent
      Responded
      RespondedDate
      Demo
      DemoDate
      CollectionAccount
      RhyteItCustomer
      RhyteItCustomerDate
      RhyteItDAOMember
      RhyteItDAOMemberDate
      RhyteItUserId
      LinkTree
      Twitter
      Telegram
      Fansly
      Instagram
      Reddit
      Email
      Beacons
      TikTok
      AllMyLinks
      UpdateCounter
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSocialMediaAds = /* GraphQL */ `
  mutation CreateSocialMediaAds(
    $input: CreateSocialMediaAdsInput!
    $condition: ModelSocialMediaAdsConditionInput
  ) {
    createSocialMediaAds(input: $input, condition: $condition) {
      id
      SMAdType
      SMPlatform
      SMId
      SBSMPlatform
      SBSMId
      AdFile
      SMPostURL
      RIAccount
      RISMAccount
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSocialMediaAds = /* GraphQL */ `
  mutation UpdateSocialMediaAds(
    $input: UpdateSocialMediaAdsInput!
    $condition: ModelSocialMediaAdsConditionInput
  ) {
    updateSocialMediaAds(input: $input, condition: $condition) {
      id
      SMAdType
      SMPlatform
      SMId
      SBSMPlatform
      SBSMId
      AdFile
      SMPostURL
      RIAccount
      RISMAccount
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSocialMediaAds = /* GraphQL */ `
  mutation DeleteSocialMediaAds(
    $input: DeleteSocialMediaAdsInput!
    $condition: ModelSocialMediaAdsConditionInput
  ) {
    deleteSocialMediaAds(input: $input, condition: $condition) {
      id
      SMAdType
      SMPlatform
      SMId
      SBSMPlatform
      SBSMId
      AdFile
      SMPostURL
      RIAccount
      RISMAccount
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSalesPipelineMetrics = /* GraphQL */ `
  mutation CreateSalesPipelineMetrics(
    $input: CreateSalesPipelineMetricsInput!
    $condition: ModelSalesPipelineMetricsConditionInput
  ) {
    createSalesPipelineMetrics(input: $input, condition: $condition) {
      id
      UserId
      ReportDate
      SubscribedCount
      AdvertsSent
      RespondedCount
      DemoCount
      CustomerCount
      DAOMemberCount
      RhyteItAccounts
      RhyteItChromeProfiles
      RhyteItEmailAccounts
      RhyteItTwitterAccounts
      RhyteItInstagramAccounts
      RhyteItOnlyFansAccounts
      RhyteItMediumAccounts
      RhyteItRedditAccounts
      OFAccounts
      OFLinkTreeAccounts
      OFEmailAccounts
      OFTwitterAccounts
      OFTelegramAccounts
      OFInstagramAccounts
      OFFanslyAccounts
      OFRedditAccounts
      RhyteItPageSiteVisits
      ReportData
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSalesPipelineMetrics = /* GraphQL */ `
  mutation UpdateSalesPipelineMetrics(
    $input: UpdateSalesPipelineMetricsInput!
    $condition: ModelSalesPipelineMetricsConditionInput
  ) {
    updateSalesPipelineMetrics(input: $input, condition: $condition) {
      id
      UserId
      ReportDate
      SubscribedCount
      AdvertsSent
      RespondedCount
      DemoCount
      CustomerCount
      DAOMemberCount
      RhyteItAccounts
      RhyteItChromeProfiles
      RhyteItEmailAccounts
      RhyteItTwitterAccounts
      RhyteItInstagramAccounts
      RhyteItOnlyFansAccounts
      RhyteItMediumAccounts
      RhyteItRedditAccounts
      OFAccounts
      OFLinkTreeAccounts
      OFEmailAccounts
      OFTwitterAccounts
      OFTelegramAccounts
      OFInstagramAccounts
      OFFanslyAccounts
      OFRedditAccounts
      RhyteItPageSiteVisits
      ReportData
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSalesPipelineMetrics = /* GraphQL */ `
  mutation DeleteSalesPipelineMetrics(
    $input: DeleteSalesPipelineMetricsInput!
    $condition: ModelSalesPipelineMetricsConditionInput
  ) {
    deleteSalesPipelineMetrics(input: $input, condition: $condition) {
      id
      UserId
      ReportDate
      SubscribedCount
      AdvertsSent
      RespondedCount
      DemoCount
      CustomerCount
      DAOMemberCount
      RhyteItAccounts
      RhyteItChromeProfiles
      RhyteItEmailAccounts
      RhyteItTwitterAccounts
      RhyteItInstagramAccounts
      RhyteItOnlyFansAccounts
      RhyteItMediumAccounts
      RhyteItRedditAccounts
      OFAccounts
      OFLinkTreeAccounts
      OFEmailAccounts
      OFTwitterAccounts
      OFTelegramAccounts
      OFInstagramAccounts
      OFFanslyAccounts
      OFRedditAccounts
      RhyteItPageSiteVisits
      ReportData
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSBSMRedditors = /* GraphQL */ `
  mutation CreateSBSMRedditors(
    $input: CreateSBSMRedditorsInput!
    $condition: ModelSBSMRedditorsConditionInput
  ) {
    createSBSMRedditors(input: $input, condition: $condition) {
      id
      UserId
      redditor
      OnlyFansName
      FanslyName
      SheerName
      comment_karma
      link_karma
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSBSMRedditors = /* GraphQL */ `
  mutation UpdateSBSMRedditors(
    $input: UpdateSBSMRedditorsInput!
    $condition: ModelSBSMRedditorsConditionInput
  ) {
    updateSBSMRedditors(input: $input, condition: $condition) {
      id
      UserId
      redditor
      OnlyFansName
      FanslyName
      SheerName
      comment_karma
      link_karma
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSBSMRedditors = /* GraphQL */ `
  mutation DeleteSBSMRedditors(
    $input: DeleteSBSMRedditorsInput!
    $condition: ModelSBSMRedditorsConditionInput
  ) {
    deleteSBSMRedditors(input: $input, condition: $condition) {
      id
      UserId
      redditor
      OnlyFansName
      FanslyName
      SheerName
      comment_karma
      link_karma
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSBSMSubReddits = /* GraphQL */ `
  mutation CreateSBSMSubReddits(
    $input: CreateSBSMSubRedditsInput!
    $condition: ModelSBSMSubRedditsConditionInput
  ) {
    createSBSMSubReddits(input: $input, condition: $condition) {
      id
      UserId
      subreddit
      over18
      Description
      subscribers
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSBSMSubReddits = /* GraphQL */ `
  mutation UpdateSBSMSubReddits(
    $input: UpdateSBSMSubRedditsInput!
    $condition: ModelSBSMSubRedditsConditionInput
  ) {
    updateSBSMSubReddits(input: $input, condition: $condition) {
      id
      UserId
      subreddit
      over18
      Description
      subscribers
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSBSMSubReddits = /* GraphQL */ `
  mutation DeleteSBSMSubReddits(
    $input: DeleteSBSMSubRedditsInput!
    $condition: ModelSBSMSubRedditsConditionInput
  ) {
    deleteSBSMSubReddits(input: $input, condition: $condition) {
      id
      UserId
      subreddit
      over18
      Description
      subscribers
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSBSMRedditorSubreddits = /* GraphQL */ `
  mutation CreateSBSMRedditorSubreddits(
    $input: CreateSBSMRedditorSubredditsInput!
    $condition: ModelSBSMRedditorSubredditsConditionInput
  ) {
    createSBSMRedditorSubreddits(input: $input, condition: $condition) {
      id
      UserId
      redditor
      subreddit
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSBSMRedditorSubreddits = /* GraphQL */ `
  mutation UpdateSBSMRedditorSubreddits(
    $input: UpdateSBSMRedditorSubredditsInput!
    $condition: ModelSBSMRedditorSubredditsConditionInput
  ) {
    updateSBSMRedditorSubreddits(input: $input, condition: $condition) {
      id
      UserId
      redditor
      subreddit
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSBSMRedditorSubreddits = /* GraphQL */ `
  mutation DeleteSBSMRedditorSubreddits(
    $input: DeleteSBSMRedditorSubredditsInput!
    $condition: ModelSBSMRedditorSubredditsConditionInput
  ) {
    deleteSBSMRedditorSubreddits(input: $input, condition: $condition) {
      id
      UserId
      redditor
      subreddit
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createProducts = /* GraphQL */ `
  mutation CreateProducts(
    $input: CreateProductsInput!
    $condition: ModelProductsConditionInput
  ) {
    createProducts(input: $input, condition: $condition) {
      id
      Product
      Description
      PaymentMode
      ProductURLParam
      PriceId
      Price
      SuccessURL
      CancelURL
      ProductImage
      Environment
      ProductType
      DeliveryMethod
      FileURL
      SocialMedia
      Likes
      Reposts
      Follow
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateProducts = /* GraphQL */ `
  mutation UpdateProducts(
    $input: UpdateProductsInput!
    $condition: ModelProductsConditionInput
  ) {
    updateProducts(input: $input, condition: $condition) {
      id
      Product
      Description
      PaymentMode
      ProductURLParam
      PriceId
      Price
      SuccessURL
      CancelURL
      ProductImage
      Environment
      ProductType
      DeliveryMethod
      FileURL
      SocialMedia
      Likes
      Reposts
      Follow
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteProducts = /* GraphQL */ `
  mutation DeleteProducts(
    $input: DeleteProductsInput!
    $condition: ModelProductsConditionInput
  ) {
    deleteProducts(input: $input, condition: $condition) {
      id
      Product
      Description
      PaymentMode
      ProductURLParam
      PriceId
      Price
      SuccessURL
      CancelURL
      ProductImage
      Environment
      ProductType
      DeliveryMethod
      FileURL
      SocialMedia
      Likes
      Reposts
      Follow
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createPrivateKeys = /* GraphQL */ `
  mutation CreatePrivateKeys(
    $input: CreatePrivateKeysInput!
    $condition: ModelPrivateKeysConditionInput
  ) {
    createPrivateKeys(input: $input, condition: $condition) {
      id
      UserId
      Component
      Key
      Value
      URL
      Environment
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updatePrivateKeys = /* GraphQL */ `
  mutation UpdatePrivateKeys(
    $input: UpdatePrivateKeysInput!
    $condition: ModelPrivateKeysConditionInput
  ) {
    updatePrivateKeys(input: $input, condition: $condition) {
      id
      UserId
      Component
      Key
      Value
      URL
      Environment
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deletePrivateKeys = /* GraphQL */ `
  mutation DeletePrivateKeys(
    $input: DeletePrivateKeysInput!
    $condition: ModelPrivateKeysConditionInput
  ) {
    deletePrivateKeys(input: $input, condition: $condition) {
      id
      UserId
      Component
      Key
      Value
      URL
      Environment
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createPurchase = /* GraphQL */ `
  mutation CreatePurchase(
    $input: CreatePurchaseInput!
    $condition: ModelPurchaseConditionInput
  ) {
    createPurchase(input: $input, condition: $condition) {
      id
      StripePaymentIntent
      StripePaymentMethod
      StripeCustomerId
      StripeCustomerEmail
      StripeCustomerName
      StripeCheckOutSessionId
      StripeInvoiceId
      StripeSubscriptionId
      StripeSubscriptionItemId
      StripeProductId
      StripePriceId
      StripeStatus
      RequestId
      idempotency_key
      ReceiptURL
      Last4
      PostalCode
      Product
      Environment
      EmailVerified
      PurchaseStatus
      FileURL
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updatePurchase = /* GraphQL */ `
  mutation UpdatePurchase(
    $input: UpdatePurchaseInput!
    $condition: ModelPurchaseConditionInput
  ) {
    updatePurchase(input: $input, condition: $condition) {
      id
      StripePaymentIntent
      StripePaymentMethod
      StripeCustomerId
      StripeCustomerEmail
      StripeCustomerName
      StripeCheckOutSessionId
      StripeInvoiceId
      StripeSubscriptionId
      StripeSubscriptionItemId
      StripeProductId
      StripePriceId
      StripeStatus
      RequestId
      idempotency_key
      ReceiptURL
      Last4
      PostalCode
      Product
      Environment
      EmailVerified
      PurchaseStatus
      FileURL
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deletePurchase = /* GraphQL */ `
  mutation DeletePurchase(
    $input: DeletePurchaseInput!
    $condition: ModelPurchaseConditionInput
  ) {
    deletePurchase(input: $input, condition: $condition) {
      id
      StripePaymentIntent
      StripePaymentMethod
      StripeCustomerId
      StripeCustomerEmail
      StripeCustomerName
      StripeCheckOutSessionId
      StripeInvoiceId
      StripeSubscriptionId
      StripeSubscriptionItemId
      StripeProductId
      StripePriceId
      StripeStatus
      RequestId
      idempotency_key
      ReceiptURL
      Last4
      PostalCode
      Product
      Environment
      EmailVerified
      PurchaseStatus
      FileURL
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSocialMediaEngagementPurchases = /* GraphQL */ `
  mutation CreateSocialMediaEngagementPurchases(
    $input: CreateSocialMediaEngagementPurchasesInput!
    $condition: ModelSocialMediaEngagementPurchasesConditionInput
  ) {
    createSocialMediaEngagementPurchases(input: $input, condition: $condition) {
      id
      Email
      StripePaymentIntent
      SocialMedia
      SocialMediaAccount
      LikesPurchased
      LikesAchieved
      Random
      PostURL
      Environment
      PurchaseStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSocialMediaEngagementPurchases = /* GraphQL */ `
  mutation UpdateSocialMediaEngagementPurchases(
    $input: UpdateSocialMediaEngagementPurchasesInput!
    $condition: ModelSocialMediaEngagementPurchasesConditionInput
  ) {
    updateSocialMediaEngagementPurchases(input: $input, condition: $condition) {
      id
      Email
      StripePaymentIntent
      SocialMedia
      SocialMediaAccount
      LikesPurchased
      LikesAchieved
      Random
      PostURL
      Environment
      PurchaseStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSocialMediaEngagementPurchases = /* GraphQL */ `
  mutation DeleteSocialMediaEngagementPurchases(
    $input: DeleteSocialMediaEngagementPurchasesInput!
    $condition: ModelSocialMediaEngagementPurchasesConditionInput
  ) {
    deleteSocialMediaEngagementPurchases(input: $input, condition: $condition) {
      id
      Email
      StripePaymentIntent
      SocialMedia
      SocialMediaAccount
      LikesPurchased
      LikesAchieved
      Random
      PostURL
      Environment
      PurchaseStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createCustomerComments = /* GraphQL */ `
  mutation CreateCustomerComments(
    $input: CreateCustomerCommentsInput!
    $condition: ModelCustomerCommentsConditionInput
  ) {
    createCustomerComments(input: $input, condition: $condition) {
      id
      UserEmail
      IpAddress
      Rating
      CommentType
      Comment
      EmailTemplate
      Status
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateCustomerComments = /* GraphQL */ `
  mutation UpdateCustomerComments(
    $input: UpdateCustomerCommentsInput!
    $condition: ModelCustomerCommentsConditionInput
  ) {
    updateCustomerComments(input: $input, condition: $condition) {
      id
      UserEmail
      IpAddress
      Rating
      CommentType
      Comment
      EmailTemplate
      Status
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteCustomerComments = /* GraphQL */ `
  mutation DeleteCustomerComments(
    $input: DeleteCustomerCommentsInput!
    $condition: ModelCustomerCommentsConditionInput
  ) {
    deleteCustomerComments(input: $input, condition: $condition) {
      id
      UserEmail
      IpAddress
      Rating
      CommentType
      Comment
      EmailTemplate
      Status
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createProductReviews = /* GraphQL */ `
  mutation CreateProductReviews(
    $input: CreateProductReviewsInput!
    $condition: ModelProductReviewsConditionInput
  ) {
    createProductReviews(input: $input, condition: $condition) {
      id
      Product
      Review
      Rating
      Status
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateProductReviews = /* GraphQL */ `
  mutation UpdateProductReviews(
    $input: UpdateProductReviewsInput!
    $condition: ModelProductReviewsConditionInput
  ) {
    updateProductReviews(input: $input, condition: $condition) {
      id
      Product
      Review
      Rating
      Status
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteProductReviews = /* GraphQL */ `
  mutation DeleteProductReviews(
    $input: DeleteProductReviewsInput!
    $condition: ModelProductReviewsConditionInput
  ) {
    deleteProductReviews(input: $input, condition: $condition) {
      id
      Product
      Review
      Rating
      Status
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSMMADContent = /* GraphQL */ `
  mutation CreateSMMADContent(
    $input: CreateSMMADContentInput!
    $condition: ModelSMMADContentConditionInput
  ) {
    createSMMADContent(input: $input, condition: $condition) {
      id
      Account
      AccountType
      Campaign
      Category
      ContentType
      Text
      URL
      S3Bucket
      S3Key
      PostedCount
      Weight
      ZScore
      LastPostDate
      SMMADContentStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSMMADContent = /* GraphQL */ `
  mutation UpdateSMMADContent(
    $input: UpdateSMMADContentInput!
    $condition: ModelSMMADContentConditionInput
  ) {
    updateSMMADContent(input: $input, condition: $condition) {
      id
      Account
      AccountType
      Campaign
      Category
      ContentType
      Text
      URL
      S3Bucket
      S3Key
      PostedCount
      Weight
      ZScore
      LastPostDate
      SMMADContentStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSMMADContent = /* GraphQL */ `
  mutation DeleteSMMADContent(
    $input: DeleteSMMADContentInput!
    $condition: ModelSMMADContentConditionInput
  ) {
    deleteSMMADContent(input: $input, condition: $condition) {
      id
      Account
      AccountType
      Campaign
      Category
      ContentType
      Text
      URL
      S3Bucket
      S3Key
      PostedCount
      Weight
      ZScore
      LastPostDate
      SMMADContentStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSMMADText = /* GraphQL */ `
  mutation CreateSMMADText(
    $input: CreateSMMADTextInput!
    $condition: ModelSMMADTextConditionInput
  ) {
    createSMMADText(input: $input, condition: $condition) {
      id
      Account
      AccountType
      ContentId
      Campaign
      Category
      Text
      PostedCount
      Weight
      ZScore
      LastPostDate
      SMMADTextStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSMMADText = /* GraphQL */ `
  mutation UpdateSMMADText(
    $input: UpdateSMMADTextInput!
    $condition: ModelSMMADTextConditionInput
  ) {
    updateSMMADText(input: $input, condition: $condition) {
      id
      Account
      AccountType
      ContentId
      Campaign
      Category
      Text
      PostedCount
      Weight
      ZScore
      LastPostDate
      SMMADTextStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSMMADText = /* GraphQL */ `
  mutation DeleteSMMADText(
    $input: DeleteSMMADTextInput!
    $condition: ModelSMMADTextConditionInput
  ) {
    deleteSMMADText(input: $input, condition: $condition) {
      id
      Account
      AccountType
      ContentId
      Campaign
      Category
      Text
      PostedCount
      Weight
      ZScore
      LastPostDate
      SMMADTextStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSMMADContentMetrics = /* GraphQL */ `
  mutation CreateSMMADContentMetrics(
    $input: CreateSMMADContentMetricsInput!
    $condition: ModelSMMADContentMetricsConditionInput
  ) {
    createSMMADContentMetrics(input: $input, condition: $condition) {
      id
      Account
      AccountType
      Campaign
      Category
      SMMADTextId
      SMMADContentId
      PostDateTime
      PostDay
      PostHour24
      Platform
      SMMADContentMetricsStatus
      SubscribersGrowth
      SubscribersTotal
      PostURL
      PostLikes
      PostComments
      PostViews
      Reposts
      Weight
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSMMADContentMetrics = /* GraphQL */ `
  mutation UpdateSMMADContentMetrics(
    $input: UpdateSMMADContentMetricsInput!
    $condition: ModelSMMADContentMetricsConditionInput
  ) {
    updateSMMADContentMetrics(input: $input, condition: $condition) {
      id
      Account
      AccountType
      Campaign
      Category
      SMMADTextId
      SMMADContentId
      PostDateTime
      PostDay
      PostHour24
      Platform
      SMMADContentMetricsStatus
      SubscribersGrowth
      SubscribersTotal
      PostURL
      PostLikes
      PostComments
      PostViews
      Reposts
      Weight
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSMMADContentMetrics = /* GraphQL */ `
  mutation DeleteSMMADContentMetrics(
    $input: DeleteSMMADContentMetricsInput!
    $condition: ModelSMMADContentMetricsConditionInput
  ) {
    deleteSMMADContentMetrics(input: $input, condition: $condition) {
      id
      Account
      AccountType
      Campaign
      Category
      SMMADTextId
      SMMADContentId
      PostDateTime
      PostDay
      PostHour24
      Platform
      SMMADContentMetricsStatus
      SubscribersGrowth
      SubscribersTotal
      PostURL
      PostLikes
      PostComments
      PostViews
      Reposts
      Weight
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSMMADAccounts = /* GraphQL */ `
  mutation CreateSMMADAccounts(
    $input: CreateSMMADAccountsInput!
    $condition: ModelSMMADAccountsConditionInput
  ) {
    createSMMADAccounts(input: $input, condition: $condition) {
      id
      Account
      AccountType
      SMMADAccountStatus
      CampaignsActive
      PostedCount
      TwitterLastPostDate
      TwitterPostingFrequencyHours
      Weight
      ZScore
      PurchaseId
      ProductId
      TwitterAccount
      TwitterPassword
      TwitterConsumerKey
      TwitterConsumerSecret
      TwitterBearerToken
      TwitterAccessToken
      TwitterAccessTokenSecret
      TwitterClientId
      TwitterClientSecret
      TwitterAppName
      TwitterAppId
      TwitterStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSMMADAccounts = /* GraphQL */ `
  mutation UpdateSMMADAccounts(
    $input: UpdateSMMADAccountsInput!
    $condition: ModelSMMADAccountsConditionInput
  ) {
    updateSMMADAccounts(input: $input, condition: $condition) {
      id
      Account
      AccountType
      SMMADAccountStatus
      CampaignsActive
      PostedCount
      TwitterLastPostDate
      TwitterPostingFrequencyHours
      Weight
      ZScore
      PurchaseId
      ProductId
      TwitterAccount
      TwitterPassword
      TwitterConsumerKey
      TwitterConsumerSecret
      TwitterBearerToken
      TwitterAccessToken
      TwitterAccessTokenSecret
      TwitterClientId
      TwitterClientSecret
      TwitterAppName
      TwitterAppId
      TwitterStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSMMADAccounts = /* GraphQL */ `
  mutation DeleteSMMADAccounts(
    $input: DeleteSMMADAccountsInput!
    $condition: ModelSMMADAccountsConditionInput
  ) {
    deleteSMMADAccounts(input: $input, condition: $condition) {
      id
      Account
      AccountType
      SMMADAccountStatus
      CampaignsActive
      PostedCount
      TwitterLastPostDate
      TwitterPostingFrequencyHours
      Weight
      ZScore
      PurchaseId
      ProductId
      TwitterAccount
      TwitterPassword
      TwitterConsumerKey
      TwitterConsumerSecret
      TwitterBearerToken
      TwitterAccessToken
      TwitterAccessTokenSecret
      TwitterClientId
      TwitterClientSecret
      TwitterAppName
      TwitterAppId
      TwitterStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSMMADAccountStats = /* GraphQL */ `
  mutation CreateSMMADAccountStats(
    $input: CreateSMMADAccountStatsInput!
    $condition: ModelSMMADAccountStatsConditionInput
  ) {
    createSMMADAccountStats(input: $input, condition: $condition) {
      id
      Account
      AccountType
      StatDate
      TwitterFollowing
      TwitterFollowingIncr
      TwitterFollowingPer
      TwitterFollowers
      TwitterFollowersIncr
      TwitterFollowersPer
      TwitterTweets
      TwitterTweetsIncr
      TwitterTweetsPer
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSMMADAccountStats = /* GraphQL */ `
  mutation UpdateSMMADAccountStats(
    $input: UpdateSMMADAccountStatsInput!
    $condition: ModelSMMADAccountStatsConditionInput
  ) {
    updateSMMADAccountStats(input: $input, condition: $condition) {
      id
      Account
      AccountType
      StatDate
      TwitterFollowing
      TwitterFollowingIncr
      TwitterFollowingPer
      TwitterFollowers
      TwitterFollowersIncr
      TwitterFollowersPer
      TwitterTweets
      TwitterTweetsIncr
      TwitterTweetsPer
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSMMADAccountStats = /* GraphQL */ `
  mutation DeleteSMMADAccountStats(
    $input: DeleteSMMADAccountStatsInput!
    $condition: ModelSMMADAccountStatsConditionInput
  ) {
    deleteSMMADAccountStats(input: $input, condition: $condition) {
      id
      Account
      AccountType
      StatDate
      TwitterFollowing
      TwitterFollowingIncr
      TwitterFollowingPer
      TwitterFollowers
      TwitterFollowersIncr
      TwitterFollowersPer
      TwitterTweets
      TwitterTweetsIncr
      TwitterTweetsPer
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSMMADCampaigns = /* GraphQL */ `
  mutation CreateSMMADCampaigns(
    $input: CreateSMMADCampaignsInput!
    $condition: ModelSMMADCampaignsConditionInput
  ) {
    createSMMADCampaigns(input: $input, condition: $condition) {
      id
      Account
      AccountType
      Campaign
      Description
      SMMADCampaignStatus
      TwitterPostingFrequencyHours
      ContentTextActive
      PostedCount
      Weight
      ZScore
      LastPostDate
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSMMADCampaigns = /* GraphQL */ `
  mutation UpdateSMMADCampaigns(
    $input: UpdateSMMADCampaignsInput!
    $condition: ModelSMMADCampaignsConditionInput
  ) {
    updateSMMADCampaigns(input: $input, condition: $condition) {
      id
      Account
      AccountType
      Campaign
      Description
      SMMADCampaignStatus
      TwitterPostingFrequencyHours
      ContentTextActive
      PostedCount
      Weight
      ZScore
      LastPostDate
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSMMADCampaigns = /* GraphQL */ `
  mutation DeleteSMMADCampaigns(
    $input: DeleteSMMADCampaignsInput!
    $condition: ModelSMMADCampaignsConditionInput
  ) {
    deleteSMMADCampaigns(input: $input, condition: $condition) {
      id
      Account
      AccountType
      Campaign
      Description
      SMMADCampaignStatus
      TwitterPostingFrequencyHours
      ContentTextActive
      PostedCount
      Weight
      ZScore
      LastPostDate
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSMMADHashTags = /* GraphQL */ `
  mutation CreateSMMADHashTags(
    $input: CreateSMMADHashTagsInput!
    $condition: ModelSMMADHashTagsConditionInput
  ) {
    createSMMADHashTags(input: $input, condition: $condition) {
      id
      Account
      AccountType
      Campaign
      HashTag
      PostedCount
      Weight
      ZScore
      SMMADHashTagStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSMMADHashTags = /* GraphQL */ `
  mutation UpdateSMMADHashTags(
    $input: UpdateSMMADHashTagsInput!
    $condition: ModelSMMADHashTagsConditionInput
  ) {
    updateSMMADHashTags(input: $input, condition: $condition) {
      id
      Account
      AccountType
      Campaign
      HashTag
      PostedCount
      Weight
      ZScore
      SMMADHashTagStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSMMADHashTags = /* GraphQL */ `
  mutation DeleteSMMADHashTags(
    $input: DeleteSMMADHashTagsInput!
    $condition: ModelSMMADHashTagsConditionInput
  ) {
    deleteSMMADHashTags(input: $input, condition: $condition) {
      id
      Account
      AccountType
      Campaign
      HashTag
      PostedCount
      Weight
      ZScore
      SMMADHashTagStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createSMMADPlatforms = /* GraphQL */ `
  mutation CreateSMMADPlatforms(
    $input: CreateSMMADPlatformsInput!
    $condition: ModelSMMADPlatformsConditionInput
  ) {
    createSMMADPlatforms(input: $input, condition: $condition) {
      id
      Platform
      PlatformLogoURL
      SMMADPlatformsStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateSMMADPlatforms = /* GraphQL */ `
  mutation UpdateSMMADPlatforms(
    $input: UpdateSMMADPlatformsInput!
    $condition: ModelSMMADPlatformsConditionInput
  ) {
    updateSMMADPlatforms(input: $input, condition: $condition) {
      id
      Platform
      PlatformLogoURL
      SMMADPlatformsStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteSMMADPlatforms = /* GraphQL */ `
  mutation DeleteSMMADPlatforms(
    $input: DeleteSMMADPlatformsInput!
    $condition: ModelSMMADPlatformsConditionInput
  ) {
    deleteSMMADPlatforms(input: $input, condition: $condition) {
      id
      Platform
      PlatformLogoURL
      SMMADPlatformsStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createBatchJobs = /* GraphQL */ `
  mutation CreateBatchJobs(
    $input: CreateBatchJobsInput!
    $condition: ModelBatchJobsConditionInput
  ) {
    createBatchJobs(input: $input, condition: $condition) {
      id
      BatchJobName
      Account
      Service
      JobJSON
      BatchJobStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateBatchJobs = /* GraphQL */ `
  mutation UpdateBatchJobs(
    $input: UpdateBatchJobsInput!
    $condition: ModelBatchJobsConditionInput
  ) {
    updateBatchJobs(input: $input, condition: $condition) {
      id
      BatchJobName
      Account
      Service
      JobJSON
      BatchJobStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteBatchJobs = /* GraphQL */ `
  mutation DeleteBatchJobs(
    $input: DeleteBatchJobsInput!
    $condition: ModelBatchJobsConditionInput
  ) {
    deleteBatchJobs(input: $input, condition: $condition) {
      id
      BatchJobName
      Account
      Service
      JobJSON
      BatchJobStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createBlogPosts = /* GraphQL */ `
  mutation CreateBlogPosts(
    $input: CreateBlogPostsInput!
    $condition: ModelBlogPostsConditionInput
  ) {
    createBlogPosts(input: $input, condition: $condition) {
      id
      Title
      HashTags
      BlogPost
      Likes
      Unlikes
      BlogPostStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateBlogPosts = /* GraphQL */ `
  mutation UpdateBlogPosts(
    $input: UpdateBlogPostsInput!
    $condition: ModelBlogPostsConditionInput
  ) {
    updateBlogPosts(input: $input, condition: $condition) {
      id
      Title
      HashTags
      BlogPost
      Likes
      Unlikes
      BlogPostStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteBlogPosts = /* GraphQL */ `
  mutation DeleteBlogPosts(
    $input: DeleteBlogPostsInput!
    $condition: ModelBlogPostsConditionInput
  ) {
    deleteBlogPosts(input: $input, condition: $condition) {
      id
      Title
      HashTags
      BlogPost
      Likes
      Unlikes
      BlogPostStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const createRhyteItLog = /* GraphQL */ `
  mutation CreateRhyteItLog(
    $input: CreateRhyteItLogInput!
    $condition: ModelRhyteItLogConditionInput
  ) {
    createRhyteItLog(input: $input, condition: $condition) {
      id
      Environment
      ipaddress
      User
      Page
      Function
      ErrorMessage
      ErrorJSON
      ErrorLine
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const updateRhyteItLog = /* GraphQL */ `
  mutation UpdateRhyteItLog(
    $input: UpdateRhyteItLogInput!
    $condition: ModelRhyteItLogConditionInput
  ) {
    updateRhyteItLog(input: $input, condition: $condition) {
      id
      Environment
      ipaddress
      User
      Page
      Function
      ErrorMessage
      ErrorJSON
      ErrorLine
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const deleteRhyteItLog = /* GraphQL */ `
  mutation DeleteRhyteItLog(
    $input: DeleteRhyteItLogInput!
    $condition: ModelRhyteItLogConditionInput
  ) {
    deleteRhyteItLog(input: $input, condition: $condition) {
      id
      Environment
      ipaddress
      User
      Page
      Function
      ErrorMessage
      ErrorJSON
      ErrorLine
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
