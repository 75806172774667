
import { Amplify } from 'aws-amplify';
import React, { useState, useEffect } from 'react'
//import isUrlHttp from 'is-url-http';
//import './Application.css'; 
import {
    TextField, 
    Text,
    CheckboxField,
    Button,
    Flex
  } from '@aws-amplify/ui-react';
import PropTypes from 'prop-types';
import styles from './Application.module.css';  
import {listApplicants} from '../../graphql/queries'
import {createAudienceGeoInfo, createApplicants}  from '../../graphql/mutations'
import StatusMessageBox from "../../ui-components/StatusMessageBox";
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";
import MenuBackground from '../Menu/MenuBackground';
import {motion as m} from "framer-motion";
import axios from "axios";
import {uuidv4} from "../../BusinessLogic/UtilitiesManagement";
import { get_uuidv4 } from "../../BusinessLogic/uuidv4";

import { generateClient } from 'aws-amplify/api';


const Application = (props) => {

    Application.propTypes = {
        UserEmail: PropTypes.string,
        UserId: PropTypes.string,
        UserName: PropTypes.string
      };

    
    const client = generateClient();

    const [ValidURLFlag, setValidURLFlag] = useState(false); 
    const [FirstTouch, setFirstTouch] = useState(true);      
    const [ip, setIP] = useState('');  
    let vIPAddress = ""      


    const [NewUser, setNewUser] = useState({
                                id: "",
                                UserId: "",
                                UserEmail: "",
                                UserLastName: "",
                                UserFirstName: "",
                                UserDialCode: "",
                                UserPhone: "",
                                SocialMediaProfile:  "",
                                ipAddress:  "",
                                PrivacyAcknowledgement: "",
                                VerificationCode: "",
                                VerificationStatus: "",
                                Application:  "",
                                Active:  "",
                                CreationName:  "",
                                UpdateName:  "",
                                CreationDateTime:  ""})
                                                                           

    let [Acknowledgement, setAcknowledgement] = useState(false)   
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  

    useEffect(() => {

        getData()
        setErrorMessage("")
                
    },[])   

    useEffect(() => {

        //console.log('Application.useEffect[ip].ip:', ip);
        //setErrorMessage(ip)
                
    },[ip])       
    
    const getData = async () => {
        //console.log("LandingPageV4.handleMouseOver.FirstTouch - Before: ", FirstTouch);
        if (FirstTouch === true) {
            //console.log("LandingPageV4.handleMouseOver.FirstTouch: ", FirstTouch);
            setFirstTouch(false);    
            //console.log("LandingPageV4.handleMouseOver.FirstTouch2: ", FirstTouch);
  
            const res = await axios.get('https://geolocation-db.com/json/')
            //console.log("LandingPageV4.getData.res.data: ",res.data );
            //console.log("LandingPageV4.getData.res: ",res );
            setIP(res.data.IPv4)
  
            let DynamoUUID =  await uuidv4()
  
            vIPAddress = res.data.IPv4
  
          if (res.data.IPv4 !== "98.199.173.171") {
  
            let SaveSubReddit = {
            id: DynamoUUID,
            UserId: "edwardhawke@rhyteit.com",
            Page: "Application",
            IPv4: res.data.IPv4,
            city: res.data.city,
            country_code: res.data.country_code,
            country_name: res.data.country_name,
            latitude: res.data.latitude,
            longitude: res.data.longitude,
            postal: res.data.postal,
            state: res.data.state,
            CreationName:  "Application",
            UpdateName: "Application"
            }                      
  
            //console.log("LandingPageV4.getData.SaveSubReddit: " ,SaveSubReddit)
  
            try{
  
                let createAudienceGeoInfoResponse = await client.graphql({                    
                    query: createAudienceGeoInfo,
                    variables:{input: {SaveSubReddit
                    }
                  }
                });
                
                //console.log("LandingPageV4.getData.createAudienceGeoInfoResponse: " ,createAudienceGeoInfoResponse)
  
            } catch (err)  {
            ErrorMessageStr = 'Application.getData.ERROR: ' + err + err.stack
            //setErrorMessage(ErrorMessageStr)
            console.error(ErrorMessageStr); 
            }
  
          }
        }
  
    }  

    function UserEmail_handleChange(event, data) {
        try {              
            
            // //console.log('UserEmail_handleChange.data.email:', data.email);

            // //console.log('UserEmail_handleChange.event.target.value:', event.target.value);

            NewUser.UserId = event.target.value;
            
            setNewUser(NewUser)

            } catch (err) { //console.log('error TeamsUserId_handleChange')
            console.error(err, err.stack); 
        }        
    }  
    
    function SocialMediaProfile_handleChange(event, data) {
        try {              
            
            //console.log('UserEmail_handleChange.data.email:', data.email);

            //console.log('SocialMediaProfile_handleChange.event.target.value:', event.target.value);

            NewUser.SocialMediaProfile = event.target.value;
            
            setNewUser(NewUser)

            } catch (err) { //console.log('error TeamsUserId_handleChange')
            console.error(err, err.stack); 
        }        
    }  
    
    async function AcknowledgementChanged(e, data) {
        try {


            //console.log("Application.AcknowledgementChanged.data: ", data)
            //console.log("Application.AcknowledgementChanged.e: ", e)
            //console.log("Application.AcknowledgementChanged.Acknowledgement: ", Acknowledgement)

            setAcknowledgement(Acknowledgement===false ? true : false)
            
            //console.log("Application.AcknowledgementChanged.Acknowledgement2: ", Acknowledgement)

    } catch (err) { //console.log('error fetching Users')
                    console.error(err, err.stack); 
                }
    }          

    async function ProcessApplicant(e, data) {
        try {

            //console.log("Application.ProcessApplicant.data:   ",data)
            let ProcessApplicantErrorMessage = ""
            //console.log("Application.ProcessApplicant.NewUser:   ", NewUser)

            let IPAddressAlreadyExists = await CheckIPAddress()

            if ( IPAddressAlreadyExists === false) {

                let EmailAddressAlreadyExists = await CheckEmailAddress(NewUser.UserId)
                console.log("Application.ProcessApplicant.EmailAddressAlreadyExists:   ", EmailAddressAlreadyExists)

                if (EmailAddressAlreadyExists === true) {
                        
                    ProcessApplicantErrorMessage = "You have already applied."
                    setErrorMessage(ProcessApplicantErrorMessage)
    
                } else if (EmailAddressAlreadyExists === false) {
                    
                    User_Add(NewUser);
                }

            }

    } catch (err) { //console.log('error fetching Users')
                    console.error(err, err.stack); 
                }
    }      

    async function CheckIPAddress() {
        try {

            let vCheckIPAddressReturnMessage = false
            //console.log("Application.CheckIPAddress.NewUser: ", NewUser )
            //console.log("Application.CheckIPAddress.NewUser[0].email: ", NewUser.UserId , " - ip address: ", ip)

            const variables = {
                filter: {
                    ipAddress: {
                        eq: ip             
                    }
                }
              };

            const ApplicantIPAddressCheck  = await client.graphql({
                query: listApplicants,
                variables: variables
              });

            const vIPAddressCheck = ApplicantIPAddressCheck.data.listApplicants.items          
            
           //console.log("Application.CheckIPAddress.vIPAddressCheck: ", vIPAddressCheck)

            if (vIPAddressCheck.length > 0) {

                vCheckIPAddressReturnMessage = true
                let fetchUserIdErrorMessage = "You have already applied."
                setErrorMessage(fetchUserIdErrorMessage)

            }
            else if (vIPAddressCheck.length === 0) {                
                vCheckIPAddressReturnMessage = false
            }         
        
    
            //console.log("Application.CheckIPAddress.vCheckIPAddressReturnMessage: ", vCheckIPAddressReturnMessage)
            return vCheckIPAddressReturnMessage

        } catch (err) { //console.log('error fetching Users')
                        console.error(err, err.stack); 
                    }
    }   

    async function CheckEmailAddress(pApplicantEmail) {
        try {

            let vCheckEmailAddressReturnMessage = false
            console.log("Application.CheckEmailAddress.pApplicantEmail: ", pApplicantEmail)

            const variables = {
                filter: {
                    UserId: {
                        eq: pApplicantEmail
                    }
                }
              };

            const ApplicantEmailAddressCheck  = await client.graphql({
                query: listApplicants,
                variables: variables
              });            


     
            const vEmailAddressCheck = ApplicantEmailAddressCheck.data.listApplicants.items    
            console.log("Application.CheckEmailAddress.vEmailAddressCheck: ", vEmailAddressCheck)      
            
            if (vEmailAddressCheck.length > 0) {

                vCheckEmailAddressReturnMessage = true
                let fetchUserIdErrorMessage = "You have already applied."
                setErrorMessage(fetchUserIdErrorMessage)

            }
            else if (vEmailAddressCheck.length === 0) {                
                vCheckEmailAddressReturnMessage = false
            }         
        
    
            return vCheckEmailAddressReturnMessage

        } catch (err) { //console.log('error fetching Users')
                        console.error(err, err.stack); 
                    }
    }   


    async function User_Add(pNewUser) {
        try {

            let ReturnMessage = ""
            //console.log("Application.User_Add.pNewUser:  ", pNewUser)
            //console.log("Application.User_Add.DynamoUUID:  ", DynamoUUID)
            //console.log("Application.User_Add.pNewUser.UserId:  ", pNewUser.UserId)
            //console.log("Application.User_Add.pNewUser.SocialMediaProfile.length    :  ", pNewUser.SocialMediaProfile.length )
 
            //console.log("Application.User_Add.ValidURL    :  ", ValidURL )
            

                
                    setValidURLFlag(true)       

                    let vApplicant = {
                        id: pNewUser.UserId,
                        UserId: pNewUser.UserId,
                        UserLastName: "",
                        UserFirstName: "",
                        ipAddress: ip,
                        UserPhone: "+18005555555",
                        UserEmail: pNewUser.UserId,
                        SocialMediaProfile: pNewUser.SocialMediaProfile,
                        PrivacyAcknowledgement: Acknowledgement,
                        EmailTemplate: "Welcome Gift",
                        Application: "New",
                        InOnlyFansProspectingProfile: false,
                        CreationName: pNewUser.UserId,
                        UpdateName: pNewUser.UserId,
                        Active: true
                    }      

                    //let arr = CollabortionToolStatusSchedulers.concat(NewCollabortionToolStatusSchedule);
                    setNewUser(vApplicant);
                    //console.log("Application.User_Add.vApplicant: ",vApplicant)

                        try {

                            const ReturnMessage = await client.graphql({
                                query: createApplicants,
                                variables: {
                                  input: vApplicant
                                }
                              });             
                            //console.log("Application.User_Add.ReturnMessage: ",ReturnMessage)
                            if (ReturnMessage.data.createApplicants.CreationDateTime.length > 0 ) {

                                var vUpdateDateTime = new Date().toISOString()

                                ErrorMessageStr = "Thank you for your application " + ReturnMessage.data.createApplicants.UserId +  ". We look forward to working with you." 
                                setErrorMessage(ErrorMessageStr)
                                
                            }                    

                        } catch (err) { 

                            console.error(err); 
                            ErrorMessageStr = 'Application.User_Add.Mutations.createApplicants.ERROR:  ' + err.errors[0].message + ' - ' + err.lineNumber
                            console.error(ErrorMessageStr); 
                            setErrorMessage(ErrorMessageStr)
                    }     






        } catch (err) {   
                ErrorMessageStr = 'Application.User_Add.ERROR:  ' + err 
                console.error(ErrorMessageStr); 
                setErrorMessage(ErrorMessageStr)
        }        
    }     

    return (
        <m.div
                key="LandingPageV2"
                initial={{y: "100%"}}
                animate={{y: "0%"}}
                transition={{duration:0.75, ease: "easeOut"}}
                exit={{ opacity: 1 }}
               >
            <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#777"   GradientStopColor4="#8880"/> 
            <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="2rem"
                    className = {styles.SignUpFormDiv}>    
                <HeaderMenu pageSource="Application"/>      
                <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="3rem"
                    className = {styles.SignUpContentSection}>         
                        <Flex
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            alignContent="flex-start"
                            wrap="wrap"
                            gap="0.25rem"
                            className={styles.SignUpContentSectionCenterLeftColumn}>
                            <div  className={styles.SignUpFormContainer}>
                                <label className = {styles.RequiredFields}>
                                Let's make you part of the 1%
                                </label>                                
                            </div>                                       
                            <div className={styles.SignUpFormContainer}> 
                                <div className = {styles.HeaderLine}/>                            
                            </div> 
                            <form className = {styles.SignUpForm}>
                                <Flex
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="stretch"
                                        alignContent="flex-start"
                                        wrap="wrap"
                                        gap="0.25rem"
                                        className = {styles.SignUpFormContent}>                                              
                                        <div className={styles.SignUpFormContainer}>
                                            <TextField  
                                                className={ styles.EmailFieldHighlighted }
                                                type="email" 
                                                color="white"
                                                isRequired={true}  
                                                placeholder="Please enter your email"  
                                                defaultValue={NewUser.UserId}
                                                label={
                                                    <Text 
                                                        className={styles.EmailFieldHighlighted}>
                                                        Email                                                   
                                                    </Text>
                                                }
                                                name="NewUser.Email" 
                                                onChange={ (e) => UserEmail_handleChange(e) } 
                                                />                                                    
                                        </div>                                                                               
                                        <span className={styles.SignUpFormSpan}></span>                                        
                                        <div className={styles.SignUpFormContainer}>
                                            <TextField  
                                                className={ValidURLFlag === true ? styles.SignUpFormEmailField : styles.EmailFieldHighlighted }
                                                type="text" 
                                                color="white"
                                                isRequired={true}  
                                                placeholder="https://onlyfans.com/thetulsaredbearr"  
                                                defaultValue={NewUser.SocialMediaProfile}
                                                label={
                                                    <Text 
                                                    className={ValidURLFlag === true ? styles.SignUpFormLabel: styles.EmailFieldHighlighted }>
                                                    OnlyFans/Sheer/Fansly/etc. profile URL
                                                    </Text>
                                                }
                                                name="NewUser.SocialMediaProfile" 
                                                onChange={ (e) => SocialMediaProfile_handleChange(e) } 
                                                />                                                    
                                        </div>                                                                               
                                        <span className={styles.SignUpFormSpan}></span>         
                                        <div className={styles.SignUpFormContainer}>
                                            <div className={styles.AcknowledgeRow}>                                                
                                                <div className={styles.AcknowledgeCheckboxFieldContainer}>
                                                    <CheckboxField className={styles.Checkbox}
                                                        name="subscribe-controlled"
                                                        checked={Acknowledgement}
                                                        size="small"
                                                        onClick={ (e) => AcknowledgementChanged(e) } 
                                                        />                                                                                    
                                                </div>                                                                                                   
                                                <div className={styles.AcknowledgeDescriptionContainerColumn}>
                                                    <span className={styles.AcknowledgeDescriptionColumn}> I certify that I have read the <a  target="_blank" rel="noopener noreferrer" className={styles.AcknowledgeDescriptionColumn} href="/PrivacyPolicy">Privacy Policy.</a>                                               
                                                </span>
                                                </div>                      
                                            </div>
                                        </div>
                                        <div className={styles.SignUpButtonContainer} >
                                            <Button
                                                className={styles.metallicbutton}
                                                loadingText=""
                                                onClick={ (e) => ProcessApplicant(e, NewUser) } 
                                                ariaLabel=""
                                                isDisabled = {Acknowledgement ? false : true}
                                                >
                                                Apply Now
                                            </Button>    
                                        </div>  
                                        <div className={styles.StatusContainer} >
                                            <StatusMessageBox className={styles.StatusMessageBox} ErrorMessage={ErrorMessage}></StatusMessageBox>  
                                        </div>                                                                                                                                                            
                                </Flex>
                            </form>
                        </Flex> 
                        <Flex
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            alignContent="flex-start"
                            wrap="wrap"
                            gap="0.25rem"
                            className={styles.SignUpContentSectionCenterLeftColumn}>  
                            <div className = {styles.ValuePropAnimatedBorder}>
                                <div className = {styles.InnerBoxBackGround}>
                                </div> 
                                <div className = {styles.InnerBlackBoxBackGround}>
                                    <div className = {styles.ValuePropStatementPadding}>
                                        <h2  className={styles.WhatisYourFreedomWorth}>Thank you for expressing your interest in RhyteIt. It is entrepreneurs like yourself that move the world forward.</h2>
                                        <p className={styles.ParagraphText}>As an entrepreneur, your drive and vision are catalysts for progress and innovation. We appreciate your interest and will keep you informed through email regarding the status of your application.</p>
                                        <p className={styles.ParagraphText}>We are eagerly anticipating the opportunity to welcome you as a valued member of our community, and are committed to supporting the growth and success of your business aspirations.</p>
                                    </div> 
                                </div> 
                            </div> 
                        </Flex>
                </Flex>
            </Flex>
  
            </m.div>
                
    );
}

export default Application;    