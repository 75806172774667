
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  Flex
  } from '@aws-amplify/ui-react'; 
import SMMADTextRow from './SMMADTextRow';
import styles from './SMMADTextTable.module.css';


const SMMADTextTable = (props) => {       

  SMMADTextTable.propTypes = {
      SMText: PropTypes.array,
      SMCampaigns: PropTypes.array,
      RhyteItUser: PropTypes.array,
      HashTagMap: PropTypes.array
      };

    let debugConsoleOutPut = true

  

    const [SMText, setSMText] = useState(props.SMText)     
    const [SMCampaigns, setSMCampaigns] = useState(props.SMCampaigns)   
    const [ShowTable, setShowTable] = useState(false);  
    const [vHashTagMap, setvHashTagMap] = useState(props.HashTagMap) 

    useEffect(() => {


      //console.log("SMMADTextTable.useEffect[].props.HashTagMap:  ",props.HashTagMap) 

    },[])  

    useEffect(() => {

      //console.log("SMMADTextTable.useEffect[].props.SMText:  ",props.SMText)
      //console.log("SMMADTextTable.useEffect[].SMText ",SMText) 
      //console.log("vSMMADTextRows.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vSMMADTextRows.useEffect[].Time:  ", SMMADTextRow.PostDateTime.substr(SMMADTextRow.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vSMMADTextRows.useEffect[].indexOf:  ", SMMADTextRow.PostDateTime.indexOf(":00"))
      if (SMText.length > 0) {
        setShowTable(true)
      }

    },[SMText])      



  return (  
    <Flex
    direction="row"
    justifyContent="flex-start"
    alignItems="stretch"
    alignContent="flex-start"
    wrap="wrap"
    gap="1rem"
    >       
          
              {
                ShowTable && SMText.length > 0 &&  SMText && SMText.map((SMMADText) => (                      
                  (<SMMADTextRow   key={SMMADText.id}  SMMADText={SMMADText}  HashTagMap={vHashTagMap} SMCampaigns={SMCampaigns}/>)        
                  ))
              }                    
    
        </Flex>        
    
  )
}

export default SMMADTextTable;


