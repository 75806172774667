
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Menu.module.css';
import { SEO } from '../../SEO/SEO';
import {useNavigate,Link } from 'react-router-dom';
import axios from 'axios';

import  { createAudienceGeoInfo}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import { useInView, useScroll, useTransform, motion, useAnimation } from "framer-motion";
//import {useInView} from "react-intersection-observer";

import Footer from '../../RhyteItComponents/Footer/Footer';
import FooterV2 from '../../RhyteItComponents/Footer/FooterV2';
import {
    Button,
    Flex, 
    Text,
    Image,
    useAuthenticator
  } from '@aws-amplify/ui-react';
import { UserContext, CognitoGroupContext} from '../../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import { NavBarMenuClickedContext} from '../../../BusinessLogic/Hooks/NavBarMenuContext';
import { signOut } from 'aws-amplify/auth';



const Menu  = (props) => {
 
    Menu.propTypes = {
    UserId: PropTypes.string,
    UserName: PropTypes.string
  };


  
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  let navigate = useNavigate(); 

  let ErrorMessageStr = ""
  const {Users, setUsers} = useContext(UserContext);    
  const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);  
  const [vNavBarMenuClicked, setvNavBarMenuClicked] = useState(false);  
  const [vHeaderTextKey, setvHeaderTextKey] = useState();   
  const [vHeaderSubTextKey, setvHeaderSubTextKey] = useState(); 
  const [vHomeImageVisible, setvHomeImageVisible] = useState(false); 
  const [vProductImageVisible, setvProductImageVisible] = useState(false); 
  const [vNewsImageVisible, setvNewsImageVisible] = useState(false);   
  const [vModelsImageVisible, setvModelsImageVisible] = useState(false);   
  const [vAboutImageVisible, setvAboutImageVisible] = useState(false); 
  
  const { scrollYProgress } = useScroll();

  const rotate = useTransform(scrollYProgress, [0, 1], [20, 0]);
  const scale = useTransform(scrollYProgress, [0, 1], [1.05, 1]);
  const translate = useTransform(scrollYProgress, [0, 1], [0, -50]);
  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);

  const HomeTextRef = useRef(null);
  const HomeImageRef = useRef(null);

  const ref= useRef(null);
  const isInView = useInView(ref)

  const mainControls = useAnimation()

  let customStyles = {
    '--x': '4s',
  };


  useEffect(() => {

    setvHeaderTextKey(Date.now())
    setvHeaderSubTextKey(Date.now())
    

    },[]) 


    useEffect(() => {
        
       // console.log("Menu.useEffect[isInView].isInView: " ,isInView);
      //  console.log("Menu.useEffect[isInView].scrollYProgress: " ,scrollYProgress);
        
        if (isInView) {
            mainControls.start("visible")
            window.scrollTo(0, 0)
        }
    
        },[isInView]) 
    

    useEffect(() => {

     //console.log("Menu.useEffect[vNavBarMenuClicked].vNavBarMenuClicked: " ,vNavBarMenuClicked);

    },[vNavBarMenuClicked]) 

    useEffect(() => {

   
       },[vHomeImageVisible]) 

 
  
async function GoToMenuSignedIn() {
    try {
  
     // console.log("LandingPageNavBar.GoToMenuSignedIn.authStatus: " ,authStatus, " - .CognitoGroup: " ,CognitoGroup, " - .Users: " ,Users);
  
  
          navigate('/AuthenticatorRhyteIt', 
          {
            state: {
              UserId:Users,                                
              UserName:Users,                                
              CognitoGroup:CognitoGroup,
              authStatus: authStatus
            }
          }
        );  
          
          
  
      } catch (err) { 
        RhyteItLogging(Users, "GoToMenuSignedIn", "getData",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
    }        
  }  
  
  async function handleSignOut() {
    try {
      await signOut();
    } catch (error) {
      //console.log('error signing out: ', error);
    }
  }
    
    

  return (
                          
    
                        <section
                            ref={ref}
                            className={styles.HeaderTextContainer}>                  
                        <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="wrap"
                        className={styles.MenuTextContainer}

                    >      
                        <motion.div
                            key={vHeaderTextKey}
                            initial={{y: "-10%"}}
                            animate={{y: "100%"}}
                            transition={{duration:2, ease: "easeOut"}}
                            exit={{ opacity: 1 }} 
                        >                      
                            <Flex
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                alignContent="center"
                                wrap="wrap"
                                className={styles.MenuTextRow}
                                >     
                                <div className={styles.MenuHomeDiv}>  
                                <Text className={styles.MenuText}                    
                                    id="HomeText"
                                    ref={HomeTextRef}
                                    onMouseEnter={() => setvHomeImageVisible(true)}
                                    onMouseLeave={() =>  setvHomeImageVisible(false)}
                                    onClick={(e) => setvNavBarMenuClicked(!vNavBarMenuClicked)}
                                    >
                                    Home
                                </Text>  
                                
                                <Image
                                    alt=""
                                    src="https://rhyteiticons.s3.us-west-2.amazonaws.com/ImageIcons/Home.png" 
                                    className={vHomeImageVisible === false ?  styles.PhotoImage :  styles.PhotoImageVisible }                                    
                                />                                    
                                </div>                                                                                
                                <Link className={styles.Link} to="/TurboFanBoost">              
                                    <Text className={styles.MenuText}
                                    
                                    onMouseEnter={() => setvProductImageVisible(true)}
                                    onMouseLeave={() =>  setvProductImageVisible(false)}
                                    >
                                        Products
                                    </Text> 
                                    <Image
                                        alt=""
                                        src="https://rhyteiticons.s3.us-west-2.amazonaws.com/ImageIcons/Products.png" 
                                        className={vProductImageVisible === false ?  styles.PhotoImage :  styles.ProductImageVisible }                                    
                                    />                                                                        
                                </Link>                                        
                                <Link className={styles.Link} to="/SBSMNews">              
                                    <Text className={styles.MenuText}
                                    onMouseEnter={() => setvNewsImageVisible(true)}
                                    onMouseLeave={() =>  setvNewsImageVisible(false)}>
                                        News
                                    </Text> 
                                    <Image
                                        alt=""
                                        src="https://rhyteiticons.s3.us-west-2.amazonaws.com/ImageIcons/News.png" 
                                        className={vNewsImageVisible === false ?  styles.PhotoImage :  styles.NewsImageVisible }                                    
                                    />              
                                </Link>    
                            </Flex>   
                        </motion.div>      
                        <motion.div
                            key={vHeaderTextKey}
                            initial={{y: "100%"}}
                            animate={{y: "0%"}}
                            transition={{duration:2, ease: "easeOut"}}
                            exit={{ opacity: 1 }} 
                        >                      
                            <Flex
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                alignContent="center"
                                wrap="wrap"
                                className={styles.MenuTextRow}
                                >    
                                                                                                     
                                <Link className={styles.Link} to="/AdultContentCreatorSearch">               
                                    <Text className={styles.MenuText}
                                    onMouseEnter={() => setvModelsImageVisible(true)}
                                    onMouseLeave={() =>  setvModelsImageVisible(false)}>
                                        Models
                                    </Text>     
                                    <Image
                                        alt=""
                                        src="https://rhyteiticons.s3.us-west-2.amazonaws.com/ImageIcons/Models.png" 
                                        className={vModelsImageVisible === false ?  styles.PhotoImage :  styles.ModelsImageVisible }                                    
                                    />               
                                </Link>                                        
                                <Link className={styles.Link} to="/AdultContentCreatorSearch"
                                    onMouseEnter={() => setvAboutImageVisible(true)}
                                    onMouseLeave={() =>  setvAboutImageVisible(false)}>          
                                    <Text className={styles.MenuText}>
                                        About
                                    </Text>      
                                    <Image
                                        alt=""
                                        src="https://rhyteiticons.s3.us-west-2.amazonaws.com/ImageIcons/About.png" 
                                        className={vAboutImageVisible === false ?  styles.PhotoImage :  styles.AboutImageVisible }                                    
                                    />        
                                </Link>                                                           
                            </Flex>   
                        </motion.div>  
                        <motion.div
                            key={vHeaderTextKey}
                            initial={{x: "100%"}}
                            animate={{x: "0%"}}
                            transition={{duration:2, ease: "easeOut"}}
                            exit={{ opacity: 1 }} 
                                className={styles.AuthenticateRow}
                        >                      
                            <Flex
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                alignContent="center"
                                wrap="wrap"
                                className={styles.AuthenticateRowTextRow}
                                >                                                                                                        
                    
                                <button  className={styles.Button}  onClick={(e) => GoToMenuSignedIn()}>                        
                                    {authStatus === "authenticated" ? 
                                          
                                        <Text className={styles.MenuText}>
                                            Enter
                                        </Text> :   
                                        <Text className={styles.MenuText}>
                                            Sign In
                                        </Text>  
                                      }
                                </button>  
                                <button  className={styles.Button}  onClick={(e) => handleSignOut()}>
                                    <Text className={styles.MenuText}>
                                        Sign Out
                                    </Text>                                      
                                </button>                               
                            </Flex>   
                        </motion.div>                                                 
                        </Flex>   
                        </section>   

        );
  }

export default Menu