
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  Image,
  TextField,  
  SelectField,
  CheckboxField,
  Flex,
  Button,
  ToggleButton,
  ToggleButtonGroup
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import  { deleteSMMADText, updateSMMADText, createSMMADText}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './SMMADTextRow.module.css';  
  import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';  
  import {get_uuidv4} from '../../../BusinessLogic/uuidv4';

const SMMADTextRow = (props) => {       

  SMMADTextRow.propTypes = {
    SMMADText: PropTypes.object,
    HashTagMap: PropTypes.array
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vSMMADTextRow, setvSMMADTextRow] = useState(props.SMMADText)   

    const [vCategory, setvCategory] = useState()   
    const [vCampaign, setvCampaign] = useState(props.SMMADText.Campaign)    
    const [vActive, setvActive] = useState(props.SMMADText.Active)   
    
    const [vUpdateDateTime, setvUpdateDateTime] = useState(props.SMMADText.UpdateDateTime)  
    const [vStatus, setvStatus] = useState(props.SMMADText.SMMADTextStatus)  
    const [vHashTagMap, setvHashTagMap] = useState(props.HashTagMap) 

    const [vText, setvText] = useState(props.SMMADText.Text)    
    const [vRecordSaved, setvRecordSaved] = useState(false)      
    const [vRowDeleted, setvRowDeleted] = useState(false)     

    useEffect(() => {

      
      },[])  

      useEffect(() => {
        
        //console.log("SMMADTextRow.useEffect[vCategory].vCategory:  ",vCategory) 
        //setHashMapToCategory()
       
       },[vCategory])  


   async function SaveRow(event, data) {
      try {           
        
          setErrorMessage("")

          var vUpdateDateTimeNew = new Date().toISOString()
      
          vSMMADTextRow.UpdateDateTime = vUpdateDateTimeNew       
          vSMMADTextRow.UpdateName = Users  
          let vActiveTmp = vActive  === false ?  true  :  false

          let TextItem = {
              "id": vSMMADTextRow.id,
              "Account": vSMMADTextRow.Account,
              "AccountType": vSMMADTextRow.AccountType,
              "Campaign": vCampaign,
              "Category": vCategory,
              "Text": vText,
              "PostedCount":  vSMMADTextRow.PostedCount,
              "Active":  vActiveTmp,
              "SMMADTextStatus":  "Updated",
              "CreationName": vSMMADTextRow.CreationName,
              "CreationDateTime": vSMMADTextRow.CreationDateTime,
              "UpdateName": vSMMADTextRow.UpdateName,
              "UpdateDateTime": vSMMADTextRow.UpdateDateTime,
          }


        
 
              TextItem.SMMADTextStatus = "Updated"
              const updateSMMADTextResponse = await client.graphql({
                query: updateSMMADText,
                variables: {
                  input: TextItem
                }
              });  

              setvSMMADTextRow(updateSMMADTextResponse.data.updateSMMADText)

  
              let vActiveTemp = (vActive  === false ?  true  :  false  )

              //console.log("SMMADCampaignRow.SaveRow.vCampaignStatus:  ",vCampaignStatus)  
              //setvCampaignToShow(updateSMMADCampaignResponse.data.updateSMMADCampaigns)
              setvActive(vActiveTemp)

            setvRecordSaved(true)        
            setvUpdateDateTime(vUpdateDateTimeNew)

        } catch (err) { 
          ErrorMessageStr = ("SMMADTextRow.SaveRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }      
    
    async function DeleteRow(event,data) {
      try {

              
              setErrorMessage("")   

              const DeleteSMMADTextRow = {
                id: vSMMADTextRow.id,
                _version: vSMMADTextRow._version
              }
              

              const vDeleteResponse = await client.graphql({
                query: deleteSMMADText,
                variables: {
                  input: DeleteSMMADTextRow
                }
              });  

              ErrorMessageStr = vDeleteResponse
              setErrorMessage(ErrorMessageStr)   
              setvRowDeleted(true)   
 
              
      
          } catch (err) { 

          ErrorMessageStr = "SMMADTextRow.DeleteRow.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }       

    async function ClickText(event,pText) {
      try {

              
          console.log("SMMADTextRow.ClickText.pText:  ",pText)
          navigator.clipboard.writeText(pText)
              
      
          } catch (err) { 

          ErrorMessageStr = "SMMADTextRow.ClickText.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }       




  return (
    vRowDeleted === false && (   
      <ToggleButtonGroup 
      className={styles.ToggleButtonGroup} 
      >
        <ToggleButton  className={styles.ToggleButton}  
                        size="small"  
                        key={vSMMADTextRow.id} 
                        onClick={(e) =>  ClickText(e, vText)} >
                    {vText}
        </ToggleButton>    
        <ToggleButton 
          className={styles.IconButton}   
          title="Delete text">                            
        <Image
            src="https://rhyteiticons.s3.us-west-2.amazonaws.com/ImageIcons/Garbage.png"
            className={styles.ImageDeleteIcon}
            onClick={(e) => DeleteRow(e, vSMMADTextRow)}
            //onMouseEnter={(e) => Alert(e)}
          />                                          
        </ToggleButton>     
        {
        vActive === true && (   
          <ToggleButton 
              className={styles.IconButton}   
              title="Stop posting text">                           
          <Image
              src="https://rhyteiticons.s3.us-west-2.amazonaws.com/ImageIcons/Inactive.png"
              className={styles.ImageDeleteIcon}
              onClick={(e) => SaveRow(e, vSMMADTextRow)}
              //onMouseEnter={(e) => Alert(e)}
            />                                          
          </ToggleButton>
      )}        
      {
          vActive === false && (    
            <ToggleButton 
                className={styles.IconButton}   
                title="Start posting text">                            
            <Image
                src="https://rhyteiticons.s3.us-west-2.amazonaws.com/ImageIcons/Active.png"
                className={styles.ImageDeleteIcon}
                onClick={(e) => SaveRow(e, vSMMADTextRow)}
                //onMouseEnter={(e) => Alert(e)}
              />                                          
            </ToggleButton>
      )}                     
      </ToggleButtonGroup>                      )
 
    
  )
}

export default SMMADTextRow;

