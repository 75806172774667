
import React, { useState , useEffect, createContext, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  Flex
  } from '@aws-amplify/ui-react'; 
import ContentRow from './ContentRow';
import styles from './ContentTable.module.css';
import { SelectedImageContext} from '../../../BusinessLogic/Hooks/SMMADImageContext';


const ContentTable = (props) => {       

  ContentTable.propTypes = {
      AccountSelected: PropTypes.string,
      CampaignSelected: PropTypes.string,
      SMContent: PropTypes.array,
      SMCampaigns: PropTypes.array,
      RhyteItUser: PropTypes.array,
      HashTagMap: PropTypes.array,
      ImageToTextRelationship: PropTypes.bool
      };

    let debugConsoleOutPut = true
  

    const [SMContent, setSMContent] = useState(props.SMContent)   
    const [SMCampaigns, setSMCampaigns] = useState(props.SMCampaigns)   
    const [ShowTable, setShowTable] = useState(false); 
    const [vHashTagMap, setvHashTagMap] = useState(props.HashTagMap) 
    
    const [vAccountSelected, setvAccountSelected] = useState(props.AccountSelected)   
    const [vCampaignSelected, setvCampaignSelected] = useState(props.CampaignSelected)    
    const [vUpdateKey, setvUpdateKey] = useState()    
    const [vClickedImage, setvClickedImage] = useState(SMContent[0].id)    
    let ErrorMessageStr =""
    useEffect(() => {


    },[])  

    useEffect(() => {

      //console.log("ContentTable.useEffect[].props.SMContent:  ",props.SMContent)
      //console.log("ContentTable.useEffect[].SMContent ",SMContent) 
      //console.log("vContentRows.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vContentRows.useEffect[].Time:  ", ContentRow.PostDateTime.substr(ContentRow.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vContentRows.useEffect[].indexOf:  ", ContentRow.PostDateTime.indexOf(":00"))
      if (SMContent.length > 0) {
        setShowTable(true)        
        setvUpdateKey(Date.now())
      }

    },[SMContent])      

    useEffect(() => {

      //console.log("ContentTable.useEffect[vClickedImage].vClickedImage:  ",vClickedImage)
      setvUpdateKey(Date.now())

    },[vClickedImage])      


    async function ClickImage(event,pSMMADContentRowId) {
      try {

              
          //console.log("ContentTable.ClickImage.pSMMADContentRowId:  ",pSMMADContentRowId)
          setvClickedImage(pSMMADContentRowId)
              
      
          } catch (err) { 

          ErrorMessageStr = "ContentTable.ClickImage.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr);   
        }               
      
    }      

    const handleClick = (item) => {
      alert(`You clicked on ${item}`)
    }

  return (  
    <SelectedImageContext.Provider value={vClickedImage}>
        <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="flex-start"
        wrap="wrap"
        gap="1rem"
      >      
          
              {
                vClickedImage && vUpdateKey && ShowTable && SMContent.length > 0 &&  SMContent && SMContent.map((SMMADContentRow) => {                      
                 return (<ContentRow   key={SMMADContentRow.id}    
                                        ImageToTextRelationship={props.ImageToTextRelationship} 
                                        SMMADContentRow={SMMADContentRow}  
                                        HashTagMap={vHashTagMap}   
                                        AccountSelected={vAccountSelected}  
                                        CampaignSelected={vCampaignSelected} 
                                        ClickedImage={vClickedImage}  
                                        onClick={() =>setvClickedImage(SMMADContentRow.id)} 
                      />)       
                 } 
                  )
              }                    
        </Flex>
        </SelectedImageContext.Provider>
    
  )
}

export default ContentTable;


