import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useRef } from 'react';

import  { listSMMADTexts, listSMMADHashTags }  from '../../../graphql/queries'
import  { createSMMADText, createSMMADHashTags}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {motion as m} from "framer-motion";
import TextTable from "./SMMADTextTable";
import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';


import {
  Button ,  Text, Flex,TextField, TextAreaField, ToggleButton
} from '@aws-amplify/ui-react';
import styles from './SMMADText.module.css';
import {get_uuidv4} from '../../../BusinessLogic/uuidv4';


const SMMADText = (props) => {       
  
  Text.propTypes = {
          AccountSelected: PropTypes.string,
          CampaignSelected: PropTypes.string,
          HashTagMap: PropTypes.array,
          ContentId: PropTypes.string,
          ContentRow: PropTypes.array
        };

        const client = generateClient();
        const [SMText, setSMText] = useState([]) 
        const [vAccountSelected, setvAccountSelected] = useState(props.AccountSelected)   
        const [vCampaignSelected, setvCampaignSelected] = useState(props.CampaignSelected)    
        const [vHashTagMap, setvHashTagMap] = useState(props.HashTagMap)  
        const [vContentRow, setvContentRow] = useState(props.ContentRow)  
        const [vTextLength, setvTextLength] = useState(0) 
        
        const [vCategory, setvCategory] = useState()   
  
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        let debugConsoleOutPut = true

        const {Users, setUsers} = useContext(UserContext);     
        

        const [vErrorMessage, setvErrorMessage] = useState() 
        const [updatedKey, setupdatedKey] = useState(Date.now())   
        const [vExecuteDataRefresh, setvExecuteDataRefresh] = useState(Date.now());
        const [vAddNewRow, setvAddNewRow] = useState(Date.now());
        const [vText, setvText] = useState("");
        const [vHashTags, setvHashTags] = useState();
        const [vNewHashTag, setvNewHashTag] = useState();

        
        const [nextToken, setnextToken] = useState(undefined) 
        const [IsLoading, setIsLoading] = useState(false)
        const [vAddTextAreaVisible, setvAddTextAreaVisible] = useState(false)

   

        useEffect(() => {
          
        //  console.log("Text.useEffect[].props.ContentRow:  ",props.ContentRow);          
          //console.log("Text.useEffect[].vCampaignSelected:  ",vCampaignSelected);

          (async function() {
            try {  
              setHashMapToCategory()
              GetText()               


                } catch (err) {
                  console.error('Text.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 


        useEffect(() => {
          //console.log("Text.useEffect[SMText].SMText:  ",SMText)
          //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )
 
           if (SMText.length > 0) {            
             setupdatedKey(Date.now())    
           }
 
 
         },[SMText])    

         useEffect(() => {

          //console.log("SMMADText.useEffect[vHashTags].vHashTags:  ",vHashTags)
          
 
         },[vHashTags])    

         useEffect(() => {

          
          //console.log("Text.useEffect[vText].vText:  ",vText);
          //console.log("Text.useEffect[vText].length:  ",(vText).length)

          if (vText !== undefined) {
            setvTextLength(vText.length)
            setupdatedKey(Date.now())
          }
 
         },[vText])    

         useEffect(() => {

          const fetch1 = async () => {

            let variables = ""
            try {

                  if(nextToken !== null) {
                    setIsLoading(true)

                      
                    if (props.ContentId !== "NA") {
                      variables = {
                        nextToken,
                        filter: {
                          Account: {
                                  eq: vAccountSelected
                              },
                          Campaign: {
                                  eq: vCampaignSelected
                              },
                          ContentId: {
                                  eq: props.ContentId 
                              }
                        }
                      };
                    } else {
                      variables = {
                        nextToken,
                        filter: {
                          Account: {
                                  eq: vAccountSelected
                              },
                          Campaign: {
                                  eq: vCampaignSelected
                              }
                        }
                      };

                    }


                    const TextData2  = await client.graphql({
                      query: listSMMADTexts,
                      variables: variables
                    });
            
            
                     let TextData = SMText                   
                          
               
                    TextData2.data.listSMMADTexts.items.forEach(item => 
                      {    
                    
                        if ([...TextData].filter(Text => item.id === Text.id)["length"] === 0) {  
                          TextData.push(item) 
                        }

                        if (TextData.length === 0) {

                          TextData.push(TextData2.data.listSMMADTexts.items) 

                        }
  
                      })


                      setSMText(TextData)  
                      setnextToken(TextData2.data.listSMMADTexts.nextToken)  

                                            

                      
                  } else if (nextToken === null){        
                    
                    
                    setIsLoading(false)
    
                  }
  
            } catch (err) {
              //console.log(err)
            }  finally {
              setIsLoading(false)
            }
          }
        
          fetch1()

        }, [nextToken])            


        function setHashMapToCategory() {
          try {
    
                    
                    try { 
                    let vCategoryList = ""
                    let vHashTags1 = ""

                    if (vCategory === undefined || vCategory === "") {
                      vHashTagMap.forEach(function(HashTag) {



                        if (vCategoryList.length > 0) {
                          vCategoryList +=  "," + HashTag.HashTag
                          vHashTags1 += " #" + HashTag.HashTag
                        } else {
                          vCategoryList = HashTag.HashTag
                          vHashTags1 =  "#" + HashTag.HashTag  
                        }

                    });  
                    
                    }
                    
    
                      setvCategory(vCategoryList)
                      setvHashTags(vHashTags1)
            
                    } catch (err) { 
                      console.error("SMMADText.Category_handleChange.Error:  ".err, err.stack); 
                  }                  
     
                    //console.log("ReportSelected.DashboardHighlevel.length: ",DashboardHighlevel.length); 
    
          } catch (err) { 
                          console.error('SMMADText.Category_handleChange.ERROR: ', err, err.stack); 
                      }
        }             
             
        async function GetText() {
          try {
                    setErrorMessage("")
                    setSMText([])
                    let variables = ""

                    
                    //console.log("Text.GetText.vAccountSelected:  ",vAccountSelected)
                    //console.log("Text.GetText.vCampaignSelected:  ",vCampaignSelected)
                    //console.log("Text.GetText.props.ContentId :  ",props.ContentId )

                    if (props.ContentId !== "NA") {
                      variables = {
                        filter: {
                          Account: {
                                  eq: vAccountSelected
                              },
                          Campaign: {
                                  eq: vCampaignSelected
                              },
                          ContentId: {
                                  eq: props.ContentId 
                              }
                        }
                      };
                    } else {
                      variables = {
                        filter: {
                          Account: {
                                  eq: vAccountSelected
                              },
                          Campaign: {
                                  eq: vCampaignSelected
                              }
                        }
                      };

                    }


                    const TextData2  = await client.graphql({
                      query: listSMMADTexts,
                      variables: variables
                    });
      
          
                    //console.log("Text.GetText.TextData2:  ",TextData2)

                    if (TextData2.data.listSMMADTexts.items.length > 0) {

                        const Text1 = TextData2.data.listSMMADTexts.items 

                        setSMText(Text1)  
           
                    }
                 

                      //console.log("Text.GetText.nextToken2:  ",TextData2.data.listSMMADTexts.nextToken)
                    if (TextData2.data.listSMMADTexts.nextToken !== undefined) {

                    //  console.log("Text.GetText.nextToken2:  ",TextData2.data.listSMMADTexts.nextToken)
                      setnextToken(TextData2.data.listSMMADTexts.nextToken) 
         
                  }



          } catch (err) { 
                          console.error('Text.GetText.ERROR: ', err, err.stack); 
                      }
        }            

        async function SaveText() {
          try {         

            
              console.log("Text.SaveText.vText:  ",vText)
              console.log("Text.SaveText.(vText).length:  ",(vText).length)

              let vLengthText =((vText).length < 230 && (vText).length > 0)
              console.log("Text.SaveText.vLengthText:  ",vLengthText)
              if ((vText).length < 230 && (vText).length > 0) {


                console.log("Text.SaveText.vText.ToBeSaved:  ",vText)

                let vAccountType = ""
        
                if (vAccountSelected.includes("RhyteIt") || vAccountSelected.includes("@rhyteit.com") || vAccountSelected.includes("@richbi.ch"))  {

                  vAccountType = "Internal"
                
                } else {
                
                  vAccountType = "Customer"

                }

                  var vUpdateDateTime = new Date().toISOString()

                  let NewText = {
                        id: get_uuidv4,
                        Account: vAccountSelected,
                        AccountType: vAccountType,
                        ContentId: props.ContentId ,
                        Campaign: vCampaignSelected, 
                        Category: vCategory,    
                        Text: vText,   
                        PostedCount: 0,
                        Weight: 0,
                        Active: true,
                        SMMADTextStatus: "new",
                        CreationName: vAccountSelected,
                        UpdateName: vAccountSelected,
                        CreationDateTime: vUpdateDateTime,
                        UpdateDateTime: vUpdateDateTime
                }    

                
                const updateSMMADTextResponse = await client.graphql({
                  query: createSMMADText,
                  variables: {
                    input: NewText
                  }})

                  let vArraySpot = Object.keys(SMText).length

                  SMText[vArraySpot] = NewText;

                  setvText("")

                  setvErrorMessage("saved") 
                  setupdatedKey(Date.now())  
                  setvAddTextAreaVisible(false)

              } else if ((vText).length >= 230) {
              
                  setvErrorMessage("Text plus hash tags is over 230 characters.") 

              } else if (0 === (vText).length) {
              
                setvAddTextAreaVisible(false)
              }
        
                } catch (err) { 
                  console.error("SMMADText.AddTextRow.Error:  ".err, err.stack); 
              }        
        }        

        function Text_handleChange(event,data) {
          try {
    
    
       
                      setvText(event.target.value)
            
          } catch (err) { 
                          console.error('SMMADText.Text_handleChange.ERROR: ', err, err.stack); 
                      }
        }  
 
        function AddHashTagToText(event,pHashTag) {
          try {
                    //console.log("SMMADText.AddHashTagToText.event: ",event); 
                    //console.log("SMMADText.AddHashTagToText.pHashTag: ",pHashTag); 

                    //console.log("SMMADText.AddHashTagToText.vText.includes(pHashTag): ",vText.includes(pHashTag)); 
                    
                    let vTextTmp = ""

                  if (vText.includes(pHashTag)) {
                    
                    vTextTmp = vText.replace("#" + pHashTag, "")
                    //console.log("SMMADText.AddHashTagToText.vTextTmp: ",vTextTmp); 

                  } else {
                    
                    if (vText === "") {
                      vTextTmp = " #" + pHashTag
                    } else {
                      vTextTmp = vText + " #" + pHashTag
                    }


                  }

                    //console.log("SMMADText.AddHashTagToText.vTextTmp2: ",vTextTmp); 
                    setvText(vTextTmp)
             
            
          } catch (err) { 
                          console.error('SMMADText.Text_handleChange.ERROR: ', err, err.stack); 
                      }
        }          

        function HashTag_handleChange(event,data) {
          try {
        
                      setvNewHashTag(event.target.value)
                        
    
          } catch (err) { 
                          console.error('SMMADText.HashTag_handleChange.ERROR: ', err, err.stack); 
                      }
        }  

        async function SaveHashTag(event, data) {
          try {           
            

          if (vNewHashTag.length > 0) {  
          
              let vAccountType = ""
        
              if (Users.includes("@rhyteit.com") || Users.includes("@richbi.ch"))  {

              vAccountType = "Internal"
              
            } else {
            
              vAccountType = "Customer"

              }
              setErrorMessage("")
    
              var vUpdateDateTimeNew = new Date().toISOString()
          
              let vId =  get_uuidv4

    
              let TextItem = {                
                id: vId,
                Account: vAccountSelected,
                AccountType: vAccountType,
                Campaign: vCampaignSelected,
                HashTag: vNewHashTag,    
                PostedCount: 0,
                Weight: 0,
                Active: true,
                SMMADHashTagStatus: "new",
                CreationName: vAccountSelected,
                UpdateName: vAccountSelected,
                CreationDateTime: vUpdateDateTimeNew,
                UpdateDateTime: vUpdateDateTimeNew

              }
    
    
                //console.log("SMMADHashTags.SaveRow.TextItem:  ",TextItem)                      
    
                  
                  const updateSMMADHashTagResponse = await client.graphql({
                    query: createSMMADHashTags,
                    variables: {
                      input: TextItem
                    }
                  });  
    
                  //console.log("SMMADHashTags.SaveRow.updateSMMADHashTagResponse:  ",updateSMMADHashTagResponse) 

                  GetHashTag()
      
            
                } else {
                  
                  setErrorMessage("Please enter a hash tag")

                }

    
            } catch (err) { 
              ErrorMessageStr = ("SMMADHashTags.SaveRow.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }       
        
        async function GetHashTag() {
          try {
                    setErrorMessage("")
                    setvHashTagMap([])

                    const variables = {
                      filter: {
                        Account: {
                                eq: vAccountSelected
                            }
                      }
                    };

                    const HashTagData2  = await client.graphql({
                      query: listSMMADHashTags,
                      variables: variables
                    });
      
          

                    if (HashTagData2.data.listSMMADHashTags.items.length > 0) {

                        const HashTag1 = HashTagData2.data.listSMMADHashTags.items 
                        //console.log("SMMADHashTags.GetHashTag.HashTag1:  ",HashTag1)     

                        setvHashTagMap(HashTag1)  
           
                  }

          } catch (err) { 
                          console.error('HashTag.GetHashTag.ERROR: ', err, err.stack); 
                      }
        }            
        

    return ( 
                <div>          
                            <Flex
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                alignContent="flex-start"
                                wrap="nowrap"
                                className={styles.FlexColumn}
                              >     
                              {vContentRow !== undefined && (  
                                <div>                             
                                  <Text
                                  className={styles.Text}
                                    >
                                      {props.ContentId}
                                  </Text>                                 
                                  <Flex
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="stretch"
                                        alignContent="flex-start"
                                        wrap="nowrap"
                                        gap="1rem"
                                      >   
                                      <Text
                                      className={styles.Text}
                                        >
                                          Posted times:
                                      </Text>   
                                      <Text
                                      className={styles.Text}
                                        >
                                          {vContentRow.PostedCount}
                                      </Text>      
                                  </Flex> 
                                  <Flex
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="stretch"
                                        alignContent="flex-start"
                                        wrap="nowrap"
                                        gap="1rem"
                                      >   
                                      <Text
                                      className={styles.Text}
                                        >
                                          Uploaded:
                                      </Text>   
                                      <Text
                                      className={styles.Text}
                                        >
                                          {new Date(vContentRow.CreationDateTime).toISOString().slice(0, 10)}
                                      </Text>   
                                  </Flex>  
                                  <Flex
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="stretch"
                                        alignContent="flex-start"
                                        wrap="nowrap"
                                        gap="1rem"
                                      >   
                                      <Text
                                      className={styles.Text}
                                        >
                                          Last Post:
                                      </Text>   
                                      <Text
                                      className={styles.Text}
                                        >
                                          {vContentRow.PostedCount > 0 ? new Date(vContentRow.LastPostDate).toISOString().slice(0, 10) : null}
                                      </Text>   
                                  </Flex>                                   
                                  <Flex
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="stretch"
                                        alignContent="flex-start"
                                        wrap="nowrap"
                                        gap="1rem"
                                      >   
                                      <Text
                                      className={styles.Text}
                                        >
                                          Weight:
                                      </Text>   
                                      <Text
                                      className={styles.Text}
                                        >
                                          {vContentRow.PostedCount > 0 ? Number.parseFloat(vContentRow.Weight).toFixed(2): null}
                                      </Text>   
                                  </Flex>                                                                                                                                   
                                </div>           
                                )  }         
                               {SMText.length > 0 && (
                                <TextTable   key={updatedKey}  HashTagMap={vHashTagMap}  SMText={SMText} />    
                                )  } 
                                {vAddTextAreaVisible === true && (                                      
                                <Flex
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    alignContent="flex-start"
                                    wrap="nowrap"
                                    gap="1rem"
                                  >   
                                  <TextAreaField 
                                      className={styles.TextAreaField} 
                                      name="Text"     
                                      placeholder="Add new caption"                   
                                      defaultValue={vText}
                                      onChange={ (e) => Text_handleChange(e, vText) }/>
                                   
                                   <Text
                                        className={styles.Text}
                                      >
                                        {vTextLength} character count
                                    </Text>     
                                </Flex>  
                                )  } 
                                <Flex
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    alignContent="flex-start"
                                    wrap="nowrap"
                                    gap="1rem"
                                  >   
                                  {vAddTextAreaVisible === false && (                                    
                                  <Button className={styles.StorageManagerButton}
                                                variation="primary"         
                                                onClick={() => setvAddTextAreaVisible(true)} >
                                          Add Text
                                    </Button> 
                                )  }    
                                {vAddTextAreaVisible === true && (                                       
                                  <Button className={styles.StorageManagerButton}
                                                variation="primary"         
                                                onClick={ (e) => SaveText(e)}
                                                >
                                          Save Text
                                    </Button> 
                                )  } 
                                    <Text
                                        className={styles.ErrorText}
                                      >
                                        {vErrorMessage}
                                    </Text>                                    
                                                                                                                                                                                                                                                                                                                        
                                </Flex>  
                                <Flex
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    alignContent="flex-start"
                                    wrap="nowrap"
                                    gap="1rem"
                                  >   
                                    {
                                     vHashTagMap  && vHashTagMap.map((SMMADHashTag) => (                      
                                        (<ToggleButton  className={styles.ToggleButton}  
                                              size="small"       
                                              onClick={ (e) => AddHashTagToText(e, SMMADHashTag.HashTag) }
                                              key={SMMADHashTag.id} >
                                                {SMMADHashTag.HashTag}
                                        </ToggleButton>)        
                                        ))
                                    }   
                                </Flex>
                                <Flex
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    alignContent="flex-start"
                                    wrap="nowrap"
                                    gap="3rem"
                                  >   
                                <TextField 
                                    className={styles.TextField} 
                                    name="HashTag"     
                                    placeholder="Add new hash tag"                   
                                    defaultValue={vNewHashTag}
                                    onChange={ (e) => HashTag_handleChange(e, vNewHashTag) }/>   
 
                                </Flex>
                                <Flex
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    alignContent="flex-start"
                                    wrap="nowrap"
                                    gap="3rem"
                                  >                                                                
                                  <Button className={styles.StorageManagerButton}
                                                variation="primary"         
                                                onClick={ (e) => SaveHashTag(e)}
                                                >
                                          Add HashTag
                                    </Button>                                                                                                                                                                                                                                                                                            
                                </Flex>                                                                                                     
                              </Flex>    
                    </div>  

    )

  }
 

export default SMMADText;